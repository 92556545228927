import { devtools } from 'zustand/middleware';
import create from 'zustand/vanilla';

type RouteHistoryType = {
  prevUrl: string;
  currentUrl: string;
};

const routeHistoryStore = create<RouteHistoryType>()(
  devtools(
    () => ({
      prevUrl: '',
      currentUrl: '',
    }),
    { name: 'routeHistory' }
  )
);

export default routeHistoryStore;
