import * as Sentry from '@sentry/nextjs';
import { ClientError, GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';

import KemiApiError, { GraphqlError } from '@global/service/Error/KemiApiError';
import { isTokenCreationOperation } from 'src/auth/helper';
import { clearToken, getAccessToken } from 'src/auth/modules/kemiToken';

const isCSR = typeof window !== 'undefined';

const graphQLClient = new GraphQLClient(
  process.env.NEXT_PUBLIC_API_URL as string
);
export const fetcher = <Data, Variables>(
  query: string,
  variables?: Variables,
  options?: RequestInit['headers']
) => {
  return async () => {
    try {
      let headers = options;

      if (!headers && isCSR && !isTokenCreationOperation(query)) {
        const accessToken = await getAccessToken();

        if (accessToken) {
          headers = {
            Authorization: `Bearer ${accessToken}`,
          };
        }
      }

      return await graphQLClient.request<Data, Variables>(
        query,
        variables,
        headers
      );
    } catch (e) {
      // 기존 kemi api error 타입 하위 호환
      if (e instanceof ClientError && e.response.errors) {
        const errorInterface: GraphqlError = e.response.errors.map((error) => {
          return {
            message: error.message,
            path: error.path || [],
            extensions: {
              code: error.extensions.code,
              exception: error.extensions.exception,
            },
            locations: error.locations || [],
          };
        });

        const hasAuthenticationFailed = errorInterface.some(
          ({ extensions }) => extensions.code === 'AUTHENTICATION_FAILED'
        );

        if (hasAuthenticationFailed) {
          clearToken();
        }

        throw new KemiApiError(errorInterface);
      } else {
        Sentry.captureException(e, {
          extra: {
            operationFirstLine: query.split('\n').filter((line) => !!line)[0],
            operation: query,
            variables,
          },
        });
        throw e;
      }
    }
  };
};
