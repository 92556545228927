import * as React from 'react';
import { SVGProps } from 'react';
const SvgFavoriteFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.136 4.484a1 1 0 0 1 1.728 0l1.985 3.408a1 1 0 0 0 .653.474l3.855.835a1 1 0 0 1 .534 1.644l-2.628 2.941a1 1 0 0 0-.25.767l.398 3.924a1 1 0 0 1-1.398 1.016l-3.61-1.59a1 1 0 0 0-.806 0l-3.61 1.59a1 1 0 0 1-1.398-1.015l.397-3.925a1 1 0 0 0-.249-.767L4.11 10.845A1 1 0 0 1 4.643 9.2l3.855-.835a1 1 0 0 0 .653-.474l1.985-3.408Z"
      fill="#FFE68B"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgFavoriteFilled;
