import { createStitches } from '@stitches/react';
import type * as Stitches from '@stitches/react';

import { Font, TransparentColor } from './../global/components/atoms/types';
import { colors } from './constants';

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors,
    space: {
      // theme 관련 padding-bottom 정의
      coverImagePaddingBottom: '100%',
    },
    fontSizes: {},
    fonts: {},
    fontWeights: {},
    lineHeights: {},
    letterSpacings: {},
    sizes: {
      headerHeight: '80px',
      // 기존 scss 모듈 사용중인 경우에 headerHeight에 해당하는 값이에요. 스티치 스타일을 사용할때에는 이 값을 사용해주세요
      contentHeaderHeight: '64px',
      footerHeight: '120px',
      appHeightWhenDesktop: '812px',
      appWidthWhenDesktop: '400px',
      listContentBottomPadding: '108px',
      mediaMobileWidth: '500px',
      contentHeight: 'calc(100vh - $headerHeight)',
      contentPadding: '16px',
      innerContentHeight: 'calc($contentHeight - $contentHeaderHeight)',
      // theme 관련 size 정의
      profileWidth: '64px',
      profileHeight: '64px',
    },
    borderWidths: {},
    borderStyles: {},
    radii: {
      // theme 관련 radius 정의
      cardBorderRadius: '16px',
      imageBorderRadius: '12px',
      buttonBorderRadius: '8px',
    },
    shadows: {
      // deprecated 👇👇👇 scss제거작업을 위해 옳겨 놓았습니다. 해당 값은 사용하지 않아요
      boxShadow4: '0 0 24px 0 rgba(0, 0, 0, 0.04)',
      boxShadow8: '0 0 24px 0 rgba(0, 0, 0, 0.08)',
      boxShadow16: '0 0 24px 0 rgba(0, 0, 0, 0.16)',
      boxShadow32: '0 0 24px 0 rgba(0, 0, 0, 0.32)',
      buttonShadow8: '0 8px 32px -8px rgba(0, 0, 0, 0.08)',
      buttonShadow16: '0 8px 32px -8px rgba(0, 0, 0, 0.16)',
      // deprecated 👆👆👆 scss제거작업을 위해 옳겨 놓았습니다. 해당 값은 사용하지 않아요
      boxShadow: '0px 8px 24px -16px rgba(0, 0, 0, 0.08)',
      buttonShadow: '0px 8px 32px -8px rgba(0, 0, 0, 0.08)',
      primaryShadow: '0px 8px 16px -8px #9382FF',
      popupShadow: '0px 0px 64px rgba(0, 0, 0, 0.24)',
    },
    zIndices: {
      contentHeader: 10,
      header: 20,
      floatingButton: 30,
      channelTalk: 40,
      playgroundPageToast: 10,
      kemiPageToast: 50,
      fullModal: 60,
      modal: 60,
      toast: 90,
      notificationBanner: 100,
    },
    transitions: {},
  },
  media: {
    mobile: '(max-width: 500px)',
    tablet: '(min-width: 501px) and (max-width: 1200px)',
    pc: '(min-width: 1201px)',
    landingMobile: '(min-width: 401px)',
    landingTablet: '(min-width: 801px)',
    landingPC: '(min-width: 1201px)',
    onlyHoverDevice: '(hover: hover) and (pointer: fine)',
    // deprecated 👇👇👇 scss제거작업을 위해 옳겨 놓았습니다. 해당 값은 사용하지 않아요
    mediaDesktop: '(min-width: 500px)',
  },
  utils: {
    marginX: (value: string | number) => ({
      marginLeft: value,
      marginRight: value,
    }),
    marginY: (value: string | number) => ({
      marginTop: value,
      marginBottom: value,
    }),
    paddingX: (value: string | number) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    paddingY: (value: string | number) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    transparentColor: (value: TransparentColor) => {
      switch (value) {
        // deprecated 👇👇👇 design0.2 이후로는 translucent를 사용해주세요
        case 'transparentBlack':
          return {
            backgroundColor: 'rgba(0, 0, 0, 0.64)',
            backdropFilter: 'blur(16px)',
            WebkitBackdropFilter: 'blur(16px)',
          };
        case 'transparentTooltip':
          return {
            backgroundColor: 'rgba(0, 0, 0, 0.64)',
            backdropFilter: 'blur(64px)',
            WebkitBackdropFilter: 'blur(64px)',
          };
        case 'transparentGrey':
          return {
            backgroundColor: 'rgba(246, 246, 246, 0.72)',
            backdropFilter: 'blur(64px)',
            WebkitBackdropFilter: 'blur(64px)',
          };
        case 'transparentWhite':
          return {
            backgroundColor: 'rgba(255, 255, 255, 0.72)',
            backdropFilter: 'blur(64px)',
            WebkitBackdropFilter: 'blur(64px)',
          };
        // deprecated 👆👆👆 design0.2 이후로는 translucent를 사용해주세요

        case 'translucentPrimary':
          return {
            backgroundColor: 'rgba(120, 86, 255, 0.8)',
            backdropFilter: 'blur(8px)',
            WebkitBackdropFilter: 'blur(8px)',
          };
        case 'translucentBlack':
          return {
            backgroundColor: 'rgba(0, 0, 0, 0.64)',
            backdropFilter: 'blur(8px)',
            WebkitBackdropFilter: 'blur(8px)',
          };
        case 'translucentGrey':
          return {
            backgroundColor: 'rgba(246, 246, 246, 0.72)',
            backdropFilter: 'blur(32px)',
            WebkitBackdropFilter: 'blur(32px)',
          };
        case 'translucentWhite':
          return {
            backgroundColor: 'rgba(255, 255, 255, 0.72)',
            backdropFilter: 'blur(32px)',
            WebkitBackdropFilter: 'blur(32px)',
          };
        case 'none':
          return {
            background: 'none',
            backdropFilter: 'none',
            WebkitBackdropFilter: 'none',
          };
      }
    },
    fontType: (value: Font) => {
      const getFont = (
        fontSize: number,
        fontWeight: number,
        lineHeight: number
      ) => ({ fontSize, fontWeight, lineHeight: `${lineHeight}px` });

      switch (value) {
        case 'heading1':
          return getFont(32, 900, 38);
        case 'heading2':
          return getFont(28, 900, 32);
        case 'heading3':
          return getFont(24, 800, 28);
        case 'titleBold':
          return getFont(20, 800, 24);
        case 'titleRegular':
          return getFont(20, 400, 24);
        case 'subtitleBold':
          return getFont(16, 700, 20);
        case 'subtitleRegular':
          return getFont(16, 400, 20);
        case 'bodyBold':
          return getFont(14, 700, 18);
        case 'bodyRegular':
          return getFont(14, 400, 18);
        case 'captionBold':
          return getFont(12, 700, 16);
        case 'captionRegular':
          return getFont(12, 400, 16);
        case 'labelBold':
          return getFont(10, 700, 12);
        case 'labelRegular':
          return getFont(10, 400, 12);
      }
    },
    border1: (value: Stitches.PropertyValue<'borderColor'>) => {
      return { border: `1px solid ${value}` };
    },
    borderItem1: (value: Stitches.PropertyValue<'borderColor'>) => {
      return {
        border: `1px solid ${value}`,
        borderRadius: 8,
        padding: 16,
      };
    },
    gridColumnWithGap: (value: Stitches.PropertyValue<'gap'>) => {
      return {
        display: 'grid',
        gridAutoFlow: 'column',
        gap: value,
      };
    },
    gridRowWithGap: (value: Stitches.PropertyValue<'gap'>) => {
      return {
        display: 'grid',
        gridAutoFlow: 'row',
        gap: value,
      };
    },
    ellipse: (value: Stitches.PropertyValue<'lineClamp'>) => {
      return {
        display: '-webkit-box !important',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': value,
        '-webkit-box-orient': 'vertical',
        wordBreak: 'break-all',
      };
    },
    gridTemplateColumnsArray: (values: (number | string)[]) => {
      const gridTemplateColumns = values
        .map((value) => {
          if (typeof value === 'number') return `${value}px`;
          else return value;
        })
        .join(' ');
      return { gridTemplateColumns };
    },
    noTapHighlight: () => {
      return {
        WebkitTapHighlightColor: 'transparent',
        outline: 'none',
      };
    },
  },
});

export type FontType = Stitches.CSS<typeof config>['fontType'];
export type ColorType = Stitches.CSS<typeof config>['color'];
export type ShadowType = Stitches.CSS<typeof config>['boxShadow'];
