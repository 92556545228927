import DataLogger from '@global/service/logger/data-logger';
import ErrorLogger from '@global/service/logger/error-logger';
import ConsoleLogger from '@global/service/logger/logger/ConsoleLogger';

export const consoleLogger = new ConsoleLogger();

export const dataLogger = new DataLogger();
export const errorLogger = new ErrorLogger();

export type LogLevel = 'DEBUG' | 'INFO' | 'WARN' | 'ERROR';
