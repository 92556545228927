import OAuthProvider from './OAuthProvider';

import ROUTE from '@routers';
import useSignInAndUpModal from '@shared/hooks/useSignInAndUpModal';
import { styled } from '@styles/stitches.config';

const SignIn = () => {
  const signInAndUpModal = useSignInAndUpModal();

  return (
    <div>
      <Title>{'케미에 로그인하기'}</Title>
      <SignInProvidersSection>
        <OAuthProvider
          provider={'kakao'}
          type={'signIn'}
          onClick={() => signInAndUpModal.proceedWithOAuth('kakao')}
        />
        <OAuthProvider
          provider={'naver'}
          type={'signIn'}
          onClick={() => signInAndUpModal.proceedWithOAuth('naver')}
        />
        <OAuthProvider
          provider={'google'}
          type={'signIn'}
          onClick={() => signInAndUpModal.proceedWithOAuth('google')}
        />
        <OAuthProvider
          provider={'facebook'}
          type={'signIn'}
          onClick={() => signInAndUpModal.proceedWithOAuth('facebook')}
        />
        <SignInTermsMessage>
          {'최초 로그인 시'}{' '}
          <a href={ROUTE.privacy} target={'_blank'} rel={'noreferrer'}>
            {'<개인정보처리방침>'}
          </a>{' '}
          {'및'}{' '}
          <a href={ROUTE.terms} target={'_blank'} rel={'noreferrer'}>
            {'<서비스 이용약관>'}
          </a>
          {'에 동의하시게 됩니다.'}
        </SignInTermsMessage>
      </SignInProvidersSection>
      <ToKemiSignIn onClick={() => signInAndUpModal.changeMode('kemiSignIn')}>
        {'케미 아이디로 로그인'}
      </ToKemiSignIn>
      <SignUpSection>
        <SignUpMessage>{'아직 케미 계정이 없으신가요?'}</SignUpMessage>
        <ToSignUp onClick={() => signInAndUpModal.changeMode('signUp')}>
          {'회원가입하기'}
        </ToSignUp>
      </SignUpSection>
    </div>
  );
};

const Title = styled('div', {
  fontType: 'heading3',
  color: '$black',
  padding: 16,
});

const SignInProvidersSection = styled('div', {
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const SignInTermsMessage = styled('div', {
  textAlign: 'center',
  fontType: 'labelRegular',
  color: '$grey100',
});

const SignUpSection = styled('div', {
  padding: 16,
  display: 'flex',
  alignItems: 'center',
  gap: 16,
});

const SignUpMessage = styled('span', {
  fontType: 'bodyRegular',
  color: '$black',
});

const ToSignUp = styled('div', {
  fontType: 'bodyBold',
  color: '$black',
  cursor: 'pointer',
});

const ToKemiSignIn = styled('div', {
  padding: 16,
  fontType: 'bodyRegular',
  color: '$grey100',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export default SignIn;
