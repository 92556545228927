const ExclamationMark = ({ ...rest }) => {
  const strokeColor = rest.color || 'black';

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <circle
        cx={'12'}
        cy={'12'}
        r={'9'}
        stroke={strokeColor}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M12 13L12 7'}
        stroke={strokeColor}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M12 17L12 16'}
        stroke={strokeColor}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default ExclamationMark;
