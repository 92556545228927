import React, { PropsWithChildren } from 'react';

import PortalContainer from './PortalContainer';

import ModalToastContainer from '@design0.2/Toast/ModalToastContainer';
import Box from '@global/components/atoms/Box';
import ToastContainer from '@global/components/atoms/ToastContainer';
import ConfirmModal from '@global/components/molecules/ConfirmModal/ConfirmModal';
import { KEMI_ROOT_TAG_ID } from '@global/constants';
import { useFirebaseGlobalConfig } from '@pages/app/hooks/useFirebaseGlobalConfig';
import useVisitKemiEvent from '@pages/app/hooks/useVisitKemiEvent';
import InAppBrowserWarningModal from '@shared/components/InAppBrowserWarningModal';
import SignInAndUpModal from '@shared/components/LogInAndSignUpModal/SignInAndUpModal';
import NotificationBanner from '@shared/components/NotificationBanner';
import RequestReviewModal from '@shared/components/RequestReviewModal/RequestReviewModal';
import useInAppBrowserWarningModal from '@shared/hooks/useInAppBrowserWarningModal';
import useOpenRequestReviewModal from '@shared/hooks/useOpenRequestReviewModal';
import useSignInAndUpModal from '@shared/hooks/useSignInAndUpModal';
import useUserAgent from '@shared/hooks/useUserAgent';
import { styled } from '@styles/stitches.config';

type WrapperProps = PropsWithChildren<{
  noLayout?: boolean;
}>;

const Wrapper = ({ noLayout, children }: WrapperProps) => {
  const signInAndUpModal = useSignInAndUpModal();
  const inAppBrowserWarningModal = useInAppBrowserWarningModal();
  const requestReviewModal = useOpenRequestReviewModal();
  const { unsupportedIosVersionLessThan14 } = useUserAgent();

  useFirebaseGlobalConfig();
  useVisitKemiEvent();
  return (
    <Container id={KEMI_ROOT_TAG_ID} noLayout={noLayout}>
      {unsupportedIosVersionLessThan14 && <NotificationBanner />}
      {children}
      <PortalContainer />
      <ToastContainer />
      <ConfirmModal />
      {signInAndUpModal.opened && <SignInAndUpModal />}
      {inAppBrowserWarningModal.opened && <InAppBrowserWarningModal />}
      {requestReviewModal.opened && <RequestReviewModal />}
      <ModalToastContainer />
    </Container>
  );
};

const Container = styled(Box, {
  width: '100%',
  minHeight: '100vh',
  position: 'relative',
  '@mobile': {
    marginTop: 'unset',
    border: 'unset',
  },
  variants: {
    noLayout: {
      true: {
        height: '100%',
      },
      false: {
        borderRadius: 8,
        '@mobile': {
          borderRadius: 0,
        },
      },
    },
  },
});

export default Wrapper;
