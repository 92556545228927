import create from 'zustand';
import { devtools } from 'zustand/middleware';

type SearchInputStoreValues = {
  hideChannelTalkButton: boolean;
};

type SearchInputStoreActions = {
  setHideChannelTalkButton: (hide: boolean) => void;
};

const initialValue: SearchInputStoreValues = {
  hideChannelTalkButton: false,
};

const useChannelTalkOptionStore = create<
  SearchInputStoreValues & SearchInputStoreActions
>()(
  devtools(
    (set) => ({
      ...initialValue,
      setHideChannelTalkButton: (hide) => {
        set(() => ({
          hideChannelTalkButton: hide,
        }));
      },
      set,
    }),
    { name: 'useChannelTalkOptionStore' }
  )
);

export default useChannelTalkOptionStore;
