import axios from 'axios';

import {
  useLoginWithNaverMutation,
  useRegisterWithNaverMutation,
} from '../graphql/auth.generated';
import { createDefaultKemiOption, getOAuthCallbackUri } from '../helper';
import { NaverAuthResponse, OAuthModule } from '../types';

import nextApi from '@global/network/NextApi';
import { generateQueryUrl } from '@utils/url';

const CLIENT_ID = process.env.NEXT_PUBLIC_NAVER_APP_KEY as string;
// 서버에만 존재
const CLIENT_SECRET = process.env.NAVER_APP_SECRET as string;
const REDIRECT_URI = getOAuthCallbackUri('naver');
class NaverOAuth implements OAuthModule {
  async authorize(state: string) {
    const authorizeUrl = generateQueryUrl(
      'https://nid.naver.com/oauth2.0/authorize',
      {
        response_type: 'code',
        client_id: CLIENT_ID,
        redirect_uri: REDIRECT_URI,
        state,
      }
    );

    window.location.href = authorizeUrl;
  }

  async requestIssuingSnsAccessToken(code: string): Promise<string> {
    return await nextApi.issueSnsAccessToken('naver', code);
  }

  async issueSnsAccessTokenOnServer(code: string): Promise<string> {
    const { data } = await axios.get<NaverAuthResponse>(
      'https://nid.naver.com/oauth2.0/token',
      {
        params: {
          grant_type: 'authorization_code',
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          code,
        },
      }
    );

    return data.access_token;
  }

  async issueKemiToken(snsAccessToken: string) {
    const kemiTokenResult = await useLoginWithNaverMutation.fetcher({
      snsAccessToken,
    })();

    const { token, refreshToken } = kemiTokenResult.loginWithNaverToken;

    return {
      accessToken: token,
      refreshToken,
    };
  }

  async registerKemi(snsAccessToken: string, linkName?: string) {
    await useRegisterWithNaverMutation.fetcher({
      snsAccessToken,
      kemiOption: createDefaultKemiOption(),
      linkName,
    })();
  }
}

const naverOAuth = new NaverOAuth();

export default naverOAuth;
