import * as React from 'react';
import { SVGProps } from 'react';
const SvgPrivacyPolicyBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m5.927 4.02-3.375 6a2 2 0 0 0 0 1.96l3.375 6A2 2 0 0 0 7.67 19h6.83s-.5-1.5-.5-4l3.948-.987a.44.44 0 0 1 .106-.013c.16 0 .307-.086.385-.225l1.01-1.795a2 2 0 0 0 0-1.96l-3.376-6A2 2 0 0 0 14.33 3H7.67a2 2 0 0 0-1.743 1.02ZM9.5 8v2M12.5 8v2"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 12c.333.333 1.4 1 3 1s2.667-.667 3-1M14 15l4-1 4 1c0 6-4 7-4 7s-4-1-4-7Z"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m17 18 .833 1 1.667-2"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPrivacyPolicyBlack;
