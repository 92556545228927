import firebaseLogger from '@global/service/logger/event-logger/FirebaseLogger';
import UserEventConsoleLogger from '@global/service/logger/event-logger/UserEventConsoleLogger';
import { IEventLogger } from '@global/service/logger/interfaces';
import { Maybe, UserAgentType, UserInteractionType } from '@global/types';

export type UserInteractionLogParam = {
  tag: string;
  interactionType: UserInteractionType;
  userProfile?: Maybe<UserProfileType>;
  userAgent?: Maybe<UserAgentType>;
  firebase?: FirebaseLogType;
  request?: any;
};

type UserProfileType = {
  linkName: string;
  title: string;
  kemiId: string;
};

export type FirebaseLogType = {
  logName: string;
  logOption?: any;
};

class UserInteractionLogger implements IEventLogger<UserInteractionLogParam> {
  private loggerList: IEventLogger<UserInteractionLogParam>[] = [
    // 다른 로깅방식으로 로깅하고 싶다면 여기에 추가하면 됩니다
    new UserEventConsoleLogger(), // 개발단계에서 콘솔로그를 켜고 싶으면 로컬 env에 NEXT_PUBLIC_CONSOLE_LOG=true 추가해주시면 됩니다!
    firebaseLogger,
  ];

  log(param: UserInteractionLogParam) {
    this.loggerList.forEach((logger) => logger.log(param));
  }
}

export default new UserInteractionLogger();
