import { useMeForFacebookAccessQuery } from '@global/hooks/useFacebookAccess/graphql/FacebookAccess.generated';

export const useGetFacebookAuth = () => {
  const { data } = useMeForFacebookAccessQuery();
  const userFacebookInfo = data?.me?.sellerInfo?.instagram;
  const hasFacebookPermission =
    userFacebookInfo?.instagramUserId && userFacebookInfo?.accessToken;

  return {
    hasFacebookPermission,
  };
};
