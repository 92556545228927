const TermOfService = ({ ...rest }) => {
  if (rest.type === 'linedWhite') {
    return (
      <svg
        width={'24'}
        height={'24'}
        viewBox={'0 0 24 24'}
        fill={'none'}
        xmlns={'http://www.w3.org/2000/svg'}
        {...rest}
      >
        <path
          d={'M5 3H19V17L15 21H5V3Z'}
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M15 17H19L15 21V17Z'}
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M9 11L11 13L15 9'}
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
      </svg>
    );
  }

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={'M5 3H19V17L15 21H5V3Z'}
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M15 17H19L15 21V17Z'}
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M9 11L11 13L15 9'}
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default TermOfService;
