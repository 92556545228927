import {
  LoginWithKemiMutationVariables,
  useLoginWithKemiMutation,
} from '../graphql/auth.generated';

export const kemiAuth = {
  issueKemiToken: async (loginInput: LoginWithKemiMutationVariables) => {
    const res = await useLoginWithKemiMutation.fetcher(loginInput)();
    const { token, refreshToken } = res.login;

    return {
      accessToken: token,
      refreshToken,
    };
  },
};
