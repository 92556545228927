const Seller = ({ ...rest }) => {
  if (rest.type === 'linedWhite') {
    return (
      <svg
        width={'24'}
        height={'24'}
        viewBox={'0 0 24 24'}
        fill={'none'}
        xmlns={'http://www.w3.org/2000/svg'}
        {...rest}
      >
        <path
          d={
            'M3 12L7.5 4.20577L16.5 4.20577L21 12L16.5 19.7942L7.5 19.7942L3 12Z'
          }
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={
            'M12.4699 8.28637L13.5832 10.6322H15.4732C15.5766 10.6303 15.6782 10.6595 15.7649 10.716C15.8515 10.7724 15.9193 10.8536 15.9594 10.9489C15.9996 11.0442 16.0102 11.1494 15.99 11.2508C15.9699 11.3522 15.9197 11.4453 15.8462 11.518L14.1072 13.0654L15.0685 15.2745C15.1109 15.3756 15.1205 15.4875 15.0958 15.5944C15.0711 15.7013 15.0135 15.7977 14.9311 15.8701C14.8486 15.9424 14.7455 15.987 14.6363 15.9976C14.5272 16.0081 14.4174 15.9841 14.3226 15.9289L11.9984 14.6216L9.67417 15.9289C9.57939 15.9841 9.46966 16.0081 9.36048 15.9976C9.25131 15.987 9.14821 15.9424 9.06576 15.8701C8.98332 15.7977 8.92569 15.7013 8.90102 15.5944C8.87636 15.4875 8.88591 15.3756 8.92832 15.2745L9.88966 13.0654L8.15383 11.518C8.08026 11.4453 8.03015 11.3522 8.00996 11.2508C7.98977 11.1494 8.00043 11.0442 8.04056 10.9489C8.08069 10.8536 8.14845 10.7724 8.23511 10.716C8.32177 10.6595 8.42335 10.6303 8.52676 10.6322H10.4168L11.5301 8.28637C11.5746 8.2001 11.642 8.12775 11.7248 8.07723C11.8077 8.02672 11.9029 8 12 8C12.0971 8 12.1923 8.02672 12.2752 8.07723C12.358 8.12775 12.4254 8.2001 12.4699 8.28637Z'
          }
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
      </svg>
    );
  }

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={
          'M3 12L7.5 4.20577L16.5 4.20577L21 12L16.5 19.7942L7.5 19.7942L3 12Z'
        }
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M12.4699 8.28637L13.5832 10.6322H15.4732C15.5766 10.6303 15.6782 10.6595 15.7649 10.716C15.8515 10.7724 15.9193 10.8536 15.9594 10.9489C15.9996 11.0442 16.0102 11.1494 15.99 11.2508C15.9699 11.3522 15.9197 11.4453 15.8462 11.518L14.1072 13.0654L15.0685 15.2745C15.1109 15.3756 15.1205 15.4875 15.0958 15.5944C15.0711 15.7013 15.0135 15.7977 14.9311 15.8701C14.8486 15.9424 14.7455 15.987 14.6363 15.9976C14.5272 16.0081 14.4174 15.9841 14.3226 15.9289L11.9984 14.6216L9.67417 15.9289C9.57939 15.9841 9.46966 16.0081 9.36048 15.9976C9.25131 15.987 9.14821 15.9424 9.06576 15.8701C8.98332 15.7977 8.92569 15.7013 8.90102 15.5944C8.87636 15.4875 8.88591 15.3756 8.92832 15.2745L9.88966 13.0654L8.15383 11.518C8.08026 11.4453 8.03015 11.3522 8.00996 11.2508C7.98977 11.1494 8.00043 11.0442 8.04056 10.9489C8.08069 10.8536 8.14845 10.7724 8.23511 10.716C8.32177 10.6595 8.42335 10.6303 8.52676 10.6322H10.4168L11.5301 8.28637C11.5746 8.2001 11.642 8.12775 11.7248 8.07723C11.8077 8.02672 11.9029 8 12 8C12.0971 8 12.1923 8.02672 12.2752 8.07723C12.358 8.12775 12.4254 8.2001 12.4699 8.28637Z'
        }
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default Seller;
