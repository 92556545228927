abstract class KemiError extends Error {
  abstract name: string;
  abstract code: string;
  abstract options: any;

  constructor(message: string) {
    super(message);
  }
}

export default KemiError;
