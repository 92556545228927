import KemiError from './KemiError';

type FacebookApiErrorCode = 'NOT_IMPLEMENT';

class FacebookApiError extends KemiError {
  name: string;
  code: FacebookApiErrorCode;
  options: any;

  constructor(error: any) {
    super('FACEBOOK_API_SERVER_ERROR');
    this.name = 'FacebookApiError';
    this.code = 'NOT_IMPLEMENT';
    this.options = error;
  }
}

export default FacebookApiError;
