import Link from 'next/link';
import { HTMLProps, PropsWithChildren } from 'react';

import { Menu, MenuProps } from '@pages/app/components/Menu';
import { ArrowRight, RightContainer } from '@pages/app/components/shared';

export const ExternalLinkMenu = ({
  name,
  Icon,
  onClick,
  postfix,
  href,
  ...props
}: PropsWithChildren<
  HTMLProps<HTMLAnchorElement> & MenuProps & { href: string }
>) => {
  return (
    <Link href={href} passHref>
      <Menu
        name={name}
        Icon={Icon}
        onClick={onClick}
        target={'_blank'}
        rel={'noreferrer'}
        postfix={
          <RightContainer>
            {postfix}
            <ArrowRight />
          </RightContainer>
        }
        {...props}
      />
    </Link>
  );
};
