import { consoleLogger } from '..';

import { DataLogParam } from '@global/service/logger/data-logger/index';
import { IEventLogger, ILogger } from '@global/service/logger/interfaces';

export default class DataConsoleLogger implements IEventLogger<DataLogParam> {
  private consoleLogger: ILogger = consoleLogger;

  log(param: DataLogParam): void {
    if (
      process.env.NEXT_PUBLIC_APP_ENV === 'dev' &&
      process.env.NEXT_PUBLIC_CONSOLE_LOG === 'true'
    ) {
      this.consoleLogger.group(() => {
        this.consoleLogger.debug('*******DATA_FLOW_LOG*******');
        this.consoleLogger.debug('time: ', new Date());
        this.consoleLogger.debug(param);
        this.consoleLogger.debug('**********************************');
      });
    }
  }
}
