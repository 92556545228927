import ROUTE from '@routers';

const useGetSelectedMenu = (url?: string) => {
  if (url?.startsWith(ROUTE.mykemi.index)) {
    return 'KEMI';
  }

  if (url?.startsWith(ROUTE.sellerCenter.index)) {
    return 'SELLER_CENTER';
  }
};

export default useGetSelectedMenu;
