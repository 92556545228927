import * as React from 'react';
import { SVGProps } from 'react';
const SvgTiktokBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.548 4h2.794l.12 1.165C15.627 6.742 17.225 8 19 8v2.588a6.688 6.688 0 0 1-3.774-1.193v5.31c0 3.403-2.898 5.295-5.235 5.295C7.604 20 5 18.062 5 14.941c0-2.65 2.36-4.823 5.235-4.823v2.588c-1.509 0-2.557 1.121-2.557 2.235 0 1.585 1.292 2.47 2.313 2.47 1.072 0 2.557-.931 2.557-2.705V4Z"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgTiktokBlack;
