import * as Toast from '@radix-ui/react-toast';

import ToastContainer from '@design0.2/Toast/components/ToastContainer';
import { modalToastStore } from '@design0.2/Toast/stores/ToastStore';
import { styled } from '@styles/stitches.config';

const ModalToastContainer = () => {
  const toast = modalToastStore();
  return (
    <Toast.Provider>
      <ToastViewport />
      <ToastContainer
        toast={toast.toast}
        open={toast.open}
        setOpen={toast.setOpen}
      />
    </Toast.Provider>
  );
};

const ToastViewport = styled(Toast.Viewport, {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: '$toast',
});

export default ModalToastContainer;
