import useEffectOnce from '@global/hooks/useEffectOnce';
import globalErrorHandler from '@global/service/Error/globalErrorHandler';
export const useThirdPartyScripts = () => {
  /** 카카오 */
  useEffectOnce(() => {
    const loadKakao = async () => {
      try {
        await import('../../../shared/third-party/kakao');
        Kakao.init(process.env.NEXT_PUBLIC_KAKAO_APP_KEY as string);
      } catch (e) {
        globalErrorHandler(e);
      }
    };

    loadKakao();
  });
};
