export const AddProduct = ({ ...rest }) => {
  let color;

  switch (rest.type) {
    case 'linedWhite':
      color = 'white';
      break;
    case 'linedGrey':
      color = '#D8D8D8';
      break;
    default:
      color = '#303030';
  }

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={
          'M18 12V7.23607C18 6.47852 17.572 5.786 16.8944 5.44721L10.8944 2.44721C10.3314 2.16569 9.66863 2.16569 9.10557 2.44721L3.10557 5.44721C2.428 5.786 2 6.47852 2 7.23607V14.7639C2 15.5215 2.428 16.214 3.10557 16.5528L9.10557 19.5528C9.66863 19.8343 10.3314 19.8343 10.8944 19.5528L12 19'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M2.5 6.5L9.05882 9.99804C9.64706 10.3118 10.3529 10.3118 10.9412 9.99804L17.5 6.5'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M5.5 11V8.5L14 4'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M10 10.5V19'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <circle
        cx={'17'}
        cy={'17'}
        r={'5'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M14.5 17H19.5'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M17 14.5V19.5'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};
