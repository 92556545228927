import * as React from 'react';
import { SVGProps } from 'react';
const SvgTwitterBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m20 6 .624.416A.75.75 0 0 0 20 5.25V6Zm-3.354 0-.497.562a.75.75 0 0 0 .497.188V6ZM18 9l-.624-.416A.75.75 0 0 0 17.25 9H18ZM3 17l.237-.712a.75.75 0 0 0-.908 1.047L3 17Zm5-1 .45.6A.75.75 0 0 0 8 15.25V16Zm-3-2-.182-.728a.75.75 0 0 0-.53.965L5 14Zm2-.5.182.728A.75.75 0 0 0 7 12.75v.75ZM4 10l.182-.728A.75.75 0 0 0 3.25 10H4Zm2 .5-.182.728a.75.75 0 0 0 .568-1.371L6 10.5ZM5 6l.586-.469a.75.75 0 0 0-1.257.134L5 6Zm5.019 3.39-.158.733a.75.75 0 0 0 .904-.805l-.746.072ZM20 5.25h-3.354v1.5H20v-1.5Zm-1.376 4.166 2-3-1.248-.832-2 3 1.248.832ZM8 19.75c5.914 0 10.75-4.836 10.75-10.75h-1.5c0 5.086-4.164 9.25-9.25 9.25v1.5Zm-5.67-2.415a3.655 3.655 0 0 0 1.71 1.71c.895.448 2.15.705 3.96.705v-1.5c-1.69 0-2.685-.243-3.29-.546a2.16 2.16 0 0 1-1.04-1.04l-1.34.671ZM7.55 15.4c-.927.696-1.69 1.007-2.352 1.109-.66.101-1.285.005-1.96-.22l-.475 1.422c.824.275 1.698.429 2.664.28.963-.148 1.95-.587 3.023-1.391l-.9-1.2Zm-3.262-1.163C4.933 16.17 6.833 16.75 8 16.75v-1.5c-.833 0-1.933-.42-2.288-1.487l-1.424.474Zm2.53-1.465-2 .5.364 1.456 2-.5-.364-1.456ZM3.25 10c0 1.472.598 2.547 1.373 3.245.748.673 1.673 1.005 2.377 1.005v-1.5c-.296 0-.871-.168-1.373-.62-.475-.427-.877-1.102-.877-2.13h-1.5Zm2.932-.228-2-.5-.364 1.456 2 .5.364-1.456ZM4.329 5.665c-.69 1.38-.667 2.6-.29 3.569.363.937 1.035 1.585 1.575 1.91l.772-1.287a2.44 2.44 0 0 1-.95-1.166c-.215-.556-.275-1.336.235-2.356l-1.342-.67Zm5.848 2.992c-1.552-.335-3.323-1.54-4.591-3.126l-1.172.938c1.423 1.777 3.473 3.228 5.447 3.654l.316-1.466Zm.588.66A3.296 3.296 0 0 1 10.75 9h-1.5c0 .156.008.31.022.462l1.493-.144ZM10.75 9A3.25 3.25 0 0 1 14 5.75v-1.5A4.75 4.75 0 0 0 9.25 9h1.5ZM14 5.75c.825 0 1.576.306 2.15.812l.992-1.124A4.735 4.735 0 0 0 14 4.25v1.5Z"
      fill="#303030"
    />
  </svg>
);
export default SvgTwitterBlack;
