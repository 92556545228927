const Customer = ({ ...rest }) => {
  let color;

  switch (rest.type) {
    case 'linedWhite':
      color = 'white';
      break;
    case 'linedGrey':
      color = '#D8D8D8';
      break;
    default:
      color = '#303030';
  }

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        d={
          'M9 14C11.2091 14 13 12.2091 13 10C13 7.79086 11.2091 6 9 6C6.79086 6 5 7.79086 5 10C5 12.2091 6.79086 14 9 14Z'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeMiterlimit={'10'}
      />
      <path
        d={'M14 14C16.2091 14 18 12.2091 18 10C18 7.79086 16.2091 6 14 6'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M3 18C3.67662 16.7644 4.57503 15.7559 5.61934 15.0597C6.66365 14.3635 7.82318 14 9 14C10.1768 14 11.3364 14.3635 12.3807 15.0597C13.425 15.7559 14.3234 16.7644 15 18'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M14 14C15.1768 14 16.3364 14.3635 17.3807 15.0597C18.425 15.7559 19.3234 16.7644 20 18'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default Customer;
