import React, {
  ChangeEvent,
  useState,
  useEffect,
  useRef,
  MouseEvent,
  ReactNode,
} from 'react';

import Box from '../Box';
import Text from '../Text';

import CloseCircle from '@static/svg/CloseCircle';
import { ColorName } from '@styles/constants';
import { css, styled } from '@styles/stitches.config';

export type InputProps = {
  value: string;
  type?: string;
  pattern?: string;
  placeholder?: string;
  leftIcon?: ReactNode;
  fixedPlaceholder?: string;
  fixedPlaceholderColor?: ColorName;
  onChange?: (value: string, name?: string) => void;
  onBlur?: () => void;
  errorBorder?: boolean;
  maxLength?: number;
  clearIcon?: boolean;
  name?: string;
  disabled?: boolean;
  disabledBorder?: boolean;
  onClear?: () => void;
  onClick?: () => void;
  onFocus?: () => void;
  autocapitalize?: 'on' | 'off';
  suffix?: string;
  valueAlign?: 'left' | 'right' | 'center';
  customIcon?: JSX.Element;
  label?: string;
  className?: string;
};

const Input = ({
  value = '',
  type,
  pattern,
  placeholder,
  leftIcon,
  fixedPlaceholder,
  fixedPlaceholderColor,
  onChange,
  onBlur,
  errorBorder,
  maxLength,
  clearIcon,
  onClear,
  onClick,
  onFocus,
  disabled,
  disabledBorder,
  name,
  autocapitalize,
  suffix,
  valueAlign,
  customIcon,
  label,
  className,
}: InputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [_hasError, setHasError] = useState(errorBorder);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputContainerClick = () => {
    onClick?.();

    inputRef.current?.focus();
  };

  const handleInputValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    // onChange시에 wheel event라면 e.nativeEvent.inputType은 undefined가 나옴
    // type === 'number'일때 마우스 휠 사용시 숫자가 바뀌는 현상을 막으려고 사용함.
    const { value, name } = e.currentTarget;

    if (type === 'number') {
      const onlyNumberValue = value.replace(/[^\d]/g, '');
      onChange?.(onlyNumberValue, name);
      return;
    }

    if (type === 'tel') {
      const onlyNumberValue = value.replace(/[^\d]/g, '');
      const lastNumber = parseInt(onlyNumberValue[onlyNumberValue.length - 1]);

      if (onlyNumberValue.length && isNaN(lastNumber)) return;

      onChange?.(onlyNumberValue, name);
      return;
    }

    onChange?.(value, name);
  };

  const handleInputContainerMouseDown = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    if (e.target !== inputRef.current) {
      e.preventDefault();
    }
  };

  const handleClear = () => {
    onChange?.('');
    onClear?.();
  };

  useEffect(() => {
    if (!maxLength) return;

    if (value.length > maxLength && !_hasError) {
      return setHasError(true);
    }

    if (_hasError && value.length <= maxLength) {
      return setHasError(false);
    }
  }, [value, maxLength, _hasError]);

  return (
    <Container
      className={className}
      onClick={handleInputContainerClick}
      onMouseDown={handleInputContainerMouseDown}
      disabled={disabled}
      disabledBorder={disabledBorder}
      focus={isFocused}
      error={errorBorder}
    >
      {leftIcon && <LeftIcon>{leftIcon}</LeftIcon>}
      {fixedPlaceholder && (
        <FixedPlaceholderText
          font={'bodyRegular'}
          color={fixedPlaceholderColor || 'grey100'}
        >
          {fixedPlaceholder}
        </FixedPlaceholderText>
      )}
      <InputWrapper>
        <InputInner
          ref={inputRef}
          aria-label={label}
          autoCapitalize={autocapitalize}
          disabled={disabled}
          type={type}
          pattern={pattern}
          right={valueAlign === 'right'}
          name={name}
          value={value}
          onChange={handleInputValueChange}
          placeholder={placeholder}
          onFocus={() => {
            onFocus?.();
            setIsFocused(true);
          }}
          onBlur={() => {
            onBlur?.();
            setIsFocused(false);
          }}
        />
        {suffix && (
          <Suffix>
            <Text font={'bodyRegular'} color={'grey100'}>
              {suffix}
            </Text>
          </Suffix>
        )}
      </InputWrapper>
      {clearIcon && (
        <CloseCircle className={rightIcon()} onClick={handleClear} />
      )}
      {customIcon}
      {maxLength && (
        <Text type={'div'} className={rightIcon()} font={'labelRegular'}>
          {value.length}
          {'/'}
          {maxLength}
        </Text>
      )}
    </Container>
  );
};

const Container = styled(Box, {
  position: 'relative',
  width: '100%',
  height: 48,
  padding: 16,
  overflow: 'hidden',
  transition: 'border-color 0.3s',
  border: '1px solid $grey50',
  borderRadius: 8,
  background: '$white',
  fontType: 'bodyRegular',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  '&:hover': {
    cursor: 'text',
  },

  variants: {
    disabled: {
      true: {
        border: 'none',
        background: '$grey20',
      },
    },
    disabledBorder: {
      true: {
        border: '1px solid $grey50',
      },
    },
    focus: {
      true: {
        borderColor: '$black',
      },
    },
    error: {
      true: {
        borderColor: '$red100',
      },
    },
  },
});

const FixedPlaceholderText = styled(Text, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: 50,
  whiteSpace: 'nowrap',
});

const LeftIcon = styled('div', {
  marginRight: 8,
  display: 'flex',
});

const InputWrapper = styled(Box, {
  position: 'relative',
  flex: 1,
  width: '100%',
  height: 50,
  overflow: 'hidden',
});

const InputInner = styled('input', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '108%',
  height: 50,
  paddingLeft: 0,
  transform: 'scale(0.875)',
  transformOrigin: '0 50%',
  border: 'none',
  outline: 'none',
  background: '$white',
  fontSize: 16,

  '&:disabled': {
    background: '$grey20',
  },

  '&::placeholder': {
    color: '$grey100',
  },

  variants: {
    right: {
      true: {
        paddingRight: 8,
        textAlign: 'right',
      },
    },
  },
});

const Suffix = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  right: 0,
  height: 50,
});

const rightIcon = css({
  '&:hover': {
    cursor: 'pointer',
  },

  flexShrink: 0,
});

export default Input;
