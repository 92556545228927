import { consoleLogger } from '..';

import { UserInteractionLogParam } from '@global/service/logger/event-logger/index';
import { IEventLogger, ILogger } from '@global/service/logger/interfaces';

export default class UserEventConsoleLogger
  implements IEventLogger<UserInteractionLogParam>
{
  private consoleLogger: ILogger;

  constructor() {
    this.consoleLogger = consoleLogger;
  }

  log({
    interactionType,
    tag,
    userProfile,
    userAgent,
    ...params
  }: UserInteractionLogParam): void {
    if (
      process.env.NEXT_PUBLIC_APP_ENV === 'dev' &&
      process.env.NEXT_PUBLIC_CONSOLE_LOG === 'true'
    ) {
      this.consoleLogger.group(() => {
        this.consoleLogger.debug('*******USER_INTERACTION_LOG*******');
        this.consoleLogger.debug('time: ', new Date());
        this.consoleLogger.debug('type: ', interactionType);
        this.consoleLogger.debug('name: ', tag);
        this.consoleLogger.debug('user-profile: ', userProfile);
        this.consoleLogger.debug('user-agent: ', userAgent);
        this.consoleLogger.debug('params: ', params);
        this.consoleLogger.debug('**********************************');
      });
    }
  }
}
