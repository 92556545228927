import Image from 'next/image';

import Button from '@design0.2/Button/Button';
import kemiAstronaut from '@static/media/kemi-astronaut.svg';
import { styled } from '@styles/stitches.config';

const InternalError = () => {
  return (
    <Container>
      <ImageContainer>
        <Image src={kemiAstronaut} alt={''} />
      </ImageContainer>
      <Title>{'음... 뭔가 잘못되었어요 😵'}</Title>
      <SubTitle>{'다음 방법을 시도해보세요.'}</SubTitle>
      <Description>
        <List>{'사파리/크롬/인터넷 브라우저 이용'}</List>
        <List>{'새로고침'}</List>
        <List>{'모바일일 경우, PC이용'}</List>
      </Description>
      <Button
        buttonStyle={'primary'}
        onClick={() => navigator.clipboard.writeText(location.href)}
      >
        {'링크 복사'}
      </Button>
    </Container>
  );
};

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: '16px 64px',
  textAlign: 'center',
  backgroundColor: '$primary10',
});

const ImageContainer = styled('div', {
  marginBottom: 24,
});

const Title = styled('span', {
  fontType: 'subtitleBold',
  marginBottom: 24,
});

const SubTitle = styled('span', {
  fontType: 'bodyBold',
  marginBottom: 8,
});

const Description = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 24,
});

const List = styled('li', {
  fontType: 'bodyRegular',
  position: 'relative',
  paddingLeft: 12,

  '&:before': {
    content: '',
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    width: 3,
    height: 3,
    borderRadius: '50%',
    backgroundColor: '$black',
  },
});

export default InternalError;
