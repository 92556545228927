import Image, { ImageProps } from 'next/future/image';
import { SyntheticEvent, useEffect, useState } from 'react';

import NoImage from './NoImage';

import Loader from '@global/components/atoms/Loader/index';
import { styled } from '@styles/stitches.config';
import { appendResizeQuery } from '@utils/url';

type KemiImageProps = ImageProps & {
  /**
   * (height / width)명시적으로 이미지 비율을 위해서 형식을 지켜주세요.
   * @example 9 / 16
   */
  ratio?: number;
  isLoading?: boolean;
};

function KemiImage({
  src,
  ratio,
  onError,
  isLoading,
  fill = true,
  alt,
  onLoadingComplete,
  ...props
}: KemiImageProps) {
  const [hasError, setHasError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const isNoImage = hasError || !src;
  const loading = isLoading || !loaded;
  const paddingBottom = ratio && `${ratio * 100}%`;

  const handleError = (e: SyntheticEvent<HTMLImageElement>) => {
    setHasError(true);
    setLoaded(true);
    onError?.(e);
  };

  const handleLoadComplete = (el: HTMLImageElement) => {
    onLoadingComplete?.(el);
    setLoaded(true);
  };

  useEffect(() => {
    if (!src) {
      setLoaded(true);
    }

    setHasError(false);
  }, [src]);

  return (
    <Container css={{ paddingBottom }}>
      {isNoImage && <NoImage />}
      {!isNoImage && (
        <Image
          className={'kemi-image'}
          src={src}
          alt={alt}
          /**
           * mobile device(768px) 이내일 경우, 전체 너비를 뷰포트로 인식한다.
           * mobile device가 아닐 경우 400px로 고정한다.
           * fill layout이 아닐때는 고정 해상도로 작동하도록 sizes를 제거해야 한다.
           */
          sizes={fill ? '(max-width: 768px) 100vw, 400px' : undefined}
          onError={handleError}
          onLoadingComplete={handleLoadComplete}
          loader={({ width, quality, src }) => {
            const extension = src.split('.').pop();

            // gif는 리사이징을 하지 않는다.
            if (extension === 'gif') {
              return src;
            }

            return appendResizeQuery(src, {
              w: width,
              q: quality || 70,
              f: 'webp',
            });
          }}
          fill={fill}
          {...props}
        />
      )}
      {loading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
    </Container>
  );
}

const Container = styled('div', {
  position: 'relative',
  transition: 'padding 0.2s ease',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  // absolute인 자식들에게 hidden이 안먹히는 사파리 버그 대응
  // https://bugs.webkit.org/show_bug.cgi?id=98538
  zIndex: 0,

  '.kemi-image': {
    // inline 속성의 text spacing으로 border radius 적용이 어려워 block 속성 부여
    display: 'block',
    objectFit: 'cover',
  },
});

const LoaderWrapper = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  transparentColor: 'transparentGrey',
});

export default KemiImage;
