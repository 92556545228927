import KemiLoadingSpinner from '@static/svg/KemiLoadingSpinner';
import { styled, keyframes } from '@styles/stitches.config';

type LoaderProps = {
  className?: string;
  inline?: boolean;
};

const Loader = ({ inline, className }: LoaderProps) => {
  return (
    <Wrapper className={className} inline={inline}>
      <KemiLoadingSpinner />
    </Wrapper>
  );
};

const rotate = keyframes({
  '0%': { transform: 'rotate(0)' },
  '100%': { transform: 'rotate(360deg)' },
});

const Wrapper = styled('div', {
  width: 24,
  height: 24,
  animation: `${rotate} 1.5s infinite linear`,

  variants: {
    inline: {
      true: {
        display: 'inline-block',
      },
    },
  },
});

export default Loader;
