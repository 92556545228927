import * as Types from 'schema/types';

import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type NonDisplayedReviewRequestsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type NonDisplayedReviewRequestsQueryResponse = {
  nonDisplayedReviewRequests?: {
    nonDisplayedReviewRequests: Array<{
      orderId: string;
      productCardId: string;
      productTitle?: string | null;
      productOptionTitles?: Array<string> | null;
      imageUrls?: Array<string> | null;
    }>;
  } | null;
};

export type UpdateDisplayedReviewRequestMutationVariables = Types.Exact<{
  orderId: Types.Scalars['String'];
  productCardId: Types.Scalars['String'];
}>;

export type UpdateDisplayedReviewRequestMutationResponse = {
  updateDisplayedReviewRequest?: { success: boolean } | null;
};

export const NonDisplayedReviewRequestsDocument = `
    query NonDisplayedReviewRequests {
  nonDisplayedReviewRequests {
    nonDisplayedReviewRequests {
      orderId
      productCardId
      productTitle
      productOptionTitles
      imageUrls
    }
  }
}
    `;
export const useNonDisplayedReviewRequestsQuery = <
  TData = NonDisplayedReviewRequestsQueryResponse,
  TError = unknown
>(
  variables?: NonDisplayedReviewRequestsQueryVariables,
  options?: UseQueryOptions<
    NonDisplayedReviewRequestsQueryResponse,
    TError,
    TData
  >
) =>
  useQuery<NonDisplayedReviewRequestsQueryResponse, TError, TData>(
    variables === undefined
      ? ['NonDisplayedReviewRequests']
      : ['NonDisplayedReviewRequests', variables],
    fetcher<
      NonDisplayedReviewRequestsQueryResponse,
      NonDisplayedReviewRequestsQueryVariables
    >(NonDisplayedReviewRequestsDocument, variables),
    options
  );

useNonDisplayedReviewRequestsQuery.getKey = (
  variables?: NonDisplayedReviewRequestsQueryVariables
) =>
  variables === undefined
    ? ['NonDisplayedReviewRequests']
    : ['NonDisplayedReviewRequests', variables];
export const useInfiniteNonDisplayedReviewRequestsQuery = <
  TData = NonDisplayedReviewRequestsQueryResponse,
  TError = unknown
>(
  variables?: NonDisplayedReviewRequestsQueryVariables,
  options?: UseInfiniteQueryOptions<
    NonDisplayedReviewRequestsQueryResponse,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<
    NonDisplayedReviewRequestsQueryResponse,
    TError,
    TData
  >(
    variables === undefined
      ? ['NonDisplayedReviewRequests.infinite']
      : ['NonDisplayedReviewRequests.infinite', variables],
    (metaData) =>
      fetcher<
        NonDisplayedReviewRequestsQueryResponse,
        NonDisplayedReviewRequestsQueryVariables
      >(NonDisplayedReviewRequestsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteNonDisplayedReviewRequestsQuery.getKey = (
  variables?: NonDisplayedReviewRequestsQueryVariables
) =>
  variables === undefined
    ? ['NonDisplayedReviewRequests.infinite']
    : ['NonDisplayedReviewRequests.infinite', variables];
useNonDisplayedReviewRequestsQuery.fetcher = (
  variables?: NonDisplayedReviewRequestsQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    NonDisplayedReviewRequestsQueryResponse,
    NonDisplayedReviewRequestsQueryVariables
  >(NonDisplayedReviewRequestsDocument, variables, options);
export const UpdateDisplayedReviewRequestDocument = `
    mutation UpdateDisplayedReviewRequest($orderId: String!, $productCardId: String!) {
  updateDisplayedReviewRequest(orderId: $orderId, productCardId: $productCardId) {
    success
  }
}
    `;
export const useUpdateDisplayedReviewRequestMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateDisplayedReviewRequestMutationResponse,
    TError,
    UpdateDisplayedReviewRequestMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateDisplayedReviewRequestMutationResponse,
    TError,
    UpdateDisplayedReviewRequestMutationVariables,
    TContext
  >(
    ['UpdateDisplayedReviewRequest'],
    (variables?: UpdateDisplayedReviewRequestMutationVariables) =>
      fetcher<
        UpdateDisplayedReviewRequestMutationResponse,
        UpdateDisplayedReviewRequestMutationVariables
      >(UpdateDisplayedReviewRequestDocument, variables)(),
    options
  );
useUpdateDisplayedReviewRequestMutation.getKey = () => [
  'UpdateDisplayedReviewRequest',
];

useUpdateDisplayedReviewRequestMutation.fetcher = (
  variables: UpdateDisplayedReviewRequestMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    UpdateDisplayedReviewRequestMutationResponse,
    UpdateDisplayedReviewRequestMutationVariables
  >(UpdateDisplayedReviewRequestDocument, variables, options);
