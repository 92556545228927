const Home = ({ ...rest }) => {
  if (rest.type === 'linedGrey') {
    return (
      <svg
        width={'24'}
        height={'24'}
        viewBox={'0 0 24 24'}
        fill={'none'}
        xmlns={'http://www.w3.org/2000/svg'}
        {...rest}
      >
        <path
          d={
            'M20 20V12L12 4L4 12V20H10V17C10 15.8954 10.8954 15 12 15C13.1046 15 14 15.8954 14 17V20H20Z'
          }
          stroke={'#D8D8D8'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
      </svg>
    );
  }

  if (rest.type === 'linedWhite') {
    return (
      <svg
        width={'24'}
        height={'24'}
        viewBox={'0 0 24 24'}
        fill={'none'}
        xmlns={'http://www.w3.org/2000/svg'}
        {...rest}
      >
        <path
          d={
            'M20 20V12L12 4L4 12V20H10V17C10 15.8954 10.8954 15 12 15C13.1046 15 14 15.8954 14 17V20H20Z'
          }
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
      </svg>
    );
  }

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={
          'M20 20V12L12 4L4 12V20H10V17C10 15.8954 10.8954 15 12 15C13.1046 15 14 15.8954 14 17V20H20Z'
        }
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default Home;
