import * as Types from 'schema/types';

import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  UseMutationOptions,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type UpdateSellerInfoForFacebookAccessMutationVariables = Types.Exact<{
  sellerInfo?: Types.InputMaybe<Types.UpdateSellerInfoInput>;
}>;

export type UpdateSellerInfoForFacebookAccessMutationResponse = {
  updateSellerInfo?: { userId: string } | null;
};

export type MeForFacebookAccessQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type MeForFacebookAccessQueryResponse = {
  me?: {
    sellerInfo?: {
      sns?: { type?: Types.ESellerSnsType | null; url?: string | null } | null;
      instagram?: {
        instagramUserId?: string | null;
        accessToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export const UpdateSellerInfoForFacebookAccessDocument = `
    mutation UpdateSellerInfoForFacebookAccess($sellerInfo: UpdateSellerInfoInput) {
  updateSellerInfo(sellerInfo: $sellerInfo) {
    userId
  }
}
    `;
export const useUpdateSellerInfoForFacebookAccessMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateSellerInfoForFacebookAccessMutationResponse,
    TError,
    UpdateSellerInfoForFacebookAccessMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateSellerInfoForFacebookAccessMutationResponse,
    TError,
    UpdateSellerInfoForFacebookAccessMutationVariables,
    TContext
  >(
    ['UpdateSellerInfoForFacebookAccess'],
    (variables?: UpdateSellerInfoForFacebookAccessMutationVariables) =>
      fetcher<
        UpdateSellerInfoForFacebookAccessMutationResponse,
        UpdateSellerInfoForFacebookAccessMutationVariables
      >(UpdateSellerInfoForFacebookAccessDocument, variables)(),
    options
  );
useUpdateSellerInfoForFacebookAccessMutation.getKey = () => [
  'UpdateSellerInfoForFacebookAccess',
];

useUpdateSellerInfoForFacebookAccessMutation.fetcher = (
  variables?: UpdateSellerInfoForFacebookAccessMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    UpdateSellerInfoForFacebookAccessMutationResponse,
    UpdateSellerInfoForFacebookAccessMutationVariables
  >(UpdateSellerInfoForFacebookAccessDocument, variables, options);
export const MeForFacebookAccessDocument = `
    query MeForFacebookAccess {
  me {
    sellerInfo {
      sns {
        type
        url
      }
      instagram {
        instagramUserId
        accessToken
      }
    }
  }
}
    `;
export const useMeForFacebookAccessQuery = <
  TData = MeForFacebookAccessQueryResponse,
  TError = unknown
>(
  variables?: MeForFacebookAccessQueryVariables,
  options?: UseQueryOptions<MeForFacebookAccessQueryResponse, TError, TData>
) =>
  useQuery<MeForFacebookAccessQueryResponse, TError, TData>(
    variables === undefined
      ? ['MeForFacebookAccess']
      : ['MeForFacebookAccess', variables],
    fetcher<
      MeForFacebookAccessQueryResponse,
      MeForFacebookAccessQueryVariables
    >(MeForFacebookAccessDocument, variables),
    options
  );

useMeForFacebookAccessQuery.getKey = (
  variables?: MeForFacebookAccessQueryVariables
) =>
  variables === undefined
    ? ['MeForFacebookAccess']
    : ['MeForFacebookAccess', variables];
export const useInfiniteMeForFacebookAccessQuery = <
  TData = MeForFacebookAccessQueryResponse,
  TError = unknown
>(
  variables?: MeForFacebookAccessQueryVariables,
  options?: UseInfiniteQueryOptions<
    MeForFacebookAccessQueryResponse,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<MeForFacebookAccessQueryResponse, TError, TData>(
    variables === undefined
      ? ['MeForFacebookAccess.infinite']
      : ['MeForFacebookAccess.infinite', variables],
    (metaData) =>
      fetcher<
        MeForFacebookAccessQueryResponse,
        MeForFacebookAccessQueryVariables
      >(MeForFacebookAccessDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteMeForFacebookAccessQuery.getKey = (
  variables?: MeForFacebookAccessQueryVariables
) =>
  variables === undefined
    ? ['MeForFacebookAccess.infinite']
    : ['MeForFacebookAccess.infinite', variables];
useMeForFacebookAccessQuery.fetcher = (
  variables?: MeForFacebookAccessQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<MeForFacebookAccessQueryResponse, MeForFacebookAccessQueryVariables>(
    MeForFacebookAccessDocument,
    variables,
    options
  );
