import { EGender, ELoginType } from '@schema/types';

const getSnsLoginType = (code?: ELoginType | null) => {
  if (!code) {
    return '-';
  }

  switch (code) {
    case 'KAKAO':
      return '카카오';
    case 'NAVER':
      return '네이버';
    case 'GOOGLE':
      return '구글';
    case 'FACEBOOK':
      return '페이스북';
    case 'KEMI':
      return '케미';
    default:
      return '기타방식';
  }
};

const getGender = (code?: EGender | 'NONE' | null) => {
  if (!code) {
    return '-';
  }

  switch (code) {
    case 'FEMALE':
      return '여성';
    case 'MALE':
      return '남성';
    default:
      return '-';
  }
};

const translator = {
  getSnsLoginType,
  getGender,
};

export default translator;
