import { ECardShape, ECoverSize, EProfileAlign } from '@schema/types';

export const getCardTheme = (cardShape: ECardShape) => {
  switch (cardShape) {
    case 'RECT':
      return {
        cardBorderRadius: 0,
        imageBorderRadius: 0,
        buttonBorderRadius: 0,
      };

    case 'ROUND_BIG':
      return {
        cardBorderRadius: '40px',
        imageBorderRadius: '36px',
        buttonBorderRadius: '24px',
      };

    default:
      return {
        cardBorderRadius: '16px',
        imageBorderRadius: '12px',
        buttonBorderRadius: '8px',
      };
  }
};

export const getProfileAlignTheme = (profileAlign: EProfileAlign) => {
  if (profileAlign === 'LEFT') {
    return {
      profileWidth: '64px',
      profileHeight: '64px',
    };
  }

  return {
    profileWidth: '96px',
    profileHeight: '96px',
  };
};

export const getCoverImageTheme = (coverSize: ECoverSize) => {
  if (coverSize === 'SQUARE') {
    return {
      coverImagePaddingBottom: '100%',
    };
  }

  return {
    coverImagePaddingBottom: '56.25%',
  };
};

// theme 수정 페이지의 작은 테마 적용시 사용
// cardBorder만 절만으로 줄인다.
export const getSmallCardTheme = (cardShape: ECardShape) => {
  switch (cardShape) {
    case 'RECT':
      return {
        cardBorderRadius: 0,
        imageBorderRadius: 0,
        buttonBorderRadius: 0,
      };

    case 'ROUND_BIG':
      return {
        cardBorderRadius: '16px',
        imageBorderRadius: '36px',
        buttonBorderRadius: '24px',
      };

    default:
      return {
        cardBorderRadius: '8px',
        imageBorderRadius: '12px',
        buttonBorderRadius: '8px',
      };
  }
};

export const getMultiColumnThumbnailPadding = (cardShape: ECardShape) => {
  if (cardShape === 'ROUND_BIG') {
    return {
      badgePositionPadding: '16px',
    };
  }

  return {
    badgePositionPadding: '8px',
  };
};
