import Image from 'next/image';

import SmallModal from '@design0.2/Modal/SmallModal';
import { useModalToast } from '@design0.2/Toast/hooks/useToast';
import useInAppBrowserWarningModal from '@shared/hooks/useInAppBrowserWarningModal';
import appIconChrome from '@static/media/app_icon_chrome.png';
import appIconSafari from '@static/media/app_icon_safari.png';
import { styled } from '@styles/stitches.config';
import { copyToClipboard } from '@utils/dom';

const InAppBrowserWarningModal = () => {
  const inAppBrowserWarningModal = useInAppBrowserWarningModal();
  const toast = useModalToast();

  const onClickCopy = () => {
    copyToClipboard(location.href);
    toast.good('주소가 클립보드에 저장되었습니다.');
  };

  return (
    <SmallModal onClose={inAppBrowserWarningModal.close} closeButton>
      <Root>
        <PrimaryMessage>
          {
            '최적의 케미 사용 경험을 위해 크롬(Chrome)\n또는 사파리(Safari) 브라우저를 사용하여\n서비스를 이용해주세요 🙏'
          }
        </PrimaryMessage>
        <span onClick={onClickCopy}>{'👆' + '클립보드로 복사' + '👆'}</span>
        <BrowserSection>
          <Browser>
            <Image
              src={appIconChrome}
              width={96}
              height={96}
              objectFit={'contain'}
              alt={'app-icon'}
            />
            <BrowserName>{'크롬'}</BrowserName>
          </Browser>
          <Browser>
            <Image
              src={appIconSafari}
              width={96}
              height={96}
              objectFit={'contain'}
              alt={'app-icon'}
            />
            <BrowserName>{'사파리'}</BrowserName>
          </Browser>
        </BrowserSection>
        <SecondaryMessage>
          {
            '현재 접속하신 브라우저에선 서비스 이용이\n원활하지 않을 수 있습니다 😥'
          }
        </SecondaryMessage>
      </Root>
    </SmallModal>
  );
};

const Root = styled('div', {
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
});

const PrimaryMessage = styled('p', {
  fontType: 'bodyBold',
  textAlign: 'center',
});

const BrowserSection = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 200,
  borderRadius: 16,
  backgroundColor: '#faf8ff',
});

const Browser = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const BrowserName = styled('span', {
  fontType: 'bodyBold',
});

const SecondaryMessage = styled('p', {
  fontType: 'captionRegular',
});

export default InAppBrowserWarningModal;
