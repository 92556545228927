import create from 'zustand';
import { devtools } from 'zustand/middleware';

type InAppBrowserWarningModalStoreState = {
  opened: boolean;
};

const INITIAL_STATE: InAppBrowserWarningModalStoreState = {
  opened: false,
};

type InAppBrowserWarningModalStoreAction = {
  open: () => void;
  close: () => void;
};

const useInAppBrowserWarningModalStore = create<
  InAppBrowserWarningModalStoreState & {
    actions: InAppBrowserWarningModalStoreAction;
  }
>()(
  devtools(
    (set) => ({
      ...INITIAL_STATE,
      actions: {
        open: () => set({ opened: true }),
        close: () => set({ opened: false }),
      },
    }),
    { name: 'inAppBrowserWarningModalStore' }
  )
);

export default useInAppBrowserWarningModalStore;
