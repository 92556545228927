import * as React from 'react';
import { SVGProps } from 'react';
const SvgNaverDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.333 5H4v14h5.333v-7.539L14.667 19H20V5h-5.333v7.539L9.333 5Z"
      fill="#D8D8D8"
    />
  </svg>
);
export default SvgNaverDisabled;
