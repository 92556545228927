import { useQuery } from '@tanstack/react-query';

import { nextApi } from '@global/network/index';
import { GetVisitorsAndBuyersByTimesResponse } from '@global/service/BigqueryService/queries';
import { ONE_HOUR } from '@pages/mykemi/analytics/source/helper';

type DataName = 'isVisitorsAndBuyersByTimeLoading';

export const useVisitorsAndBuyersByTimesAnalyticsQuery = (
  startDates: string[],
  kemiId: string,
  utcOffset: number,
  dataName: DataName
) => {
  return useQuery(
    ['VisitorsAndBuyersByTimes', { kemiId, utcOffset, dataName }],
    async () => {
      const data =
        await nextApi.fetchAnalyticsData<GetVisitorsAndBuyersByTimesResponse>(
          '/buyers/by-times',
          {
            startDates,
            kemiId,
            utcOffset,
          }
        );

      return data;
    },

    {
      enabled: !!(startDates.length && kemiId),
      cacheTime: ONE_HOUR,
      staleTime: ONE_HOUR,
    }
  );
};
