import { LAYOUT_TYPES_WITH_DESKTOP_NAV_BAR } from '@shared/constants/app';
import { LayoutType } from '@shared/types/app';

/**
 * 현재 실행중인 웹앱의 origin을 반환한다.
 *
 * 다음을 고려해 가장 적절한 방식으로 origin을 구한다.
 *
 * - 참조하는 시점에 코드가 실행되는 환경 (클라이언트 or 서버)
 * - 환경변수를 통해 명시적으로 origin을 정의했는지
 *
 * @returns 웹앱의 origin
 */
export const getOrigin = () => {
  const isBrowser = typeof window !== 'undefined';

  switch (true) {
    // 브라우저 환경에서는 location.origin이 가장 확실한 origin이다.
    case isBrowser:
      return window.location.origin;
    // 노드 환경이자 해당 배포물에 명시적으로 도메인이 할당되었거나 또는 로컬일 경우(예: local, dev, stage, prod 등), 해당 host를 환경변수로 세팅해 사용한다.
    case !!process.env.NEXT_PUBLIC_HOST_NAME:
      return process.env.NEXT_PUBLIC_HOST_NAME;
    // 그 외 나머지 노드 환경(vercel이 임의로 할당하는 origin을 사용하는 배포물)은 vercel이 기본적으로 할당한 host를 origin으로 정의한다.
    default:
      return `https://${process.env.VERCEL_URL}`;
  }
};

/** 데스크톱 네비게이션바가 있는지 */
export const hasDesktopNavBar = (layoutType: LayoutType) => {
  return LAYOUT_TYPES_WITH_DESKTOP_NAV_BAR.includes(layoutType);
};
