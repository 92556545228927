import axiosClient from '@global/network/axios-client';
import { EventResponse } from '@global/service/ChannelTalk';
import { checkAndAddHttps } from '@utils/url';
import { OAuthProviderType } from 'src/auth/types';

const endpoint = {
  ogData: '/og-data',
  uploadAWS: '/aws-s3',
  analyticsBaseUrl: '/analytics',
  multilinkMigrationUrl: '/multilink-migration',
  getFacebookLongTermAccessToken: '/analytics/sns/request-long-term-token',
  pushChannelTalkEvent: '/channeltalk/events',
  imageFetcher: '/image-fetcher',
  issueSnsAccessToken: '/oauth/[provider]/token',
};

type OgDataType = {
  url: string;
  ogImage: string;
  ogDescription: string;
  ogTitle: string;
  hostname: string;
};

type FacebookLongTermAccessTokenResponse = {
  access_token: string;
  token_type: string;
};

class NextApi {
  private endpoint: { [K in keyof typeof endpoint]: string };

  constructor() {
    this.endpoint = endpoint;
  }

  async getOgData(url: string) {
    const { data } = await axiosClient.post<OgDataType>(this.endpoint.ogData, {
      url,
    });

    return data;
  }

  async uploadImageToAWS(file: string) {
    const { data } = await axiosClient.get<{
      url: string;
      fields: { [key: string]: any };
    }>(this.endpoint.uploadAWS, {
      params: { file },
    });

    return data;
  }

  async deleteImageFromAWS(url: string) {
    const Bucket = process.env.NEXT_PUBLIC_AWS_S3_BUCKET_NAME as string;
    const Key = url.replace(Bucket, '');

    const { data } = await axiosClient.delete<{ success: boolean }>(
      this.endpoint.uploadAWS,
      {
        params: {
          Key,
        },
      }
    );

    return data;
  }

  async fetchAnalyticsData<T>(url: string, params: object) {
    const { data } = await axiosClient.get<T>(
      `${this.endpoint.analyticsBaseUrl}${url}`,
      { params }
    );

    return data;
  }

  async getMultilinkMigrationData(url: string) {
    const urlWithProtocol = checkAndAddHttps(url);

    const { data } = await axiosClient.post(
      this.endpoint.multilinkMigrationUrl,
      {
        url: urlWithProtocol,
      }
    );

    return data.result;
  }

  async getFacebookLongTermAccessToken(token: string) {
    const { data } =
      await axiosClient.post<FacebookLongTermAccessTokenResponse>(
        this.endpoint.getFacebookLongTermAccessToken,
        { token }
      );

    return data;
  }

  async pushChannelTalkEvent(
    channelTalkUserId: string,
    payload: {
      name: string;
      property: {
        [key: string]: any;
      };
    }
  ) {
    const { data } = await axiosClient.post<EventResponse>(
      this.endpoint.pushChannelTalkEvent,
      payload,
      { params: { userId: channelTalkUserId } }
    );

    return data;
  }

  async imageFetcher(url: string) {
    const { data } = await axiosClient.post<{ base64: string; blob: Blob }>(
      this.endpoint.imageFetcher,
      {
        url,
      }
    );
    return data;
  }

  async issueSnsAccessToken(provider: OAuthProviderType, code: string) {
    const originAndPath = this.endpoint.issueSnsAccessToken.replace(
      '[provider]',
      provider
    );

    const { data } = await axiosClient.get<string>(originAndPath, {
      params: { code },
    });

    return data;
  }
}

export default new NextApi();
