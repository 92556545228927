import { ComponentType, forwardRef, HTMLProps, RefObject } from 'react';

import {
  AnchorContainer,
  Content,
  IconContainer,
  IconTitle,
} from '@pages/app/components/shared';

export type MenuProps = HTMLProps<HTMLAnchorElement> &
  MenuContainerProps & {
    ref?: RefObject<any>;
  };

type MenuContainerProps = {
  name: string;
  Icon?: ComponentType;
  active?: boolean;
  postfix?: JSX.Element | string;
};

const MenuContainer = ({
  name,
  Icon,
  active = false,
  postfix,
}: MenuContainerProps) => {
  return (
    <Content active={active}>
      <IconTitle>
        {Icon && (
          <IconContainer active={active}>
            <Icon />
          </IconContainer>
        )}
        {name}
      </IconTitle>
      {postfix}
    </Content>
  );
};

export const Menu = forwardRef<HTMLAnchorElement, MenuProps>(
  ({ name, Icon, active, postfix, ...props }: MenuProps, ref) => {
    return (
      <AnchorContainer ref={ref} {...props}>
        <MenuContainer
          name={name}
          Icon={Icon}
          postfix={postfix}
          active={active}
        />
      </AnchorContainer>
    );
  }
);

Menu.displayName = 'menu-anchor-container';
