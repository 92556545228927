import DataConsoleLogger from '@global/service/logger/data-logger/DataConsoleLogger';
import { IEventLogger } from '@global/service/logger/interfaces';

export type DataLogParam = any;

export default class DataLogger implements IEventLogger<DataLogParam> {
  private loggerList: IEventLogger<DataLogParam>[] = [
    // 다른 로깅방식으로 로깅하고 싶다면 여기에 추가하면 됩니다
    new DataConsoleLogger(),
  ];

  log(param: DataLogParam): void {
    this.loggerList.forEach((_logger) => _logger.log(param));
  }
}
