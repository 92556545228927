const Phone = ({ ...rest }) => (
  <svg
    width={'24'}
    height={'24'}
    viewBox={'0 0 24 24'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...rest}
  >
    <path
      d={
        'M9.88088 8.65431C10.196 8.33864 10.3729 7.91083 10.3729 7.46481C10.3729 7.01878 10.196 6.59097 9.88088 6.27531L8.09616 4.49269C7.78069 4.17723 7.35284 4 6.90671 4C6.46058 4 6.03273 4.17723 5.71726 4.49269L4.73908 5.47164C4.32751 5.88257 4.07084 6.42328 4.01265 7.00197C3.95445 7.58065 4.09831 8.16165 4.41981 8.64631C7.30046 12.9824 11.0177 16.6998 15.3536 19.5805C15.8384 19.9016 16.4193 20.0454 16.9979 19.9875C17.5765 19.9295 18.1173 19.6735 18.5288 19.2627L19.507 18.2838C19.6633 18.1276 19.7873 17.9422 19.8719 17.7381C19.9565 17.534 20 17.3152 20 17.0943C20 16.8733 19.9565 16.6546 19.8719 16.4505C19.7873 16.2464 19.6633 16.0609 19.507 15.9048L17.723 14.1207C17.5669 13.9644 17.3815 13.8404 17.1774 13.7558C16.9733 13.6712 16.7545 13.6277 16.5336 13.6277C16.3127 13.6277 16.0939 13.6712 15.8898 13.7558C15.6857 13.8404 15.5003 13.9644 15.3441 14.1207L14.7492 14.7156C12.7557 13.075 10.926 11.2448 9.28597 9.25069L9.88088 8.65431Z'
      }
      stroke={'#303030'}
      strokeWidth={'1.5'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
  </svg>
);

export default Phone;
