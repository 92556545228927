import React from 'react';

import Button from '@design0.2/Button/Button';
import SmallModal from '@design0.2/Modal/SmallModal';
import { useConfirmModalStore } from '@global/store/confirmModalStore';
import { styled } from '@styles/stitches.config';

const ConfirmModal = () => {
  const {
    isOpen,
    title,
    description,
    confirmText,
    cancelText,
    actions,
    close,
  } = useConfirmModalStore();

  const handleConfirm = async () => {
    if (actions?.confirm) {
      await actions.confirm();
    }

    close();
  };
  const handleCancel = async () => {
    if (actions?.cancel) {
      await actions.cancel();
    }

    close();
  };
  const handleClose = async () => {
    if (actions?.backgroundClick) {
      await actions.backgroundClick();
    }

    close();
  };

  if (!isOpen) return null;

  return (
    <SmallModal onClose={handleClose}>
      <Container>
        <InnerContainer>
          {title && <Title>{title}</Title>}
          {description && <Description>{description}</Description>}
          <Buttons>
            <Button className={'button'} onClick={handleConfirm}>
              {confirmText}
            </Button>
            {actions?.cancel && (
              <Button
                className={'button'}
                buttonStyle={'tertiary'}
                onClick={handleCancel}
              >
                {cancelText}
              </Button>
            )}
          </Buttons>
        </InnerContainer>
      </Container>
    </SmallModal>
  );
};

const Container = styled('div', {
  '@mobile': {
    borderRadius: 16,
    backgroundColor: '$white',
    position: 'fixed',
    right: 16,
    bottom: 16,
    left: 16,
  },

  '@mediaDesktop': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
});

const InnerContainer = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  alignItems: 'center',

  '@mediaDesktop': {
    borderRadius: 16,
    backgroundColor: '$white',
    width: 480,
  },
});

const Title = styled('div', {
  padding: 16,
  fontType: 'titleBold',
});

const Description = styled('div', {
  padding: 16,
  fontType: 'bodyRegular',
  whiteSpace: 'pre-line',
  textAlign: 'left',
});

const Buttons = styled('div', {
  gridRowWithGap: 8,
  padding: 16,
});

export default ConfirmModal;
