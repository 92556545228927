// https://developers.channel.io/docs/web-installation => install docs
import { nextApi } from '@global/network';
import globalErrorHandler from '@global/service/Error/globalErrorHandler';
import { BootParams } from 'types/channelIO';

export type UpdateUserInput = {
  email?: string;
  mobile?: string;
  name?: string;
};

export type UpdateUserResponse = {
  createdAt: number;
  id: string; // 62303433e4460e50315f
  channelId: string; // 82287
  memberId: string; // c75c9827-c629-4ead-b7ac-a708d927e094
  profile: {
    mobileNumber: string; // 01073239734
    name: string; // 임케미4
    email: string; // somi4@wired.company
  };
};

export type ProductInfoEventParams = {
  productId: string;
  productName: string;
  productCardId: string;
  linkName: string;
};

export type UserInfoEventParams = {
  userId: string;
  kemiTitle: string;
  kemiLinkName: string;
};

export type EventResponse = {
  event: {
    userId: string;
    id: string;
    channelId: string;
    name: string;
    property: {
      [key: string]: any;
    };
    createdAt: Date;
    expireAt: Date;
    version: number;
  };
};

export type UserObject = {
  language?: string; // ko
  profile?: {
    [key: string]: any;
    email?: string;
    mobileNumber?: string;
    name?: string;
    isKemiUser?: boolean;
    kemiName?: string;
    kemiLinkName?: string;
    mktAgree?: boolean;
  };
  tags?: string[];
};

const ADMIN_HOST = process.env.NEXT_PUBLIC_ADMIN_BASE_URL as string;

class ChannelTalk {
  protected userId: string = '';
  private isBoot: boolean = false;

  constructor() {
    this.loadScript();
  }

  loadScript() {
    if (typeof window === 'undefined') return;

    let w = window;

    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function () {})(
        'ChannelIO script included twice.'
      );
    }

    const ch = function () {
      ch.c(arguments);
    };
    ch.q = [] as any[];
    ch.c = function (args: any) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;

    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }

      w.ChannelIOInitialized = true;
      let s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      let x = document.getElementsByTagName('script')[0];
      x.parentNode!!.insertBefore(s, x);
    }

    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  }

  get isBootDone() {
    return this.isBoot;
  }

  boot(settings: BootParams, cb: (isBoot: boolean) => void) {
    window.ChannelIO?.('boot', settings, (error, user) => {
      if (error) {
        this.isBoot = false;
        globalErrorHandler(error);
        cb(false);
      }

      this.isBoot = true;
      this.userId = user?.id || '';
      cb(true);
    });
  }

  shutdown() {
    window.ChannelIO?.('shutdown');
  }

  updateUser(
    userObject: UserObject,
    callback?: (userObject: UserObject) => void
  ) {
    window.ChannelIO?.('updateUser', userObject, callback);
  }

  showMessenger() {
    window.ChannelIO?.('showMessenger');
  }

  onShowMessenger(callback: () => void) {
    window.ChannelIO?.('onShowMessenger', () => {
      callback?.();
    });
  }

  showChannelButton() {
    window.ChannelIO?.('showChannelButton');
  }

  hideChannelButton() {
    window.ChannelIO?.('hideChannelButton');
  }

  async pushProductInfoEvent({
    productId,
    productName,
    productCardId,
    linkName,
  }: ProductInfoEventParams) {
    try {
      const eventParam = {
        name: '상품상세 > 채널톡 클릭',
        property: {
          상품아이디: productId,
          상품명: productName,
          상품카드아이디: productCardId,
          상품카드URL: `${location.origin}/${linkName}/products/${productCardId}`,
          어드민상품URL: `${ADMIN_HOST}/products/${productId}`,
        },
      };
      await nextApi.pushChannelTalkEvent(this.userId, eventParam);
    } catch (e: any) {
      /**
       * 백그라운드에서 동작하는 함수여서 별도 핸들링을 하지 않음.
       */
    }
  }

  async pushUserInfoEvent({
    userId,
    kemiTitle,
    kemiLinkName,
  }: UserInfoEventParams) {
    try {
      const eventParam = {
        name: '사이드메뉴 > 문의하기 클릭',
        property: {
          회원아이디: userId,
          케미이름: kemiTitle,
          케미링크: `${location.origin}/${kemiLinkName}`,
          어드민회원URL: `${ADMIN_HOST}/kemi-user/${userId}`,
        },
      };
      await nextApi.pushChannelTalkEvent(this.userId, eventParam);
    } catch (e: any) {
      /**
       * 백그라운드에서 동작하는 함수여서 별도 핸들링을 하지 않음.
       */
    }
  }
}

export default new ChannelTalk();
