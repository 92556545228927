import * as Types from 'schema/types';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type CreateOrderInSignInAndUpModalMutationVariables = Types.Exact<{
  order: Types.CreateOrderInput;
}>;

export type CreateOrderInSignInAndUpModalMutationResponse = {
  createOrder?: { id: string } | null;
};

export const CreateOrderInSignInAndUpModalDocument = `
    mutation CreateOrderInSignInAndUpModal($order: CreateOrderInput!) {
  createOrder(order: $order) {
    id
  }
}
    `;
export const useCreateOrderInSignInAndUpModalMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateOrderInSignInAndUpModalMutationResponse,
    TError,
    CreateOrderInSignInAndUpModalMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateOrderInSignInAndUpModalMutationResponse,
    TError,
    CreateOrderInSignInAndUpModalMutationVariables,
    TContext
  >(
    ['CreateOrderInSignInAndUpModal'],
    (variables?: CreateOrderInSignInAndUpModalMutationVariables) =>
      fetcher<
        CreateOrderInSignInAndUpModalMutationResponse,
        CreateOrderInSignInAndUpModalMutationVariables
      >(CreateOrderInSignInAndUpModalDocument, variables)(),
    options
  );
useCreateOrderInSignInAndUpModalMutation.getKey = () => [
  'CreateOrderInSignInAndUpModal',
];

useCreateOrderInSignInAndUpModalMutation.fetcher = (
  variables: CreateOrderInSignInAndUpModalMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    CreateOrderInSignInAndUpModalMutationResponse,
    CreateOrderInSignInAndUpModalMutationVariables
  >(CreateOrderInSignInAndUpModalDocument, variables, options);
