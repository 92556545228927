import { useQuery } from '@tanstack/react-query';

import { nextApi } from '@global/network/index';
import { GetVisitorsResponse } from '@global/service/BigqueryService/queries';
import { ONE_HOUR } from '@pages/mykemi/analytics/source/helper';

type DataName = 'todayVisitors' | 'yesterdayVisitors' | 'totalVisitorsStats';

export const useVisitorsAnalyticsDataQuery = (
  from: string,
  to: string,
  kemiId: string,
  dataName: DataName
) => {
  return useQuery(
    ['VisitorsAnalytics', { kemiId, dataName }],
    async () => {
      const data = await nextApi.fetchAnalyticsData<GetVisitorsResponse>(
        '/visitors/count',
        {
          from,
          to,
          kemiId,
        }
      );

      return data;
    },
    { enabled: !!kemiId, cacheTime: ONE_HOUR, staleTime: ONE_HOUR }
  );
};
