/**
 * 컴포넌트 내에서 404 화면을 보여주기 위해 쓰는 에러 클래스
 *
 * 이 에러 인스턴스를 throw하면 ErrorBoundary 컴포넌트 내에서 식별해 404화면을 보여준다.
 */
class NotFoundError extends Error {
  constructor() {
    super('404');
  }
}

export default NotFoundError;
