export const SellingAnalysis = ({ ...rest }) => {
  let color;

  switch (rest.type) {
    case 'linedWhite':
      color = 'white';
      break;
    case 'linedGrey':
      color = '#D8D8D8';
      break;
    default:
      color = '#303030';
  }

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={
          'M11 13.677V18.323C11 18.7319 11.249 19.0996 11.6286 19.2514L15.6286 20.8514C15.867 20.9468 16.133 20.9468 16.3714 20.8514L20.3714 19.2514C20.751 19.0996 21 18.7319 21 18.323V13.677C21 13.2681 20.751 12.9004 20.3714 12.7486L16.3714 11.1486C16.133 11.0532 15.867 11.0532 15.6286 11.1486L11.6286 12.7486C11.249 12.9004 11 13.2681 11 13.677Z'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M20 13V4H4V20H13'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M7 11H9V17H7V11Z'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M11 14V9H13V12'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M17 11V7H15V11'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M20 13.4L16.3714 14.8514C16.133 14.9468 15.867 14.9468 15.6286 14.8514L12 13.4'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M16 15V20.5'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};
