const BarGraph = ({ ...rest }) => {
  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <rect
        x={'4'}
        y={'4'}
        width={'16'}
        height={'16'}
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <rect
        x={'7'}
        y={'9'}
        width={'2'}
        height={'8'}
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <rect
        x={'11'}
        y={'13'}
        width={'2'}
        height={'4'}
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <rect
        x={'15'}
        y={'7'}
        width={'2'}
        height={'10'}
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default BarGraph;
