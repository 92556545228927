const CheckCircle = ({ ...rest }) => {
  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <circle
        cx={'12'}
        cy={'12'}
        r={'9'}
        stroke={rest.color || 'white'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M8 12L10.6667 15L16 9'}
        stroke={rest.color || 'white'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default CheckCircle;
