const CloseCircle = ({ ...rest }) => {
  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <circle cx={'12'} cy={'12'} r={'10'} fill={'#303030'} />
      <path
        d={'M8 16L16 8'}
        stroke={'white'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M16 16L8 8'}
        stroke={'white'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default CloseCircle;
