import { isEmpty } from 'lodash';

export const isEmptyList = <T>(list: null | undefined | T[]) => {
  return !list || list.length < 1;
};

export type EmptyObject = {
  [k in any]: never;
};

export const isObjectEmpty = <T>(obj: T): obj is EmptyObject => {
  if (!obj) return true;

  return isEmpty(obj) || Object.keys(obj).length === 0;
};

type ObjectWithId = { id: string };

export const orderWithId = <T extends ObjectWithId>(
  arr: T[],
  ids: string[]
): T[] => {
  const result = [];

  for (const id of ids) {
    const item = arr.find((item) => item.id === id);

    if (item) {
      result.push(item);
    }
  }

  return result;
};

export const getRandomIndex = (arr: any[]) => {
  return Math.floor(Math.random() * arr.length);
};

export const getRandomItem = <T>(arr: T[]) => {
  return arr[getRandomIndex(arr)];
};

export const accumulateReverse = (rates: number[]) => {
  return rates.map((_, i, arr) => {
    return arr.slice(i).reduce((acc, cur) => acc + cur);
  });
};
