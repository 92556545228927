import * as Sentry from '@sentry/nextjs';

import KemiApiError from '@global/service/Error/KemiApiError';
import KemiError from '@global/service/Error/KemiError';
import { ErrorLogParam } from '@global/service/logger/error-logger/index';
import { IEventLogger } from '@global/service/logger/interfaces';

export default class ErrorSentryLogger implements IEventLogger<ErrorLogParam> {
  log({ data }: ErrorLogParam): void {
    if (!(data instanceof KemiError)) {
      Sentry.captureException(data);
      return;
    }

    if (!(data instanceof KemiApiError)) {
      Sentry.captureException(data);
      return;
    }

    const extras = data.options?.query;
    const tags = { code: data.code };

    extras && Sentry.setExtras(extras);
    tags && Sentry.setTags(tags);
    Sentry.captureException(data);
  }
}
