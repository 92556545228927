import classNames from 'classnames/bind';
import Link from 'next/link';
import { MouseEventHandler, ReactNode } from 'react';

import buttonCSS from './buttonCSS';

export type ButtonType =
  | 'default'
  | 'defaultNoShadow'
  | 'defaultTransparent'
  | 'disabled'
  | 'disabledNoShadow'
  | 'inverse'
  | 'inverseNoShadow'
  | 'inverseWeekNoShadow'
  | 'alert'
  | 'alertNoShadow'
  | 'alertWeekNoShadow'
  | 'line'
  | 'lineNoShadow'
  | 'lineWeek'
  | 'kakaoLogin'
  | 'naverLogin'
  | 'googleLogin'
  | 'facebook'
  | 'instagram'
  | 'sideMenu'
  | 'small'
  | 'smallInverse'
  | 'dropdown'
  | 'purple100'
  | 'smallOval';

type _ButtonTemplateProps = {
  type?: ButtonType;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
};

const _ButtonTemplate = ({
  type = 'default',
  onClick,
  className,
  children,
  disabled = false,
}: _ButtonTemplateProps) => {
  const buttonClassName = buttonCSS({ buttonType: type }).className;

  return (
    <button
      className={classNames(buttonClassName, className)}
      onClick={onClick}
      disabled={type === 'disabled' || disabled}
    >
      {children}
    </button>
  );
};

export type ButtonProps = _ButtonTemplateProps & {
  href?: any;
  as?: string;
  disabled?: boolean;
};

const Button = ({
  type = 'default',
  href,
  as,
  onClick,
  className,
  children,
  disabled = false,
}: ButtonProps) => {
  const MaybeInnerButton = (
    <_ButtonTemplate
      type={type}
      onClick={onClick}
      className={className}
      disabled={disabled}
    >
      {children}
    </_ButtonTemplate>
  );

  if (href) {
    return (
      <Link href={href} as={as}>
        <a>{MaybeInnerButton}</a>
      </Link>
    );
  }

  return MaybeInnerButton;
};

export default Button;
