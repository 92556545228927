import React from 'react';

type ServerSideContextValue = {
  userAgent?: string;
};

const ServerSideContext = React.createContext<ServerSideContextValue>({});

ServerSideContext.displayName = 'ServerSideContext';

export default ServerSideContext;

export const ServerSideProvider = ServerSideContext.Provider;
