import { nanoid } from 'nanoid';
import { UAParser } from 'ua-parser-js';

import { wait } from '@shared/utils/index';

export const copyToClipboard = async (
  targetText: string
): Promise<string | void> => {
  const textArea = document.createElement('textarea');
  textArea.value = targetText;

  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  return new Promise((resolve, reject) => {
    document.execCommand('copy') ? resolve('COPY SUCCESS') : reject();
    textArea.remove();
  });
};

export const selectFiles = (
  options?: Partial<{ [T in keyof HTMLInputElement]: HTMLInputElement[T] }>
): Promise<FileList | null> => {
  return new Promise((resolve) => {
    const input = document.createElement('input');
    input.style.display = 'none';
    input.type = 'file';

    Object.assign(input, options);

    document.body.appendChild(input);

    window.addEventListener('focus', openFilePopUp);
    input.addEventListener('change', openFilePopUp);

    input.click();

    async function openFilePopUp(event: Event) {
      window.removeEventListener('focus', openFilePopUp);
      await wait(500);

      if (!input.value) {
        resolve(null);
        return;
      }

      if (event.target === window) {
        return;
      }

      document.body.removeChild(input);
      resolve((event.target as HTMLInputElement).files as FileList);
    }
  });
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => resolve(reader.result as string),
      false
    );
    reader.readAsDataURL(file);
  });
};

export const loadSdk = <T>(src: string, namespace: string): Promise<T> => {
  return new Promise((resolve, reject) => {
    const js: HTMLScriptElement = document.createElement('script');
    js.src = src;
    js.onload = () => {
      // @ts-ignore
      resolve(window[namespace]);
    };
    js.onerror = (err) => {
      reject(err);
    };
    document.body.append(js);
  });
};

export const getUserAgent = () => {
  const unparsedUserAgent = navigator.userAgent;
  const parser = new UAParser(unparsedUserAgent);
  const _userAgent = {
    browser: parser.getBrowser(),
    device: parser.getDevice(),
    os: parser.getOS(),
  };

  return _userAgent;
};

export const textParser = (str: string) => {
  const parser = new DOMParser();
  const parseResult = parser.parseFromString(str, 'text/html');
  const parsedStr = parseResult.body.textContent;

  return parsedStr || '';
};

export const hapticVibration = () => {
  // android 환경에서만 실행 가능
  if (window.navigator.vibrate) {
    window.navigator.vibrate(2);
  }
};

export const base64toFile = (dataurl: string) => {
  const dataArr: string[] = dataurl.split(',');
  const mimeCheck: string[] | null = dataArr[0].match(/:(.*?);/);
  const mime = mimeCheck && mimeCheck.length > 0 && mimeCheck[1];
  const bstr = atob(dataArr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], nanoid(), { type: mime as string });
};

export const safeJsonParse = (text: string | null | undefined) => {
  if (typeof text === 'string') {
    try {
      return JSON.parse(text);
    } catch (err) {
      return null;
    }
  } else {
    return null;
  }
};

export const selectFilesDataUrl = async (
  options?: Partial<{ [T in keyof HTMLInputElement]: HTMLInputElement[T] }>
): Promise<string[] | undefined> => {
  const files = await selectFiles(options);

  if (!files) return;

  const fileDataUrls = await Promise.all(
    Array.from(files).map(async (file) => {
      return await fileToBase64(file);
    })
  );

  return fileDataUrls;
};

export const isWebView = () => {
  // @ts-ignore
  const standalone = window.navigator.standalone;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios && !standalone && !safari) {
    // iOS webview
    return true;
  }

  // over android 8 version #https://stackoverflow.com/a/61251124
  if (!ios && userAgent.includes('wv')) {
    // Android webview
    return true;
  }

  return false;
};
