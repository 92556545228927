import * as React from 'react';
import { SVGProps } from 'react';
const SvgGoogleFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.643 6.329 15.36 8.574c-.8-.789-2.382-1.773-4.654-1.207-1.728.431-2.864 1.981-3.178 2.899L4.898 8.309c1.167-2.115 3.558-4.315 7.11-4.315 2.649 0 4.595 1.286 5.636 2.335Z"
      fill="#E74133"
    />
    <path
      d="M7.527 10.266 4.898 8.309c-1.22 2.532-1.204 5.05.043 7.457l2.594-2.008c-.293-.637-.58-2.122-.008-3.492Z"
      fill="#F9BB00"
    />
    <path
      d="m7.535 13.758-2.594 2.008c1.147 2.107 3.232 3.925 6.515 4.201 2.778.234 5.068-1.215 5.868-1.994l-2.445-2.135c-.816.547-2.143 1.352-4.306.745-1.685-.474-2.747-1.967-3.038-2.825Z"
      fill="#32A753"
    />
    <path
      d="m17.324 17.973-2.445-2.135c.596-.457 1.243-1.18 1.483-1.849h-3.953v-3.188h7.49c.127.646.229 2.34-.387 3.958-.615 1.618-1.715 2.816-2.188 3.214Z"
      fill="#3E82F1"
    />
  </svg>
);
export default SvgGoogleFilled;
