import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useSellerCenterDashboardQuery } from '../graphql/OrderMenus.generated';

import { getRateOfChange } from '@utils/number';

enum TitleType {
  NEW = 'NEW',
  EXCHANGE = 'EXCHANGE',
  RETURN = 'RETURN',
}

/**
 * @description
 * 판매탭 사이드메뉴 대시보드에 필요한 데이터를 query해오는 hook
 *
 * @returns
 * new: 신규주문,
 * exchange: 교환건수,
 * return: 반품건수,
 * sales: 오늘의 매출 (amount: 매출액, growth: 전일대비 증감률)
 */

function useSellerCenterDashboardData({ enabled }: { enabled: boolean }) {
  const today = dayjs().startOf('day');
  const yesterday = today.subtract(1, 'day');
  const { isLoading, data } = useSellerCenterDashboardQuery(
    {
      orderMenusInput: {
        type: 'ALL',
        menus: [
          {
            title: TitleType.NEW,
            period: {
              beginAt: dayjs()
                .startOf('date')
                .subtract(7, 'days')
                .toISOString(),
              endAt: dayjs().endOf('date').toISOString(),
            },
            orderLineItemStatuses: ['PAYMENT_WAIT', 'PAYMENT_COMPLETED'],
          },
          {
            title: TitleType.EXCHANGE,
            orderLineItemStatuses: ['EXCHANGE_REQUESTED'],
          },
          {
            title: TitleType.RETURN,
            orderLineItemStatuses: ['RETURN_REQUESTED'],
          },
        ],
      },
      dailySellerInput: {
        startDate: yesterday.toISOString(),
        endDate: today.toISOString(),
        isUseTime: true,
      },
    },
    { enabled }
  );

  const sellerStats = data?.dailySellerStats?.sellerStats;

  const { salesGrowthRate, salesAmount } = useMemo(() => {
    const todaySellerStats = sellerStats?.find((stats) => {
      return today.isSame(stats?.date, 'date');
    });
    const yesterdaySellerStats = sellerStats?.find((stats) => {
      return yesterday.isSame(stats?.date, 'date');
    });

    return {
      salesAmount: todaySellerStats?.sales || 0,
      salesGrowthRate: getRateOfChange(
        yesterdaySellerStats?.sales || 0,
        todaySellerStats?.sales || 0
      ),
    };
  }, [sellerStats, today, yesterday]);

  const getCount = (title: TitleType) =>
    data?.orderMenus?.menus.find((item) => item.title === title)?.count;

  return {
    isLoading,
    data: {
      new: getCount(TitleType.NEW),
      exchange: getCount(TitleType.EXCHANGE),
      return: getCount(TitleType.RETURN),
      sales: {
        amount: salesAmount,
        growth: salesGrowthRate,
      },
    },
  };
}

export default useSellerCenterDashboardData;
