import * as React from 'react';
import { SVGProps } from 'react';
const SvgCalendarSmallDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke="#D8D8D8"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2 3h12v3H2zM5 2v2M2 6h12v8H2zM8 2v2M11 2v2"
    />
    <circle cx={5} cy={8.75} r={0.75} fill="#D8D8D8" />
    <circle cx={8} cy={8.75} r={0.75} fill="#D8D8D8" />
    <circle cx={11} cy={8.75} r={0.75} fill="#D8D8D8" />
    <circle cx={5} cy={11.25} r={0.75} fill="#D8D8D8" />
    <circle cx={8} cy={11.25} r={0.75} fill="#D8D8D8" />
  </svg>
);
export default SvgCalendarSmallDisabled;
