import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

import { EVENT_TAG } from '@global/constants';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import { useYourKemiQuery } from '@multilink/graphql/YourKemi.generated';
import { useProductCardDetailQuery } from '@product/product-detail/product-card-detail/graphql/ProductCardDetail.generated';
import { ROUTE_QUERY } from '@routers';
import { isObjectEmpty } from '@utils/collection';

/**
 * 케미 방문자 로그 이벤트 호출 규칙
 * - 너의 케미 진입시
 * - 상품상세페이지 진입시
 * - 커미션 상세 페이지 진입시
 */
const useVisitKemiEvent = () => {
  const router = useRouter();

  const linkName = (router.query[ROUTE_QUERY.linkName] as string) || '';
  const productCardId =
    (router.query[ROUTE_QUERY.productCardId] as string) || '';

  const { data: yourKemiData } = useYourKemiQuery(
    { linkName: (linkName as string) || '' },
    {
      enabled: false,
      select(data) {
        return {
          kemiId: data.kemi?.id || '',
        };
      },
    }
  );

  const { data: productCardData } = useProductCardDetailQuery(
    { productCardId },
    {
      enabled: false,
      select(data) {
        return {
          kemiId: isObjectEmpty(data.card)
            ? ''
            : data.card?.user?.kemi.id || '',
        };
      },
    }
  );

  const kemiId = useMemo<string>(() => {
    if (productCardId) {
      return productCardData?.kemiId || '';
    }

    return yourKemiData?.kemiId || '';
  }, [linkName, productCardId, yourKemiData, productCardData]);

  useEffect(() => {
    if (kemiId) {
      logFirebase(
        UserInteractionType.PAGE_IN,
        EVENT_TAG.GLOBAL_PAGE_OPEN.VISIT_KEMI,
        {
          kemi_id: kemiId,
          path: router.asPath,
        }
      );
    }
  }, [kemiId]);
};

export default useVisitKemiEvent;
