import Image from 'next/image';

import { ButtonProps } from './type';

import kemiLoader from '@static/media/kemi-fullpage-loader.gif';
import { styled } from '@styles/stitches.config';

const Button = ({
  buttonStyle = 'primary',
  buttonSize = 'l',
  loading = false,
  children,
  onClick,
  caption,
  ...props
}: ButtonProps) => {
  return (
    <>
      <BaseButton
        onClick={onClick}
        buttonStyle={buttonStyle}
        buttonSize={buttonSize}
        loading={loading}
        {...props}
      >
        {loading ? (
          <div>
            <Image src={kemiLoader} width={88} height={16} alt={'loader'} />
          </div>
        ) : (
          <ButtonContent>{children}</ButtonContent>
        )}
      </BaseButton>
      {caption && <Caption>{caption}</Caption>}
    </>
  );
};

const BaseButton = styled('button', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingX: 12,
  borderRadius: 8,
  cursor: 'pointer',
  textAlign: 'center',
  width: '100%',
  color: '$white',

  '&:disabled': {
    boxShadow: 'none',
  },

  variants: {
    buttonSize: {
      l: {
        height: 48,
        fontType: 'subtitleBold',
      },
      m: {
        height: 40,
        fontType: 'subtitleBold',
      },
      s: {
        height: 32,
        fontType: 'captionBold',
      },
    },
    buttonStyle: {
      primary: {
        backgroundColor: '$primary200',
        boxShadow: '$primaryShadow',
        '&:hover': {
          '@onlyHoverDevice': {
            backgroundColor: '$primary100',
          },
        },
        '&:disabled': {
          backgroundColor: '$primary50',
        },
      },
      secondary: {
        backgroundColor: '$black',
        '&:hover': {
          '@onlyHoverDevice': {
            backgroundColor: '$grey100',
          },
        },
        '&:disabled': {
          backgroundColor: '$grey50',
        },
      },
      tertiary: {
        color: '$black',
        border: '1px solid $grey50',
        backgroundColor: '$white',
        '&:hover': {
          '@onlyHoverDevice': {
            backgroundColor: '$grey20',
            border: '1px solid $grey100',
          },
        },
        '&:disabled': {
          color: '$grey50',
          backgroundColor: '$grey20',
          border: '1px solid $grey50',
        },
      },
      negative: {
        color: '$red100',
        '&:hover': {
          '@onlyHoverDevice': {
            backgroundColor: '$grey20',
          },
        },
        '&:disabled': {
          color: '$red50',
          backgroundColor: '$grey20',
        },
      },
    },
    // type error 방지를 위한 variants
    loading: {
      true: {},
    },
  },

  compoundVariants: [
    {
      buttonStyle: 'primary',
      loading: 'true',
      css: {
        backgroundColor: '$primary50',
      },
    },
    {
      buttonStyle: 'secondary',
      loading: 'true',
      css: {
        backgroundColor: '$black',
      },
    },
    {
      buttonStyle: 'tertiary',
      loading: 'true',
      css: {
        backgroundColor: '$white',
      },
    },
    {
      buttonStyle: 'negative',
      loading: 'true',
      css: {
        backgroundColor: '$white',
      },
    },
  ],
});

const ButtonContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,
});

const Caption = styled('span', {
  display: 'block',
  font: 'captionRegular',
  color: '$grey100',
  marginTop: 8,
});

export default Button;
