import classNames from 'classnames/bind';
import {
  createElement,
  ReactHTML,
  ReactNode,
  HTMLAttributes,
  forwardRef,
} from 'react';

import { ColorName, colors } from '@styles/constants';
import { ShadowType, css } from '@styles/stitches.config';

type BoxStyleProps = {
  borderColor?: ColorName;
  shadow?: ShadowType;
  backgroundColor?: ColorName;
};
export type BoxProps = HTMLAttributes<Element> & {
  type?: keyof ReactHTML;
  className?: string;
  children?: ReactNode;
} & BoxStyleProps;

const getBoxClassName = ({
  borderColor,
  shadow,
  backgroundColor,
}: BoxStyleProps) => {
  return box({
    css: {
      ...(borderColor && { border1: colors[borderColor] }),
      ...(shadow && { boxShadow: shadow }),
      ...(backgroundColor && {
        background: colors[backgroundColor],
      }),
    },
  });
};

// eslint-disable-next-line react/display-name
const Box = forwardRef(
  (
    {
      type = 'div',
      borderColor,
      shadow,
      backgroundColor,
      className,
      children,
      ...rest
    }: BoxProps,
    ref
  ) => {
    const boxClassName = getBoxClassName({
      borderColor,
      shadow,
      backgroundColor,
    });

    return createElement(
      type,
      {
        ref,
        className: classNames(boxClassName.className, className),
        ...rest,
      },
      children
    );
  }
);

const box = css({});

export default Box;
