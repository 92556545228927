import * as Types from 'schema/types';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type LoginWithKemiMutationVariables = Types.Exact<{
  loginId: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;

export type LoginWithKemiMutationResponse = {
  login: { token: string; refreshToken?: string | null };
};

export type LoginWithKakaoMutationVariables = Types.Exact<{
  snsAccessToken: Types.Scalars['String'];
}>;

export type LoginWithKakaoMutationResponse = {
  loginWithKakaoToken: { token: string; refreshToken: string };
};

export type RegisterWithKakaoMutationVariables = Types.Exact<{
  snsAccessToken: Types.Scalars['String'];
  kemiOption: Types.KemiOptionInput;
  linkName?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type RegisterWithKakaoMutationResponse = {
  registerWithKakaoToken?: { id: string } | null;
};

export type LoginWithNaverMutationVariables = Types.Exact<{
  snsAccessToken: Types.Scalars['String'];
}>;

export type LoginWithNaverMutationResponse = {
  loginWithNaverToken: { token: string; refreshToken: string };
};

export type RegisterWithNaverMutationVariables = Types.Exact<{
  snsAccessToken: Types.Scalars['String'];
  kemiOption: Types.KemiOptionInput;
  linkName?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type RegisterWithNaverMutationResponse = {
  registerWithNaverToken?: { id: string } | null;
};

export type LoginWithGoogleMutationVariables = Types.Exact<{
  snsAccessToken: Types.Scalars['String'];
}>;

export type LoginWithGoogleMutationResponse = {
  loginWithGoogleToken: { token: string; refreshToken: string };
};

export type RegisterWithGoogleMutationVariables = Types.Exact<{
  snsAccessToken: Types.Scalars['String'];
  kemiOption: Types.KemiOptionInput;
  linkName?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type RegisterWithGoogleMutationResponse = {
  registerWithGoogleToken?: { id: string } | null;
};

export type LoginWithFacebookMutationVariables = Types.Exact<{
  snsAccessToken: Types.Scalars['String'];
}>;

export type LoginWithFacebookMutationResponse = {
  loginWithFacebookToken: { token: string; refreshToken: string };
};

export type RegisterWithFacebookMutationVariables = Types.Exact<{
  snsAccessToken: Types.Scalars['String'];
  kemiOption: Types.KemiOptionInput;
  linkName?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type RegisterWithFacebookMutationResponse = {
  registerWithFacebookToken?: { id: string } | null;
};

export type ReissueAccessTokenMutationVariables = Types.Exact<{
  expiredAccessToken: Types.Scalars['String'];
  refreshToken: Types.Scalars['String'];
}>;

export type ReissueAccessTokenMutationResponse = {
  reissueAccessTokenByRefreshToken: string;
};

export type LogoutMutationVariables = Types.Exact<{ [key: string]: never }>;

export type LogoutMutationResponse = { logout?: { success: boolean } | null };

export const LoginWithKemiDocument = `
    mutation LoginWithKemi($loginId: String!, $password: String!) {
  login(loginId: $loginId, password: $password) {
    token
    refreshToken
  }
}
    `;
export const useLoginWithKemiMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    LoginWithKemiMutationResponse,
    TError,
    LoginWithKemiMutationVariables,
    TContext
  >
) =>
  useMutation<
    LoginWithKemiMutationResponse,
    TError,
    LoginWithKemiMutationVariables,
    TContext
  >(
    ['LoginWithKemi'],
    (variables?: LoginWithKemiMutationVariables) =>
      fetcher<LoginWithKemiMutationResponse, LoginWithKemiMutationVariables>(
        LoginWithKemiDocument,
        variables
      )(),
    options
  );
useLoginWithKemiMutation.getKey = () => ['LoginWithKemi'];

useLoginWithKemiMutation.fetcher = (
  variables: LoginWithKemiMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<LoginWithKemiMutationResponse, LoginWithKemiMutationVariables>(
    LoginWithKemiDocument,
    variables,
    options
  );
export const LoginWithKakaoDocument = `
    mutation LoginWithKakao($snsAccessToken: String!) {
  loginWithKakaoToken(snsAccessToken: $snsAccessToken) {
    token
    refreshToken
  }
}
    `;
export const useLoginWithKakaoMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    LoginWithKakaoMutationResponse,
    TError,
    LoginWithKakaoMutationVariables,
    TContext
  >
) =>
  useMutation<
    LoginWithKakaoMutationResponse,
    TError,
    LoginWithKakaoMutationVariables,
    TContext
  >(
    ['LoginWithKakao'],
    (variables?: LoginWithKakaoMutationVariables) =>
      fetcher<LoginWithKakaoMutationResponse, LoginWithKakaoMutationVariables>(
        LoginWithKakaoDocument,
        variables
      )(),
    options
  );
useLoginWithKakaoMutation.getKey = () => ['LoginWithKakao'];

useLoginWithKakaoMutation.fetcher = (
  variables: LoginWithKakaoMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<LoginWithKakaoMutationResponse, LoginWithKakaoMutationVariables>(
    LoginWithKakaoDocument,
    variables,
    options
  );
export const RegisterWithKakaoDocument = `
    mutation RegisterWithKakao($snsAccessToken: String!, $kemiOption: KemiOptionInput!, $linkName: String) {
  registerWithKakaoToken(
    snsAccessToken: $snsAccessToken
    kemiOption: $kemiOption
    linkName: $linkName
  ) {
    id
  }
}
    `;
export const useRegisterWithKakaoMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RegisterWithKakaoMutationResponse,
    TError,
    RegisterWithKakaoMutationVariables,
    TContext
  >
) =>
  useMutation<
    RegisterWithKakaoMutationResponse,
    TError,
    RegisterWithKakaoMutationVariables,
    TContext
  >(
    ['RegisterWithKakao'],
    (variables?: RegisterWithKakaoMutationVariables) =>
      fetcher<
        RegisterWithKakaoMutationResponse,
        RegisterWithKakaoMutationVariables
      >(RegisterWithKakaoDocument, variables)(),
    options
  );
useRegisterWithKakaoMutation.getKey = () => ['RegisterWithKakao'];

useRegisterWithKakaoMutation.fetcher = (
  variables: RegisterWithKakaoMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    RegisterWithKakaoMutationResponse,
    RegisterWithKakaoMutationVariables
  >(RegisterWithKakaoDocument, variables, options);
export const LoginWithNaverDocument = `
    mutation LoginWithNaver($snsAccessToken: String!) {
  loginWithNaverToken(snsAccessToken: $snsAccessToken) {
    token
    refreshToken
  }
}
    `;
export const useLoginWithNaverMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    LoginWithNaverMutationResponse,
    TError,
    LoginWithNaverMutationVariables,
    TContext
  >
) =>
  useMutation<
    LoginWithNaverMutationResponse,
    TError,
    LoginWithNaverMutationVariables,
    TContext
  >(
    ['LoginWithNaver'],
    (variables?: LoginWithNaverMutationVariables) =>
      fetcher<LoginWithNaverMutationResponse, LoginWithNaverMutationVariables>(
        LoginWithNaverDocument,
        variables
      )(),
    options
  );
useLoginWithNaverMutation.getKey = () => ['LoginWithNaver'];

useLoginWithNaverMutation.fetcher = (
  variables: LoginWithNaverMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<LoginWithNaverMutationResponse, LoginWithNaverMutationVariables>(
    LoginWithNaverDocument,
    variables,
    options
  );
export const RegisterWithNaverDocument = `
    mutation RegisterWithNaver($snsAccessToken: String!, $kemiOption: KemiOptionInput!, $linkName: String) {
  registerWithNaverToken(
    snsAccessToken: $snsAccessToken
    kemiOption: $kemiOption
    linkName: $linkName
  ) {
    id
  }
}
    `;
export const useRegisterWithNaverMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RegisterWithNaverMutationResponse,
    TError,
    RegisterWithNaverMutationVariables,
    TContext
  >
) =>
  useMutation<
    RegisterWithNaverMutationResponse,
    TError,
    RegisterWithNaverMutationVariables,
    TContext
  >(
    ['RegisterWithNaver'],
    (variables?: RegisterWithNaverMutationVariables) =>
      fetcher<
        RegisterWithNaverMutationResponse,
        RegisterWithNaverMutationVariables
      >(RegisterWithNaverDocument, variables)(),
    options
  );
useRegisterWithNaverMutation.getKey = () => ['RegisterWithNaver'];

useRegisterWithNaverMutation.fetcher = (
  variables: RegisterWithNaverMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    RegisterWithNaverMutationResponse,
    RegisterWithNaverMutationVariables
  >(RegisterWithNaverDocument, variables, options);
export const LoginWithGoogleDocument = `
    mutation LoginWithGoogle($snsAccessToken: String!) {
  loginWithGoogleToken(snsAccessToken: $snsAccessToken) {
    token
    refreshToken
  }
}
    `;
export const useLoginWithGoogleMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    LoginWithGoogleMutationResponse,
    TError,
    LoginWithGoogleMutationVariables,
    TContext
  >
) =>
  useMutation<
    LoginWithGoogleMutationResponse,
    TError,
    LoginWithGoogleMutationVariables,
    TContext
  >(
    ['LoginWithGoogle'],
    (variables?: LoginWithGoogleMutationVariables) =>
      fetcher<
        LoginWithGoogleMutationResponse,
        LoginWithGoogleMutationVariables
      >(LoginWithGoogleDocument, variables)(),
    options
  );
useLoginWithGoogleMutation.getKey = () => ['LoginWithGoogle'];

useLoginWithGoogleMutation.fetcher = (
  variables: LoginWithGoogleMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<LoginWithGoogleMutationResponse, LoginWithGoogleMutationVariables>(
    LoginWithGoogleDocument,
    variables,
    options
  );
export const RegisterWithGoogleDocument = `
    mutation RegisterWithGoogle($snsAccessToken: String!, $kemiOption: KemiOptionInput!, $linkName: String) {
  registerWithGoogleToken(
    snsAccessToken: $snsAccessToken
    kemiOption: $kemiOption
    linkName: $linkName
  ) {
    id
  }
}
    `;
export const useRegisterWithGoogleMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RegisterWithGoogleMutationResponse,
    TError,
    RegisterWithGoogleMutationVariables,
    TContext
  >
) =>
  useMutation<
    RegisterWithGoogleMutationResponse,
    TError,
    RegisterWithGoogleMutationVariables,
    TContext
  >(
    ['RegisterWithGoogle'],
    (variables?: RegisterWithGoogleMutationVariables) =>
      fetcher<
        RegisterWithGoogleMutationResponse,
        RegisterWithGoogleMutationVariables
      >(RegisterWithGoogleDocument, variables)(),
    options
  );
useRegisterWithGoogleMutation.getKey = () => ['RegisterWithGoogle'];

useRegisterWithGoogleMutation.fetcher = (
  variables: RegisterWithGoogleMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    RegisterWithGoogleMutationResponse,
    RegisterWithGoogleMutationVariables
  >(RegisterWithGoogleDocument, variables, options);
export const LoginWithFacebookDocument = `
    mutation LoginWithFacebook($snsAccessToken: String!) {
  loginWithFacebookToken(snsAccessToken: $snsAccessToken) {
    token
    refreshToken
  }
}
    `;
export const useLoginWithFacebookMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    LoginWithFacebookMutationResponse,
    TError,
    LoginWithFacebookMutationVariables,
    TContext
  >
) =>
  useMutation<
    LoginWithFacebookMutationResponse,
    TError,
    LoginWithFacebookMutationVariables,
    TContext
  >(
    ['LoginWithFacebook'],
    (variables?: LoginWithFacebookMutationVariables) =>
      fetcher<
        LoginWithFacebookMutationResponse,
        LoginWithFacebookMutationVariables
      >(LoginWithFacebookDocument, variables)(),
    options
  );
useLoginWithFacebookMutation.getKey = () => ['LoginWithFacebook'];

useLoginWithFacebookMutation.fetcher = (
  variables: LoginWithFacebookMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    LoginWithFacebookMutationResponse,
    LoginWithFacebookMutationVariables
  >(LoginWithFacebookDocument, variables, options);
export const RegisterWithFacebookDocument = `
    mutation RegisterWithFacebook($snsAccessToken: String!, $kemiOption: KemiOptionInput!, $linkName: String) {
  registerWithFacebookToken(
    snsAccessToken: $snsAccessToken
    kemiOption: $kemiOption
    linkName: $linkName
  ) {
    id
  }
}
    `;
export const useRegisterWithFacebookMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RegisterWithFacebookMutationResponse,
    TError,
    RegisterWithFacebookMutationVariables,
    TContext
  >
) =>
  useMutation<
    RegisterWithFacebookMutationResponse,
    TError,
    RegisterWithFacebookMutationVariables,
    TContext
  >(
    ['RegisterWithFacebook'],
    (variables?: RegisterWithFacebookMutationVariables) =>
      fetcher<
        RegisterWithFacebookMutationResponse,
        RegisterWithFacebookMutationVariables
      >(RegisterWithFacebookDocument, variables)(),
    options
  );
useRegisterWithFacebookMutation.getKey = () => ['RegisterWithFacebook'];

useRegisterWithFacebookMutation.fetcher = (
  variables: RegisterWithFacebookMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    RegisterWithFacebookMutationResponse,
    RegisterWithFacebookMutationVariables
  >(RegisterWithFacebookDocument, variables, options);
export const ReissueAccessTokenDocument = `
    mutation ReissueAccessToken($expiredAccessToken: String!, $refreshToken: String!) {
  reissueAccessTokenByRefreshToken(
    expiredAccessToken: $expiredAccessToken
    refreshToken: $refreshToken
  )
}
    `;
export const useReissueAccessTokenMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ReissueAccessTokenMutationResponse,
    TError,
    ReissueAccessTokenMutationVariables,
    TContext
  >
) =>
  useMutation<
    ReissueAccessTokenMutationResponse,
    TError,
    ReissueAccessTokenMutationVariables,
    TContext
  >(
    ['ReissueAccessToken'],
    (variables?: ReissueAccessTokenMutationVariables) =>
      fetcher<
        ReissueAccessTokenMutationResponse,
        ReissueAccessTokenMutationVariables
      >(ReissueAccessTokenDocument, variables)(),
    options
  );
useReissueAccessTokenMutation.getKey = () => ['ReissueAccessToken'];

useReissueAccessTokenMutation.fetcher = (
  variables: ReissueAccessTokenMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    ReissueAccessTokenMutationResponse,
    ReissueAccessTokenMutationVariables
  >(ReissueAccessTokenDocument, variables, options);
export const LogoutDocument = `
    mutation Logout {
  logout {
    success
  }
}
    `;
export const useLogoutMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    LogoutMutationResponse,
    TError,
    LogoutMutationVariables,
    TContext
  >
) =>
  useMutation<
    LogoutMutationResponse,
    TError,
    LogoutMutationVariables,
    TContext
  >(
    ['Logout'],
    (variables?: LogoutMutationVariables) =>
      fetcher<LogoutMutationResponse, LogoutMutationVariables>(
        LogoutDocument,
        variables
      )(),
    options
  );
useLogoutMutation.getKey = () => ['Logout'];

useLogoutMutation.fetcher = (
  variables?: LogoutMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<LogoutMutationResponse, LogoutMutationVariables>(
    LogoutDocument,
    variables,
    options
  );
