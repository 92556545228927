import { SVGProps } from 'react';

type KemiLogoProps = SVGProps<SVGSVGElement>;

const KemiLogo = ({ ...rest }: KemiLogoProps) => {
  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={
          'M2.2698 12.4856C2.10201 12.1836 2.10201 11.8164 2.2698 11.5144L6.71425 3.51436C6.89062 3.19689 7.22524 3 7.5884 3H16.4116C16.7748 3 17.1094 3.19689 17.2858 3.51436L21.7302 11.5144C21.898 11.8164 21.898 12.1836 21.7302 12.4856L17.2858 20.4856C17.1094 20.8031 16.7748 21 16.4116 21H7.5884C7.22524 21 6.89061 20.8031 6.71425 20.4856L2.2698 12.4856Z'
        }
        fill={'url(#paint0_linear_1727_428)'}
      />
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={'M12.6154 9.91291V6.78247H13.4359V9.91291H12.6154Z'}
        fill={'#8C3CDC'}
      />
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={'M10.5641 9.91291V6.78247H11.3846V9.91291H10.5641Z'}
        fill={'#8C3CDC'}
      />
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M8.8326 10.4346C9.5182 11.0684 10.8322 11.7389 12 11.7389C13.1678 11.7389 14.4818 11.0684 15.1674 10.4346L15.5898 11.0867C14.7664 11.848 13.2919 12.5215 12 12.5215C10.7082 12.5215 9.23368 11.848 8.41028 11.0867L8.8326 10.4346Z'
        }
        fill={'#8C3CDC'}
      />
      <defs>
        <linearGradient
          id={'paint0_linear_1727_428'}
          x1={'7'}
          y1={'3'}
          x2={'17.4733'}
          y2={'20.7452'}
          gradientUnits={'userSpaceOnUse'}
        >
          <stop stopColor={'#E8C7FF'} />
          <stop offset={'1'} stopColor={'#CA7CFF'} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default KemiLogo;
