import axios, { AxiosError } from 'axios';
import qs from 'qs';

import NextApiError from '@global/service/Error/NextApiError';

const axiosClient = axios.create({
  baseURL: '/api',
  paramsSerializer: {
    serialize: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  },
});

axiosClient.interceptors.response.use(
  (response) => {
    // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    // 응답 데이터가 있는 작업 수행
    return response;
  },
  (error) => {
    // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    // 응답 오류가 있는 작업 수행
    // Next api 용도로 사용하므로 Next api 에러 생성자로 wrapping 합니다.
    if (error instanceof AxiosError) {
      return Promise.reject(new NextApiError(error.toJSON()));
    }

    return Promise.reject(new NextApiError(error));
  }
);

export default axiosClient;
