import React, { PropsWithChildren, Suspense } from 'react';

import PageToastContainer from '@design0.2/Toast/PageToastContainer';
import KemiLayout from '@pages/app/components/KemiLayout';
import KemiPlaygroundDesktopHeader from '@pages/app/components/KemiPlaygroundDesktopHeader';
import KemiPlaygroundDesktopSideMenu from '@pages/app/components/KemiPlaygroundDesktopSideMenu';
import useUserAgent from '@shared/hooks/useUserAgent';
import { styled } from '@styles/stitches.config';

type DesktopContentMode = 'DESKTOP' | 'MOBILE';

const KemiPlaygroundLayout = ({
  desktopContentMode,
  children,
}: PropsWithChildren<{ desktopContentMode: DesktopContentMode }>) => {
  const { isDesktop } = useUserAgent();

  if (!isDesktop) {
    return <KemiLayout>{children}</KemiLayout>;
  }

  return (
    <LayoutContainer>
      <HeaderContainer>
        <Suspense fallback={null}>
          <KemiPlaygroundDesktopHeader />
        </Suspense>
      </HeaderContainer>
      <Content>
        <SideMenu>
          <Suspense fallback={null}>
            <KemiPlaygroundDesktopSideMenu />
          </Suspense>
        </SideMenu>
        <ContentWrapper pcViewContent={desktopContentMode}>
          <ToastPosition>
            <PageToastContainer />
          </ToastPosition>
          {children}
        </ContentWrapper>
      </Content>
    </LayoutContainer>
  );
};

const LayoutContainer = styled('div', {
  minHeight: 'inherit',
  display: 'grid',
  gridTemplateRows: '$headerHeight 1fr',
  width: '100%',
});

const HeaderContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  position: 'sticky',
  top: 0,
  background: '$white',
  zIndex: '$header',
  borderBottom: '1px solid $grey50',
});

const SideMenu = styled('div', {
  position: 'sticky',
  top: '$sizes$headerHeight',
  height: '$contentHeight',
  overflowY: 'scroll',
  padding: 16,
});

const Content = styled('div', {
  gridColumnWithGap: 0,
  justifyContent: 'center',
  gridTemplateColumns: 'minmax(240px, 400px) minmax(800px, 1200px)',
});

const ContentWrapper = styled('div', {
  position: 'relative',
  borderLeft: '1px solid $grey50',
  variants: {
    pcViewContent: {
      MOBILE: {
        width: 400,
      },
      DESKTOP: {},
    },
  },
});

const ToastPosition = styled('div', {
  position: 'sticky',
  top: '$sizes$headerHeight',
  left: 0,
  width: '100%',
  zIndex: '$playgroundPageToast',
});

export default KemiPlaygroundLayout;
