import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import {
  useDailyInstagramStatsForSummaryQuery,
  useInstagramMediasQuery,
} from '../graphql/snsAnalyticsSummary.generated';
import { makeInstagramProfileData, makeInstagramSummaryData } from '../helper';
import { InstagramMediaResponseType } from '../type';

import {
  useInstagramSummaryDataQuery,
  useInstagramProfileDataQuery,
} from './useInstagramQueries';

import { useModalToast } from '@design0.2/Toast/hooks/useToast';
import useFacebookAccess from '@global/hooks/useFacebookAccess';
import { useUpdateSellerInfoForFacebookAccessMutation } from '@global/hooks/useFacebookAccess/graphql/FacebookAccess.generated';
import useUserQuery from '@global/queries/useUserQuery';
import { useVisitorsAndBuyersByTimesAnalyticsQuery } from '@pages/mykemi/analytics/source/hooks/useVisitorsAndBuyersByTimesAnalyticsQuery';

function useInstagramAnalyticsSummary() {
  const toast = useModalToast();
  const now = dayjs().endOf('minute');
  const yesterday = now.subtract(1, 'day');
  const startOfYesterday = yesterday.startOf('day').toISOString();
  const endOfYesterday = yesterday.endOf('day').toISOString();
  const recent7DayStart = now.subtract(7, 'day').toISOString();
  const recent7DayEnd = now.toISOString();

  const { data } = useUserQuery();
  const kemiId = data?.kemiId;

  const { isFacebookAuthLoading, facebookAuthData, requestFacebookAccess } =
    useFacebookAccess();
  const { mutateAsync } = useUpdateSellerInfoForFacebookAccessMutation();

  const { data: yesterdayInstagramData } =
    useDailyInstagramStatsForSummaryQuery({
      dailyInstagramInput: {
        startDate: startOfYesterday,
        endDate: endOfYesterday,
      },
    });
  const { isLoading: isRecent7daysMediaLoading, data: recent7DaysMediaData } =
    useInstagramMediasQuery({
      instagramMediaInput: {
        endDate: recent7DayEnd,
        startDate: recent7DayStart,
      },
    });

  const {
    isLoading: isVisitorsAndBuyersByTimeLoading,
    data: visitorsAndBuyersByTimeData,
  } = useVisitorsAndBuyersByTimesAnalyticsQuery(
    recent7DaysMediaData?.instagramMedias?.medias
      ? recent7DaysMediaData.instagramMedias.medias.map(
          (media) => media?.mediaCreatedAt || ''
        )
      : [],
    kemiId as string,
    dayjs().utcOffset(),
    'isVisitorsAndBuyersByTimeLoading'
  );

  const { data: summaryData, error: summaryError } =
    useInstagramSummaryDataQuery({
      enabled:
        !!facebookAuthData?.instagramUserId && !!facebookAuthData?.accessToken,
      instagramUserId: facebookAuthData?.instagramUserId,
      accessToken: facebookAuthData?.accessToken,
    });

  const { data: profileData, error: profileError } =
    useInstagramProfileDataQuery({
      enabled:
        !!facebookAuthData?.instagramUserId && !!facebookAuthData?.accessToken,
      instagramUserId: facebookAuthData?.instagramUserId,
      accessToken: facebookAuthData?.accessToken,
    });

  const [top3Data, setTop3Data] = useState<{
    top3ByVisitors: InstagramMediaResponseType[];
    top3ByBuyers: InstagramMediaResponseType[];
  }>({
    top3ByVisitors: [],
    top3ByBuyers: [],
  });

  const getTop3Data = () => {
    const recent7DayMediaList = recent7DaysMediaData?.instagramMedias?.medias;

    if (!visitorsAndBuyersByTimeData || !visitorsAndBuyersByTimeData.length)
      return;

    if (!recent7DayMediaList) return;

    const sortedByVisitors = [...visitorsAndBuyersByTimeData]
      .sort((a, b) => b.visitors - a.visitors)
      .splice(0, 3);
    const top3ByVisitors =
      sortedByVisitors.map((sortedByVisitorsItem) => {
        return recent7DayMediaList.find(
          (recent7DayMediaItem) =>
            dayjs(recent7DayMediaItem?.mediaCreatedAt)
              .add(9, 'hour')
              .toISOString() ===
            dayjs(sortedByVisitorsItem?.startDatetime).toISOString()
        );
      }) || [];

    const sortedByBuyers = [...visitorsAndBuyersByTimeData]
      .sort((a, b) => b.buyers - a.buyers)
      .splice(0, 3);
    const top3ByBuyers =
      sortedByBuyers.map((sortedByBuyersItem) => {
        return recent7DayMediaList.find(
          (recent7DayMediaItem) =>
            dayjs(recent7DayMediaItem?.mediaCreatedAt)
              .add(9, 'hour')
              .toISOString() ===
            dayjs(sortedByBuyersItem?.startDatetime).toISOString()
        );
      }) || [];

    const _top3Data = {
      top3ByVisitors,
      top3ByBuyers,
    };

    setTop3Data(_top3Data);
  };

  useEffect(() => {
    if (!isRecent7daysMediaLoading && !isVisitorsAndBuyersByTimeLoading) {
      getTop3Data();
    }
  }, [isRecent7daysMediaLoading, isVisitorsAndBuyersByTimeLoading]);

  useEffect(() => {
    if (!!summaryError || !!profileError) {
      mutateAsync({
        sellerInfo: { instagram: { accessToken: null, instagramUserId: null } },
      });

      toast.bad('인스타그램 토큰이 만료되었습니다. 다시 로그인해 주세요.');
    }
  }, [summaryError, profileError]);

  return {
    isFacebookAuthLoading,
    isTop3DataLoading:
      isRecent7daysMediaLoading && isVisitorsAndBuyersByTimeLoading,
    isFacebookAuthorized:
      facebookAuthData?.accessToken && facebookAuthData.instagramUserId,
    profileData: !!profileData && makeInstagramProfileData(profileData),
    summaryData: !!summaryData
      ? makeInstagramSummaryData({
          instagramSummaryData: summaryData,
          yesterdayInstagramData,
        })
      : null,
    top3Data,
    requestFacebookAccess,
  };
}

export default useInstagramAnalyticsSummary;
