import { EImageSize } from '@schema/types';

export const getImageWidth = (size: EImageSize) => {
  switch (size) {
    case 'MEDIUM':
      return 112;
    case 'SMALL':
      return 72;
    default:
      return 0;
  }
};
