import * as React from 'react';
import { SVGProps } from 'react';
const SvgCalendarBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4 5h16v4H4zM4 9h16v11H4zM8 4v2M8 12v1M12 12v1M16 12v1M8 16v1M12 16v1M12 4v2M16 4v2"
    />
  </svg>
);
export default SvgCalendarBlack;
