import * as React from 'react';
import { SVGProps } from 'react';
const SvgPersonalInformationBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 8v2M12.5 8v2M8 12c.333.333 1.4 1 3 1s2.667-.667 3-1M16 16h6v5h-6zM17 16v-1a2 2 0 1 1 4 0v1"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m16 18.111-.05.09m0 0v0c-.278.494-.8.799-1.367.799H7.67a2 2 0 0 1-1.743-1.02l-3.375-6a2 2 0 0 1 0-1.96l3.375-6A2 2 0 0 1 7.67 3h6.66a2 2 0 0 1 1.743 1.02l3.375 6a2 2 0 0 1 0 1.96l-.51.909a.218.218 0 0 1-.19.111v0c-.966 0-1.748.782-1.748 1.747v.92c0 .184-.15.333-.333.333h-.173c-.274 0-.499.22-.505.494l-.04 1.707Z"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPersonalInformationBlack;
