import * as React from 'react';
import { SVGProps } from 'react';
const SvgLikeDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m12 7.625-.669.34a.75.75 0 0 0 1.338 0L12 7.625ZM12 19l-.476.58a.75.75 0 0 0 .952 0L12 19ZM8.444 5.75c.806 0 1.441.38 1.938.875.5.5.814 1.073.95 1.34l1.337-.68a6.834 6.834 0 0 0-1.228-1.722C10.767 4.89 9.772 4.25 8.444 4.25v1.5ZM4.75 9.375A3.668 3.668 0 0 1 8.444 5.75v-1.5c-3.118 0-5.194 2.561-5.194 5.125h1.5Zm7.726 9.045c-2.67-2.19-4.623-3.903-5.91-5.381-1.29-1.482-1.816-2.621-1.816-3.664h-1.5c0 1.582.808 3.068 2.184 4.649 1.38 1.584 3.426 3.37 6.09 5.556l.952-1.16Zm0 1.16c2.664-2.186 4.71-3.972 6.09-5.556 1.377-1.581 2.184-3.067 2.184-4.649h-1.5c0 1.043-.526 2.182-1.816 3.664-1.287 1.478-3.24 3.192-5.91 5.381l.952 1.16ZM20.75 9.375c0-2.564-2.076-5.125-5.194-5.125v1.5a3.668 3.668 0 0 1 3.694 3.625h1.5ZM15.556 4.25c-1.328 0-2.323.64-2.997 1.313a6.834 6.834 0 0 0-1.228 1.722l1.338.68c.135-.267.448-.84.95-1.34.496-.495 1.131-.875 1.937-.875v-1.5Z"
      fill="#D8D8D8"
    />
  </svg>
);
export default SvgLikeDisabled;
