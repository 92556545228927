import { BadgeProps } from '@design0.2/Badge/Badge';
import CardContent from '@design0.2/Cards/shared/components/CardContent';
import { CardContainer } from '@design0.2/Cards/shared/components/Cards';
import CardThumbnail from '@design0.2/Cards/shared/components/CardThumbnail';
import { ECardThumbnailType } from '@design0.2/Cards/shared/types';
import { EImageSize } from '@schema/types';
import { styled } from '@styles/stitches.config';

export type CardProps = {
  imageSize?: EImageSize;
  imageUrl?: string;
  showImage?: boolean;
  emoji?: string;
  title: string | JSX.Element;
  badges?: BadgeProps[];
  showBadge?: boolean;
  isLoading?: boolean;
  hasError?: boolean;
  hide?: boolean;
  label?: string;
  onClick?: () => void;
  isDragging?: boolean;
  dragHandler?: JSX.Element | null;
  videoIcon?: boolean;
  musicIcon?: boolean;
  placeholder?: string;
  thumbnailType: ECardThumbnailType;
  onImageError?: () => void;
};

function Card({
  imageSize = 'SMALL',
  imageUrl,
  showImage,
  title,
  badges,
  showBadge,
  emoji,
  isLoading,
  hasError,
  hide,
  label,
  onClick,
  isDragging,
  dragHandler,
  videoIcon,
  musicIcon,
  placeholder,
  thumbnailType,
  onImageError,
}: CardProps) {
  return (
    <CardContainer
      type={imageSize}
      hasError={hasError}
      onClick={onClick}
      isDragging={isDragging}
    >
      {showImage && (
        <CardImageWrapper size={imageSize}>
          <CardThumbnail
            imageSize={imageSize}
            imageUrl={imageUrl || ''}
            emoji={emoji}
            isLoading={isLoading}
            hide={hide}
            label={label}
            videoIcon={videoIcon}
            musicIcon={musicIcon}
            thumbnailType={thumbnailType}
            onError={onImageError}
          />
        </CardImageWrapper>
      )}
      <ContentWrapper>
        <CardContent
          title={title}
          badges={badges}
          showBadge={showBadge}
          placeholder={placeholder}
        />
      </ContentWrapper>
      {(dragHandler || (imageSize === 'SMALL' && showImage)) && (
        <DragHandlerBlock onClick={(e) => e.stopPropagation()}>
          {dragHandler}
        </DragHandlerBlock>
      )}
    </CardContainer>
  );
}

const DragHandlerBlock = styled('div', {
  flex: '0 0 56px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  'svg path': {
    stroke: '$cardFontColor',
  },
});

const ContentWrapper = styled('div', {
  flex: 1,
  display: 'flex',
  wordBreak: 'break-all',
});

const CardImageWrapper = styled('div', {
  padding: 3, // 4px 들어와야 한다. 하지만 보더가 1px 있으므로 3px로 잡는다.
  flex: '0 0 79px',

  variants: {
    size: {
      SMALL: {
        flex: '0 0 79px',
      },
      MEDIUM: {
        flex: '0 0 119px',
      },
      LARGE: {
        flex: 1,
      },
    },
  },
});

export default Card;
