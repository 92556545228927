import dayjs from 'dayjs';

import useUserQuery from '@global/queries/useUserQuery';
import { useVisitorsAnalyticsDataQuery } from '@pages/mykemi/analytics/source/hooks/useVisitorsAnalyticsQuery';

export const useKemiVisitorsSummary = () => {
  const { data: user } = useUserQuery();

  const today = dayjs();
  const now = today.endOf('minute');
  const yesterday = now.subtract(1, 'day');
  const current = now.toISOString();
  const startOfDay = now.startOf('day').toISOString();
  const yesterdayOfSameTime = yesterday.toISOString();
  const startOfYesterday = yesterday.startOf('day').toISOString();
  const kemiId = user?.kemiId || '';

  const { data: todayVisitors, isLoading: isTodayVisitorsLoading } =
    useVisitorsAnalyticsDataQuery(startOfDay, current, kemiId, 'todayVisitors');
  const { data: yesterdayVisitors, isLoading: isYesterdayVisitorsLoading } =
    useVisitorsAnalyticsDataQuery(
      startOfYesterday,
      yesterdayOfSameTime,
      kemiId,
      'yesterdayVisitors'
    );

  return {
    todayVisitors: todayVisitors?.visitors || 0,
    yesterdayVisitors: yesterdayVisitors?.visitors || 0,
    isTodayVisitorsLoading,
    isYesterdayVisitorsLoading,
  };
};
