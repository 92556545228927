export const Storage = ({ ...rest }) => {
  let color;

  switch (rest.type) {
    case 'linedWhite':
      color = 'white';
      break;
    case 'linedGrey':
      color = '#D8D8D8';
      break;
    default:
      color = '#303030';
  }

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={
          'M7 5.67703V10.323C7 10.7319 7.24895 11.0996 7.62861 11.2514L11.6286 12.8514C11.867 12.9468 12.133 12.9468 12.3714 12.8514L16.3714 11.2514C16.751 11.0996 17 10.7319 17 10.323V5.67703C17 5.26813 16.751 4.90042 16.3714 4.74856L12.3714 3.14856C12.133 3.05319 11.867 3.05319 11.6286 3.14856L7.62861 4.74856C7.24895 4.90042 7 5.26813 7 5.67703Z'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M16 5.4L12.3714 6.85145C12.133 6.94681 11.867 6.94681 11.6286 6.85145L8 5.4'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M12 7V12.5'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M2 13.677V18.323C2 18.7319 2.24895 19.0996 2.62861 19.2514L6.62861 20.8514C6.86702 20.9468 7.13298 20.9468 7.37139 20.8514L11.3714 19.2514C11.751 19.0996 12 18.7319 12 18.323V13.677C12 13.2681 11.751 12.9004 11.3714 12.7486L7.37139 11.1486C7.13298 11.0532 6.86702 11.0532 6.62861 11.1486L2.62861 12.7486C2.24895 12.9004 2 13.2681 2 13.677Z'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M11 13.4L7.37139 14.8514C7.13298 14.9468 6.86702 14.9468 6.62861 14.8514L3 13.4'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M7 15V20.5'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M12 13.677V18.323C12 18.7319 12.249 19.0996 12.6286 19.2514L16.6286 20.8514C16.867 20.9468 17.133 20.9468 17.3714 20.8514L21.3714 19.2514C21.751 19.0996 22 18.7319 22 18.323V13.677C22 13.2681 21.751 12.9004 21.3714 12.7486L17.3714 11.1486C17.133 11.0532 16.867 11.0532 16.6286 11.1486L12.6286 12.7486C12.249 12.9004 12 13.2681 12 13.677Z'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M21 13.4L17.3714 14.8514C17.133 14.9468 16.867 14.9468 16.6286 14.8514L13 13.4'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M17 15V20.5'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};
