import { useContext, useMemo } from 'react';
import { UAParser } from 'ua-parser-js';

import ServerSideContext from '@shared/contexts/ServerSideContext';

const useUserAgent = () => {
  const { userAgent: ssrUserAgent } = useContext(ServerSideContext);

  const userAgent = useMemo(() => {
    if (typeof window === 'undefined') {
      return ssrUserAgent;
    }

    return navigator.userAgent;
  }, [ssrUserAgent]);

  const uaParser = useMemo(() => {
    return new UAParser(userAgent);
  }, [userAgent]);

  const isDesktop = useMemo(() => {
    const deviceType = uaParser.getDevice().type;

    return deviceType !== 'mobile' && deviceType !== 'tablet';
  }, [uaParser]);

  // ios 14 미만 버전은 지원하지 않음
  const unsupportedIosVersionLessThan14 = useMemo(() => {
    const os = uaParser.getOS();
    const majorVersion = Number(os.version?.split('.')[0]);

    return os.name === 'iOS' && majorVersion < 14;
  }, [uaParser]);

  return { isDesktop, unsupportedIosVersionLessThan14 };
};

export default useUserAgent;
