import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { NonDisplayedReviewRequestsQueryResponse } from '@shared/graphql/RequestReviewModal.generated';

type RequestReviewItem = NonNullable<
  NonNullable<
    NonDisplayedReviewRequestsQueryResponse['nonDisplayedReviewRequests']
  >['nonDisplayedReviewRequests']
>[0];

type RequestReviewModalStoreState = {
  opened: boolean;
  requestReviewItems: RequestReviewItem[];
};

const INITIAL_STATE: RequestReviewModalStoreState = {
  opened: false,
  requestReviewItems: [],
};

type RequestReviewModalStoreAction = {
  open: (requestReviewItems: RequestReviewItem[]) => void;
  close: () => void;
};

const useRequestReviewModalStore = create<
  RequestReviewModalStoreState & {
    actions: RequestReviewModalStoreAction;
  }
>()(
  devtools(
    (set) => ({
      ...INITIAL_STATE,
      actions: {
        open: (requestReviewItems: RequestReviewItem[]) =>
          set({ opened: true, requestReviewItems }),
        close: () => set({ opened: false }),
      },
    }),
    { name: 'requestReviewModalStore' }
  )
);

export default useRequestReviewModalStore;
