import ColorConverter from 'color';

import { Color, colors } from '@styles/constants';
import type { ColorName } from '@styles/constants';
import { theme } from '@styles/stitches.config';

const COLOR_WHITE = theme.colors.white.value;
const COLOR_BLACK = theme.colors.black.value;

/**
 * @name calculateColorWithLightness
 * @param hex 배경색 hex code
 * @returns black or white
 * @description
 * sRGB Luma 방법을 사용하여 밝기 계산 (참고 : https://css-tricks.com/switch-font-color-for-different-backgrounds-with-css)
 *
 * global/helper/color.ts의 계산이 약간 다른 듯(theme.colors.red100.value일때 검은색글자 생성 - 흰색이여야함) 하여 새로 만들었습니다.
 * 리뷰 후 교체 혹은 헬퍼로 옯겨놓겠습니다 (TODO)
 */
export const calculateColorWithLightness = (hex?: string) => {
  if (!(hex && isValidHexcode(hex))) return COLOR_BLACK;

  const rgb = ColorConverter(hex).rgb().array() as number[];
  const threshold = 0.5;
  const r = rgb[0] * 0.2126;
  const g = rgb[1] * 0.7152;
  const b = rgb[2] * 0.0722;
  const sum = r + g + b;
  const perceivedLightness = sum / 255;
  const lightness = (perceivedLightness - threshold) * -10000000;

  return ColorConverter(COLOR_WHITE).lightness(lightness).hex();
};

export const isValidHexcode = (hex: string) => {
  const reg = /^#([0-9a-f]{3}){1,2}$/i; // 올바른 hex code 인지 검증
  return reg.test(hex);
};

/**
 * @param color 변경할 색깔
 * @param altColor 잘못된 색상 값일때 대신할 색깔
 * @description
 * hexcode이거나 테마컬러(primary200...등)일때 검증 후 hexcode로 내려줌
 */
export const getHexcode = (
  color: Color,
  altColor: string = theme.colors.white.value
) => {
  if (isValidHexcode(color)) {
    return color;
  } else if (color in colors) {
    // theme.colors일때(primary200 등)
    let name = color as ColorName;
    return colors[name];
  }

  return altColor;
};
