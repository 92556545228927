import { LiteralUnion } from 'type-fest';

// theme 관련 color 정의
const themeColors = {
  bgSolidColor: '#faf8ff',
  cardColor: '#ffffff',
  borderColor: 'transparent',
  pointColor: '#303030',
  cardFontColor: '#303030', // 카드에 들어가는 폰트 색상
  themeFontColor: '#303030', // 배경에 들어가는 폰트 색상
};

export const colors = {
  primary200: '#7856ff', // stitches타입 사용시 css변수형태(--colors-primary200)로 내려오고있어 hexcode로 변경
  primary100: '#9382FF',
  primary50: '#cfc7ff',
  primary20: '#e9e5ff',
  primary10: '#f5f6ff',
  secondary200: '#64a996',
  secondary100: '#82f0be',
  secondary50: '#c8ffe6',
  secondary20: '#e6fbf0',
  secondary10: '#f1fff8',
  black: '#303030',
  grey100: '#909090',
  grey50: '#d8d8d8',
  grey20: '#f6f6f6',
  white: '#ffffff',
  lavender200: '#7856ff',
  lavender100: '#9382FF',
  lavender50: '#cfc7ff',
  lavender20: '#e9e5ff',
  lavender10: '#f5f6ff',
  purple200: '#8c3cdc',
  purple100: '#c878ff',
  purple50: '#e6beff',
  purple20: '#f0dcff',
  purple10: '#faf8ff',
  pink200: '#c84696',
  pink100: '#ff6eaa',
  pink50: '#ffbedc',
  pink20: '#ffe7f1',
  pink10: '#fff4fb',
  red200: '#b43c4f',
  red100: '#ff5064',
  red50: '#ffb4be',
  red20: '#ffdce6',
  red10: '#fff2f6',
  orange200: '#c76428',
  orange100: '#ffa064',
  orange50: '#ffdcbe',
  orange20: '#fff1e6',
  orange10: '#fffaf5',
  yellow200: '#c8ab50',
  yellow100: '#ffe68b',
  yellow50: '#fff4c7',
  yellow20: '#fffae6',
  yellow10: '#fffcf2',
  green200: '#64b43b',
  green100: '#a0f078',
  green50: '#dcffc8',
  green20: '#f0fae6',
  green10: '#f9fff2',
  mint200: '#64a996',
  mint100: '#82f0be',
  mint50: '#c8ffe6',
  mint20: '#e6fbf0',
  mint10: '#f1fff8',
  teal200: '#4696b4',
  teal100: '#82d2f0',
  teal50: '#c8f0ff',
  teal20: '#e5fafa',
  teal10: '#f2ffff',
  blue200: '#2864be',
  blue100: '#649fff',
  blue50: '#bedcff',
  blue20: '#dcf1ff',
  blue10: '#f1f9ff',
  gradi00: 'linear-gradient(180deg, $lavender50 0%, $purple50 100%)',
  gradi01: 'linear-gradient(180deg, $purple50 0%, $blue50 100%)',
  gradi02: 'linear-gradient(180deg, $pink50 0%, $purple50 100%)',
  gradi03: 'linear-gradient(180deg, $red50 0%, $pink50 100%)',
  gradi04: 'linear-gradient(180deg, $orange50 0%, $red50 100%)',
  gradi05: 'linear-gradient(180deg, $orange50 0%, $yellow50 100%)',
  gradi06: 'linear-gradient(180deg, $green50 0%, $yellow50 100%)',
  gradi07: 'linear-gradient(180deg, $mint50 0%, $green50 100%)',
  gradi08: 'linear-gradient(180deg, $teal50 0%, $mint50 100%)',
  gradi09: 'linear-gradient(180deg, $blue50 0%, $teal50 100%)',
  transparent: 'transparent',
  facebook: '#3975ea',
  ...themeColors,
} as const;

export type ColorName = keyof typeof colors;
// https://github.com/sindresorhus/type-fest/blob/main/source/literal-union.d.ts
export type Color = LiteralUnion<ColorName, string>;
