import * as React from 'react';
import { SVGProps } from 'react';
const SvgBinWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 10v6M14.5 10l-.25 6M9.5 10l.25 6M16.286 19 17 7H7l.714 12h8.572ZM5 7h14M10 5h4v2h-4z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgBinWhite;
