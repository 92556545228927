import { DailyInstagramStatsForSummaryQueryResponse } from './graphql/snsAnalyticsSummary.generated';
import {
  InstagramProfileDataUIType,
  InstagramSummaryDataAPIType,
  InstagramSummaryDataUIType,
} from './type';

import { InstagramUserDataFacebookAPIType } from '@global/types';

// ToDo: Default Profile Url, 내부 이미지로 교체
export const DEFAULT_PROFILE_IMAGE_URL =
  'https://www.easycomingsoon.com/wp-content/themes/ecs-website-git/images/testimonial/no-image.png';

export type MakeInstagramSummaryDataPayload = {
  instagramSummaryData: InstagramSummaryDataAPIType;
  yesterdayInstagramData?: DailyInstagramStatsForSummaryQueryResponse;
};

export const makeInstagramSummaryData = ({
  instagramSummaryData,
  yesterdayInstagramData,
}: MakeInstagramSummaryDataPayload): InstagramSummaryDataUIType => {
  const timestamp = new Date().toISOString();
  const totalLike = instagramSummaryData.instagramMediaData.reduce(
    (acc, cur) => acc + cur.like_count,
    0
  );
  const totalSave = instagramSummaryData.instagramMediaInsightData?.reduce(
    (acc, cur) => {
      if (cur.data && cur.data[0].name === 'saved') {
        return acc + cur.data[0].values[0].value;
      }

      return acc;
    },
    0
  );

  return {
    timestamp,
    todayFollowersCount: instagramSummaryData.instagramUserData.followers_count,
    todayPostCount: instagramSummaryData.instagramUserData.media_count,
    todayLikeCount: totalLike,
    todaySaveCount: totalSave,
    yesterdayFollowersCount:
      yesterdayInstagramData?.dailyInstagramStats?.dailyInstagramStats?.[0]
        ?.followersCount || 0,
    yesterdayLikeCount:
      yesterdayInstagramData?.dailyInstagramStats?.dailyInstagramStats?.[0]
        ?.totalLikeCount || 0,
    yesterdaySaveCount:
      yesterdayInstagramData?.dailyInstagramStats?.dailyInstagramStats?.[0]
        ?.totalSavedCount || 0,
  };
};

export const makeInstagramProfileData = (
  userData: InstagramUserDataFacebookAPIType
): InstagramProfileDataUIType => {
  return {
    username: userData.username,
    profileImageUrl: userData.profile_picture_url || DEFAULT_PROFILE_IMAGE_URL,
    mediaCount: userData.media_count,
    followersCount: userData.followers_count,
    followsCount: userData.follows_count,
  };
};
