import { useMemo } from 'react';

import {
  getCardTheme,
  getCoverImageTheme,
  getMultiColumnThumbnailPadding,
  getProfileAlignTheme,
} from '@global/helper/theme';
import { useKemiForThemeQuery } from '@global/store/themeStore/theme.generated';
import { colors } from '@styles/constants';
import { createTheme } from '@styles/stitches.config';
import { calculateColorWithLightness } from 'src/design0.2/helpers/color';

const defaultTheme = {
  bgColorType: 'SIMPLE',
  coverSize: 'RECTANGLE',
  bgSolidColor: colors.primary10,
  profileAlign: 'LEFT',
  cardShape: 'ROUND_SMALL',
  cardColor: colors.white,
  borderColor: colors.transparent,
  pointColor: colors.black,
} as const;

export const useTheme = (linkName: string) => {
  const { data: kemiTheme, isLoading } = useKemiForThemeQuery(
    { linkName },
    {
      enabled: !!linkName,
      select(data) {
        return data?.kemi?.kemiTheme || defaultTheme;
      },
    }
  );

  const theme = useMemo(() => {
    if (!kemiTheme) {
      return '';
    }

    const theme = {
      colors: {
        bgSolidColor: kemiTheme.bgSolidColor || defaultTheme.bgSolidColor,
        cardColor: kemiTheme.cardColor || defaultTheme.cardColor,
        borderColor:
          kemiTheme.borderColor ||
          kemiTheme.cardColor ||
          defaultTheme.borderColor,
        pointColor: kemiTheme.pointColor || defaultTheme.pointColor,
        cardFontColor: calculateColorWithLightness(
          kemiTheme.cardColor || defaultTheme.cardColor
        ),
        themeFontColor: calculateColorWithLightness(
          kemiTheme.bgSolidColor || defaultTheme.bgSolidColor
        ),
      },
      radii: getCardTheme(kemiTheme.cardShape),
      sizes: getProfileAlignTheme(
        kemiTheme.profileAlign || defaultTheme.profileAlign
      ),
      space: {
        ...getCoverImageTheme(kemiTheme.coverSize || defaultTheme.coverSize),
        ...getMultiColumnThumbnailPadding(kemiTheme.cardShape),
      },
    };

    const customTheme = createTheme(theme);

    return customTheme;
  }, [kemiTheme]);

  return { theme, kemiTheme, isLoading };
};
