import {
  KEMI_MODAL_ROOT_TAG_ID,
  KEMI_MOBILE_SIDEMENU_ROOT_TAG_ID,
} from '@global/constants';

const PortalContainer = () => {
  return (
    <>
      <div id={KEMI_MOBILE_SIDEMENU_ROOT_TAG_ID} />
      <div id={KEMI_MODAL_ROOT_TAG_ID} />
    </>
  );
};

export default PortalContainer;
