import Button from '@global/components/atoms/Button';
import Image from '@global/components/atoms/Image';
import { KemiNextPage } from '@shared/types/next';
import background from '@static/media/500page-background.png';
import kemiAstro from '@static/media/kemi-astro.svg';
import { css, styled } from '@styles/stitches.config';

const NotFoundErrorPage: KemiNextPage = () => {
  return (
    <Block>
      <Image src={kemiAstro} layout={'fixed'} alt={'kemi-astro'} />
      <span className={textCss({ fontType: 'bold' })}>
        {'페이지를 찾을 수 없습니다 💫'}
      </span>
      <span className={textCss({ fontType: 'regular' })}>
        {'페이지가 삭제되었거나 변경되었습니다. 주소를 다시 확인해주세요.'}
      </span>
      <Button onClick={() => (location.href = '/')}>{'홈으로'}</Button>
    </Block>
  );
};

const Block = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: 16,
  textAlign: 'center',
  backgroundSize: 'cover',
  backgroundImage: `url(${background.src})`,
});

const textCss = css({
  color: '$white',
  marginBottom: 16,
  variants: {
    fontType: {
      bold: {
        fontType: 'bodyBold',
      },
      regular: {
        fontType: 'bodyRegular',
      },
    },
  },
});

NotFoundErrorPage.layoutType = 'KEMI';

export default NotFoundErrorPage;
