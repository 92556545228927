import * as React from 'react';
import { SVGProps } from 'react';
const SvgCheckboxUncheckedDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={4.5}
      y={4}
      width={16}
      height={16}
      rx={1}
      fill="#D8D8D8"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCheckboxUncheckedDisabled;
