import Link from 'next/link';
import { useRouter } from 'next/router';

import { Menu, MenuProps } from '@pages/app/components/Menu';
import { RightContainer } from '@pages/app/components/shared';
import GoToLink from '@static/svg/GoToLink';

export const RedirectMenu = ({
  targetUrls,
  postfix,
  ...props
}: MenuProps & { targetUrls: string[] }) => {
  const router = useRouter();
  const active = targetUrls?.some((url) => router.pathname.startsWith(url));
  return (
    <Link href={targetUrls[0]} passHref>
      <Menu
        {...props}
        target={'_blank'}
        active={active}
        postfix={
          <RightContainer>
            {postfix}
            <GoToLink />
          </RightContainer>
        }
      />
    </Link>
  );
};
