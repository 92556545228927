import axios from 'axios';

import {
  useLoginWithKakaoMutation,
  useRegisterWithKakaoMutation,
} from '../graphql/auth.generated';
import { createDefaultKemiOption, getOAuthCallbackUri } from '../helper';
import { KakaoAuthResponse, OAuthModule } from '../types';

import { nextApi } from '@global/network';

const CLIENT_ID = process.env.NEXT_PUBLIC_KAKAO_APP_KEY as string;
const CLIENT_SECRET = process.env.KAKAO_APP_SECRET as string;
const REDIRECT_URI = getOAuthCallbackUri('kakao');

class KakaoOAuth implements OAuthModule {
  async authorize(state: string) {
    /**
     * TODO 테스트 필요 - kakao sdk 코드안에 같은 로직이 있는 것 같은데 수동으로 제어를 해야하는가?
     * https://developers.kakao.com/sdk/js/kakao.js 코드 1934번쨰 줄
     */
    // const { name: browser = '' } = getBrowser();
    // const unavailableBrowser = ['Instagram', 'Facebook'];
    // if (unavailableBrowser.includes(browser)) {
    //   window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${APP_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code&state=${state}`;
    //   return;
    // }

    /**
     * [PC 크롬 > 개발자 도구 > 모바일 화면] 개발 과정에서 주의
     *
     * 안드로이드 프리셋 또는 반응형으로 화면 크기 설정 시,
     * 유저에이전트를 안드로이드로 인식해 로그인 과정에서 딥링크로 카톡을 실행해 에러가 발생함
     * 해당 에러는 try catch로 검출이 되지 않음
     */
    Kakao.Auth.authorize({
      redirectUri: REDIRECT_URI,
      state,
    });
  }

  async requestIssuingSnsAccessToken(code: string): Promise<string> {
    return await nextApi.issueSnsAccessToken('kakao', code);
  }

  async issueSnsAccessTokenOnServer(code: string): Promise<string> {
    const { data } = await axios.post<KakaoAuthResponse>(
      'https://kauth.kakao.com/oauth/token',
      {
        grant_type: 'authorization_code',
        client_id: CLIENT_ID,
        //  * TODO 현재 카카오에 client secret이 없음
        //  * devel, stage, main에 로그인 수정 코드가 반영될때마다 카카오 앱 콘솔에서 secret을 추가하고 코드에도 반영해야함
        client_secret: CLIENT_SECRET,
        redirect_uri: REDIRECT_URI,
        code,
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      }
    );

    return data.access_token;
  }

  async issueKemiToken(snsAccessToken: string) {
    const res = await useLoginWithKakaoMutation.fetcher({
      snsAccessToken,
    })();

    const { token, refreshToken } = res.loginWithKakaoToken;

    return {
      accessToken: token,
      refreshToken,
    };
  }

  async registerKemi(snsAccessToken: string, linkName?: string) {
    await useRegisterWithKakaoMutation.fetcher({
      snsAccessToken,
      kemiOption: createDefaultKemiOption(),
      linkName,
    })();
  }
}

const kakaoOAuth = new KakaoOAuth();

export default kakaoOAuth;
