import {
  CardBadge,
  EShippingChargeType,
  LinkCardBadge,
  ProductCardBadge,
} from '@schema/types';

export type Maybe<T> = T | null | undefined;

export type Gap = 0 | 4 | 8 | 12 | 16 | 20 | 24 | 64;

export enum TempCardType {
  Calendar = 'calendar',
  Divider = 'divider',
  Survey = 'survey',
  Vote = 'vote',
  Donation = 'donation',
  DownloadProduct = 'download-product',
  CustomOrderProduct = 'custom-order-product',
}

export type CardType =
  | 'link'
  | 'text'
  | 'product'
  | 'image'
  | 'video'
  | 'music'
  | TempCardType;

export enum ImageType {
  PROFILE = 'PROFILE',
  COVER = 'COVER',
  LINK_CARD = 'LINK_CARD',
}

export enum ImageCropperShape {
  RECT = 'rect',
  ROUND = 'round',
}

export type StaticImageData = {
  src: string;
  height: number;
  width: number;
  blurDataURL?: string;
};

export enum LoginModalType {
  login = 'login',
  signup = 'signup',
}

export enum ImageAlertType {
  OVERSIZE = 'IMAGE_OVERSIZE',
  WRONG_TYPE = 'IMAGE_WRONG_TYPE',
}

export type UserAgentType = {
  browser: {
    name: string | undefined;
    version: string | undefined;
    major: string | undefined;
  };
  device: {
    model: string | undefined;
    type: string | undefined;
    vendor: string | undefined;
  };
  os: {
    name: string | undefined;
    version: string | undefined;
  };
};

export enum UserInteractionType {
  PAGE_IN = 'PAGE_IN',
  PAGE_OUT = 'PAGE_OUT',
  CLICK = 'CLICK',
  CHANGE = 'CHANGE',
  SCROLL = 'SCROLL',
  FOCUS_IN = 'FOCUS_IN',
  FOCUS_OUT = 'FOCUS_OUT',
  BLUR = 'BLUR',
  RESIZE = 'RESIZE',
  TOGGLE = 'TOGGLE',
  TOAST = 'TOAST',
  NONE = 'NONE',
}

export type OrderDetail = {
  stocks: OrderDetailStock[];
  cartItemId?: string;
};

export type CartDetail = {
  stocks: CartDetailStock[];
};

export type OrderDetailStockType =
  | 'PRODUCT'
  | 'OPTION'
  | 'EXTRA_OPTION'
  | 'ADDITIONAL_PRODUCT';

export type OrderDetailStock = {
  sku: string;
  quantity: number;
  type: OrderDetailStockType;
};

export type CartDetailStock = OrderDetailStock & {
  parentSku?: string;
};

export type ProductDetailStock = CartDetailStock;

export type PriceSummaryProductCard = {
  price: number;
};

export type ProductShippingType = {
  shippingChargeType: EShippingChargeType;
  minPriceForFreeShipping: number;
  shippingCharge: number;
  extraChargeForIslandsAndMountains: number;
  extraChargeForJeju: number;
  isOverseasShipping: boolean;
  isChargedPerQuantity: boolean;
  chargedQuantity: number;
};

export type FacebookAuthDataType = {
  instagramUserId: string | null;
  accessToken: string | null;
};

export type InstagramMediaDataFacebookAPIType = {
  comments_count: number;
  id: string;
  like_count: number;
  media_product_type: 'FEED' | 'STORY' | 'AD';
  media_type: 'VIDEO' | 'IMAGE' | 'CAROUSEL_ALBUM';
  timestamp: string; // "2022-01-12T10:03:17+0000" (GMT+00:00 시간대)
};

export type InstagramMediaInsigntDataFacebookAPIType = {
  data: {
    description: string;
    id: string;
    name: string;
    period: string;
    title: string;
    values: {
      value: number;
    }[];
  }[];
};

export type InstagramUserDataFacebookAPIType = {
  followers_count: number;
  follows_count: number;
  id: string;
  ig_id: number;
  media_count: number;
  username: string;
  profile_picture_url: string;
};

export type InstagramUserInsightDataFacebookAPIType = {
  data: {
    description: string;
    id: string;
    name: string; // online_followers
    period: 'lifetime' | 'day' | 'week' | 'days_28';
    title: string;
    values: {
      value: OnlineFollowerType;
      end_time: string;
    }[];
  }[];
};

type OnlineFollowerType = {
  '0'?: number;
  '1'?: number;
  '2'?: number;
  '3'?: number;
  '4'?: number;
  '5'?: number;
  '6'?: number;
  '7'?: number;
  '8'?: number;
  '9'?: number;
  '10'?: number;
  '11'?: number;
  '12'?: number;
  '13'?: number;
  '14'?: number;
  '15'?: number;
  '16'?: number;
  '17'?: number;
  '18'?: number;
  '19'?: number;
  '20'?: number;
  '21'?: number;
  '22'?: number;
  '23'?: number;
};

export type UrlType = 'VIDEO' | 'MUSIC' | 'LINK';

export type EBadgeType = CardBadge | LinkCardBadge | ProductCardBadge;
