import { useQuery } from '@tanstack/react-query';

import { InstagramSummaryDataAPIType } from '../type';

import { FACEBOOK_ERROR_CODE } from './../../../../../../../global/constants';

import { facebookService } from '@global/service';
import KemiRuntimeError from '@global/service/Error/KemiRuntimeError';
import { InstagramUserDataFacebookAPIType } from '@global/types';

const ONE_HOUR = 60 * 60 * 1000;

type UseInstagramQueryArgsType = {
  enabled: boolean;
  instagramUserId?: string | null;
  accessToken?: string | null;
};

export const useInstagramSummaryDataQuery = ({
  enabled,
  instagramUserId,
  accessToken,
}: UseInstagramQueryArgsType) =>
  useQuery(
    ['getInstagramSummaryData', instagramUserId],
    async (): Promise<InstagramSummaryDataAPIType | undefined> => {
      try {
        if (!instagramUserId || !accessToken) return;

        const [
          instagramUserData,
          instagramMediaData,
          instagramMediaInsightData,
        ] = await Promise.all([
          facebookService.getInstagramUserData(instagramUserId, accessToken),
          facebookService.getInstagramMediaData(instagramUserId, accessToken),
          facebookService.getInstagramMediaInsightData(
            instagramUserId,
            accessToken
          ),
        ]);

        return {
          instagramUserData,
          instagramMediaData,
          instagramMediaInsightData,
        };
      } catch (err: any) {
        throw new KemiRuntimeError(err);
      }
    },
    {
      enabled,
      cacheTime: ONE_HOUR,
      staleTime: ONE_HOUR,
      retry: (_, error) =>
        (error as Error).message !== FACEBOOK_ERROR_CODE.TOKEN_EXPIRED,
    }
  );

export const useInstagramProfileDataQuery = ({
  enabled,
  instagramUserId,
  accessToken,
}: UseInstagramQueryArgsType) =>
  useQuery(
    ['getInstagramProfileData', instagramUserId],
    async (): Promise<InstagramUserDataFacebookAPIType | undefined> => {
      try {
        if (!instagramUserId || !accessToken) return;

        return await facebookService.getInstagramUserData(
          instagramUserId,
          accessToken
        );
      } catch (err: any) {
        throw new KemiRuntimeError(err);
      }
    },
    {
      enabled,
      cacheTime: ONE_HOUR,
      staleTime: ONE_HOUR,
      retry: (_, error) =>
        (error as Error).message !== FACEBOOK_ERROR_CODE.TOKEN_EXPIRED,
    }
  );
