import create from 'zustand';
import { devtools } from 'zustand/middleware';

export type ToastMessage = string | JSX.Element;

export type ToastItem = {
  type: 'good' | 'bad' | 'info';
  message: ToastMessage;
};

export type ToastStoreType = {
  toast?: ToastItem;
  open: boolean;
  setOpen: (open: boolean) => void;
  good: (message: ToastMessage) => void;
  bad: (message: ToastMessage) => void;
  info: (message: ToastMessage) => void;
};

const createInstance = (name: string) =>
  create<ToastStoreType>()(
    devtools(
      (set) => ({
        open: false,
        setOpen: (open) => {
          set(() => ({
            open,
          }));
        },
        good: (message) => {
          set(() => ({
            toast: { type: 'good', message },
            open: true,
          }));
        },
        bad: (message) => {
          set(() => ({
            toast: { type: 'bad', message },
            open: true,
          }));
        },
        info: (message) => {
          set(() => ({
            toast: { type: 'info', message },
            open: true,
          }));
        },
      }),
      { name }
    )
  );

export const pageToastStore = createInstance('PageToastStore');
export const modalToastStore = createInstance('ModalToastStore');
export const fullModalToastStore = createInstance('FullModalToastStore');
