import * as React from 'react';
import { SVGProps } from 'react';
const SvgFavoriteLargeBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.272 3.963c.772-1.323 2.684-1.323 3.456 0l4.627 7.935a2 2 0 0 0 1.305.947l8.98 1.943c1.5.324 2.091 2.145 1.069 3.288l-6.12 6.843a2 2 0 0 0-.5 1.535l.925 9.13c.155 1.526-1.392 2.65-2.795 2.033l-8.413-3.703a2 2 0 0 0-1.612 0l-8.413 3.703c-1.403.617-2.95-.507-2.796-2.032l.926-9.131a2 2 0 0 0-.5-1.535l-6.12-6.843c-1.022-1.143-.43-2.964 1.068-3.288l8.982-1.943a2 2 0 0 0 1.304-.947l4.627-7.935Z"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgFavoriteLargeBlack;
