/**
 * 변화율 공식
 * (변화 전 수치 - 변화 후 수치) / 변화 전 수치 * 100 * -1
 */
export const getRateOfChange = (before: number, after: number) => {
  // 분모가 0일 때 분자가 0이상일 경우 분모를 1로 간주하고 리턴, 분자도 0일 경우 0%로 리턴
  if (!before) {
    if (after) {
      return (after / 1) * 100;
    }

    return 0;
  }

  return ((before - after) / before) * 100 * -1;
};

export const getPercentages = (counts: number[]) => {
  const total = counts.reduce((acc, cur) => acc + cur);
  const rates = counts.map((count) => (count / total) * 100);

  return rates;
};

export const convertTo100Unit = (before: number, after: number) => {
  let converted = after;

  // 입력하는 동작
  if (before < after) {
    // 입력 위치가 마지막 00 안쪽일 때만 별도로 처리
    if (after % 100) {
      const currentInput = Math.floor(converted % 100)
        .toString()
        .replace('0', '');
      const oldInput = Math.floor(before / 100).toString();
      converted = Number(oldInput + currentInput) * 100;
    }
  }

  // 지우는 동작
  if (before > after) {
    const removeBefore = Math.floor(before / 100)
      .toString()
      .split('');
    const removeAfter = Math.floor(after / 100)
      .toString()
      .split('');
    let removeIndex = 0;
    const removeNumber = removeBefore.length - removeAfter.length;

    for (let i = 0; i < removeBefore.length; i++) {
      if (removeBefore[i] !== removeAfter[i]) {
        removeIndex = i;
        break;
      }
    }

    // 지우는 위치가 00보다 뒤일 때
    if (removeIndex >= removeAfter.length) {
      converted = Number(before.toString().slice(0, -3)) * 100;
      // 마지막 숫자 1개만 남았을 때
    } else if (removeIndex === 0 && removeNumber === 0) {
      converted = 0;
      // 지우는 위치가 00보다 앞일 때 (블록 지정으로 한번에 지우는 경우도 고려)
    } else {
      converted =
        Number(
          removeBefore.join('').slice(0, removeIndex) +
            removeBefore.join('').slice(removeIndex + removeNumber)
        ) * 100;
    }
  }

  // 한번에 블록지정해서 삭제할 때
  if (after === 0) {
    converted = 0;
  }

  return converted;
};

// unit은 10의 단위로 입력
export const convertToMinumumUnit = (
  before: number,
  after: number,
  unit: number
) => {
  let converted = after;

  if (after === 0) {
    return 0;
  }

  // 입력하는 동작
  if (before < after) {
    // 입력 위치가 마지막 최소단위 안쪽일 때만 별도로 처리
    if (after % unit) {
      const currentInput = Math.floor(converted % unit)
        .toString()
        .replace('0', '');
      const oldInput = Math.floor(before / unit).toString();
      converted = Number(oldInput + currentInput) * unit;
    }
  }

  // 지우는 동작
  if (before > after) {
    const removeBefore = Math.floor(before / unit)
      .toString()
      .split('');
    const removeAfter = Math.floor(after / unit)
      .toString()
      .split('');
    let removeIndex = 0;
    const removeNumber = removeBefore.length - removeAfter.length;

    for (let i = 0; i < removeBefore.length; i++) {
      if (removeBefore[i] !== removeAfter[i]) {
        removeIndex = i;
        break;
      }
    }

    // 지우는 위치가 최소단위보다 뒤일 때
    if (removeIndex >= removeAfter.length) {
      converted =
        Number(before.toString().slice(0, -1 * unit.toString().length)) * unit;
      // 마지막 숫자 1개만 남았을 때
    } else if (removeIndex === 0 && removeNumber === 0) {
      converted = 0;
      // 지우는 위치가 최소단위보다 앞일 때 (블록 지정으로 한번에 지우는 경우도 고려)
    } else {
      converted =
        Number(
          removeBefore.join('').slice(0, removeIndex) +
            removeBefore.join('').slice(removeIndex + removeNumber)
        ) * unit;
    }
  }

  return converted;
};

export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
