import Button from '@design0.2/Button/Button';
import CheckBoxInput from '@design0.2/Inputs/CheckBoxInput';
import useSignInAndUpModal from '@shared/hooks/useSignInAndUpModal';
import { styled } from '@styles/stitches.config';

const TermsSelection = () => {
  const signInAndUpModal = useSignInAndUpModal();

  const confirm = () => {
    signInAndUpModal.changeMode('signUp');
  };

  return (
    <div>
      <TitleSection>
        <TitleText>{'약관 확인'}</TitleText>
        <SubtitleText>
          {'아래 약관에 동의하시고 가입을 완료해주세요'}
        </SubtitleText>
      </TitleSection>
      <AllSelectionSection>
        <CheckBoxInput
          label={'모두 동의합니다. 🤝'}
          checked={signInAndUpModal.allTermsSelected}
          onChange={(e) =>
            e.target.checked
              ? signInAndUpModal.selectAllTerms()
              : signInAndUpModal.deselectAllTerms()
          }
        />
      </AllSelectionSection>
      <Divider />
      <SelectionSection>
        <Selection>
          <CheckBoxInput
            label={'서비스이용약관 (필수)'}
            checked={signInAndUpModal.selectedTerms.service}
            onChange={(e) =>
              signInAndUpModal.updateTerms({ service: e.target.checked })
            }
          />
          <DetailAnchor
            target={'_blank'}
            href={'https://kemi.oopy.io/25277795-4af9-46dc-a3e2-422ab63d089f'}
            rel={'noreferrer'}
          >
            {'확인하기'}
          </DetailAnchor>
        </Selection>
        <Selection>
          <CheckBoxInput
            label={'개인정보처리방침 (필수)'}
            checked={signInAndUpModal.selectedTerms.privacy}
            onChange={(e) =>
              signInAndUpModal.updateTerms({ privacy: e.target.checked })
            }
          />
          <DetailAnchor
            target={'_blank'}
            href={'https://kemi.oopy.io/0b0735eb-cf1d-4cb6-8cef-b09f2971a3c9'}
            rel={'noreferrer'}
          >
            {'확인하기'}
          </DetailAnchor>
        </Selection>
        <Selection>
          <CheckBoxInput
            label={'마케팅정보수신동의 (선택)'}
            checked={signInAndUpModal.selectedTerms.marketing}
            onChange={(e) =>
              signInAndUpModal.updateTerms({ marketing: e.target.checked })
            }
          />
          <DetailAnchor
            target={'_blank'}
            href={'https://kemi.oopy.io/dd5f8a9f-7007-4e28-be17-fa5951d207f0'}
            rel={'noreferrer'}
          >
            {'확인하기'}
          </DetailAnchor>
        </Selection>
      </SelectionSection>
      <ConfirmSection>
        <Button onClick={confirm}>{'확인'}</Button>
      </ConfirmSection>
    </div>
  );
};

const TitleSection = styled('div', {
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const TitleText = styled('span', { fontType: 'heading3', color: '$black' });

const SubtitleText = styled('span', {
  fontType: 'captionRegular',
  color: '$black',
});

const AllSelectionSection = styled('div', { padding: 16 });

const Divider = styled('div', {
  margin: '0 16px',
  height: 1,
  backgroundColor: '$grey20',
});

const SelectionSection = styled('div', {
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const Selection = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const DetailAnchor = styled('a', {
  fontType: 'captionRegular',
  color: '$grey100',
});

const ConfirmSection = styled('div', { padding: 16 });

export default TermsSelection;
