import { styled } from '@styles/stitches.config';
import Badge, { BadgeProps } from 'src/design0.2/Badge/Badge';

function Badges({ badges }: { badges: BadgeProps[]; reverse?: boolean }) {
  return (
    <Container>
      {badges?.map((badge, i) => (
        <Badge key={i} {...badge}>
          {badge?.title}
        </Badge>
      ))}
    </Container>
  );
}

const Container = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  gap: 4,
  width: '100%',

  '& > div': {
    flexShrink: 0,
  },
  '& > div:last-child': {
    flexShrink: 1,
  },
});
export default Badges;
