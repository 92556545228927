/**
 * 특정 범주로 분류하지 않은 유틸들. 언제든지 특정 범주로 분류 될 수 있다.
 */

/**
 * 인자로 넘긴 시간이 경과된 후 resolve되는 프로미스를 반환한다.
 * @param ms 대기시간
 * @returns 대기시간 이후 resolve될 프로미스 객체
 */
export const wait = (ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

// 주문에서만 사용되다가, 상품상세 에서도 사용하게 되어 일단 해당 위치로 이동
export const toNumbersOnly = (value: string = '') => {
  return value.replace(/[^\d]/g, '');
};
