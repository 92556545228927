import * as React from 'react';
import { SVGProps } from 'react';
const SvgNaverblogBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-3.465a1 1 0 0 0-.832.445l-.871 1.307a1 1 0 0 1-1.664 0l-.871-1.307A1 1 0 0 0 9.465 17H6a2 2 0 0 1-2-2V7Z"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.8 8.5v4M10.3 8.5v4" stroke="#303030" strokeLinecap="round" />
    <circle cx={7.8} cy={11.5} r={1} stroke="#303030" strokeLinecap="round" />
    <circle cx={12.8} cy={11.5} r={1} stroke="#303030" strokeLinecap="round" />
    <circle cx={16.3} cy={11.5} r={1} stroke="#303030" strokeLinecap="round" />
    <path
      d="M15.3 13.5a1 1 0 0 0 2 0v-3"
      stroke="#303030"
      strokeLinecap="round"
    />
  </svg>
);
export default SvgNaverblogBlack;
