import Image from 'next/image';

import Button from '@design0.2/Button/Button';
import networkKemi from '@static/media/kemi-network.svg';
import { styled } from '@styles/stitches.config';

function NetworkError() {
  return (
    <Container>
      <ImageContainer>
        <Image src={networkKemi} alt={''} />
      </ImageContainer>
      <Title>{'인터넷 연결이 원활하지 않습니다.'}</Title>
      <SubTitle>{'다음 방법을 시도해보세요.'}</SubTitle>
      <Description>
        <List>{'사파리/크롬/인터넷 브라우저 이용'}</List>
        <List>{'모바일 데이터나 와이파이 연결 상태 확인'}</List>
        <List>{'모바일일 경우, PC이용'}</List>
      </Description>
      <Button
        buttonStyle={'primary'}
        onClick={() => navigator.clipboard.writeText(location.href)}
      >
        {'링크 복사'}
      </Button>
      <LinkDescription
        target={'_blank'}
        rel={'noopener noreferer noreferrer'}
        href={'https://kemi.channel.io/lounge'}
      >
        {'계속 연결이 안되시나요? 👉'}
      </LinkDescription>
    </Container>
  );
}

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: '16px 64px',
  textAlign: 'center',
  backgroundColor: '$primary10',
});

const ImageContainer = styled('div', {
  marginBottom: 24,
});

const Title = styled('span', {
  fontType: 'bodyBold',
  marginBottom: 24,
});

const SubTitle = styled('span', {
  fontType: 'bodyBold',
  marginBottom: 8,
});

const Description = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 24,
});

const List = styled('li', {
  fontType: 'bodyRegular',
  position: 'relative',
  paddingLeft: 12,

  '&:before': {
    content: '',
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    width: 3,
    height: 3,
    borderRadius: '50%',
    backgroundColor: '$black',
  },
});

const LinkDescription = styled('a', {
  fontType: 'captionRegular',
  color: '$grey100',
  marginTop: 24,
  cursor: 'pointer',
});

export default NetworkError;
