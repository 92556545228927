import { ILogger } from '@global/service/logger/interfaces';

export default class ConsoleLogger implements ILogger {
  debug(...args: any[]): void {
    // eslint-disable-next-line no-console
    console.log(...args);
  }

  info(...args: any[]): void {
    // eslint-disable-next-line no-console
    console.info(...args);
  }

  warn(...args: any[]): void {
    // eslint-disable-next-line no-console
    console.warn(...args);
  }

  error(...args: any[]): void {
    // eslint-disable-next-line no-console
    console.error(...args);
  }

  group(fn: () => void): void {
    // eslint-disable-next-line no-console
    console.group();
    fn();
    // eslint-disable-next-line no-console
    console.groupEnd();
  }
}
