import isEmail from 'validator/lib/isEmail';

import { REGEX_CHECK_KOREAN } from '@global/regex';
export const isEmpty = (str?: string) => !str || str === '';

export const trimAllSpace = (str: string) => {
  return str.split(' ').join('');
};

type ExtractOptions = {
  prefix: string;
  suffix: string;
};

export const extract = (target: string, options?: ExtractOptions) => {
  const prefix = options ? options.prefix : '[';
  const suffix = options ? options.suffix : ']';
  const regexp = new RegExp(`\\${prefix}(.*?)\\${suffix}`);
  const result = target.match(regexp);
  return result && result[1];
};

export const addComma = (num: number | string) => {
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(regexp, ',');
};

export const removeComma = (string: string) => {
  const numberArrayWithDot = string.match(/[\d | .]/g);
  return numberArrayWithDot ? Number(numberArrayWithDot.join('')) : 0;
};

export const formatBusinessRegistrationNumber = (number: string) => {
  // const value = number.replace(/[^0-9]/g, '');

  if (number.length > 5) {
    return [number.slice(0, 3), number.slice(3, 5), number.slice(5)].join('-');
  } else if (number.length > 3) {
    return [number.slice(0, 3), number.slice(3)].join('-');
  } else {
    return number;
  }
};

export const formatPhone = (phone: string, masking: boolean) => {
  let formatNum;

  if (phone.length == 11) {
    if (masking) {
      formatNum = phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
    } else {
      formatNum = phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }
  } else if (phone.length == 8) {
    formatNum = phone.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else {
    if (phone.indexOf('02') == 0) {
      if (masking) {
        formatNum = phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
      } else {
        formatNum = phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else {
      if (masking) {
        formatNum = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
      } else {
        formatNum = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
    }
  }

  return formatNum;
};

export const isValidPhoneNumber = (phone: string) => {
  const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  return regPhone.test(phone);
};

export const isValidEmail = (email: string) => {
  // 한글 제외를 위해 isEmail 옵션 중 allow_utf8_local_part를 사용하려 했으나
  // 해당 옵션은 @앞(local_part)만 적용되고
  // '라' <- 이런 완성된 글자만 체크되고, 'ㄹ' <- 이런 케이스를 잡지못하여
  // 아래와 같은 한글체크를 위한 정규표현식을 추가하였습니다.
  const includingKorean = REGEX_CHECK_KOREAN.test(email);

  return !includingKorean && isEmail(email);
};

export const mask = (name: string, startAt: number) => {
  if (name.length === 0) return '';

  return name.slice(0, startAt) + ''.padEnd(name.length - startAt, '*');
};

export const getOnlyNumber = (str: string) => {
  return str.replace(/[^0-9]+/g, '');
};
