const EditDesign = ({ ...rest }) => {
  if (rest.type === 'linedWhite') {
    return (
      <svg
        width={'24'}
        height={'24'}
        viewBox={'0 0 24 24'}
        fill={'none'}
        xmlns={'http://www.w3.org/2000/svg'}
        {...rest}
      >
        <rect
          x={'6'}
          y={'3'}
          width={'12'}
          height={'6'}
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M12 3V6'}
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M9 3V6'}
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M15 3V6'}
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <rect
          x={'6'}
          y={'9'}
          width={'12'}
          height={'2'}
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={
            'M18 11H6V12C6 13.1046 6.89543 14 8 14H8.69398C9.91108 14 10.846 15.078 10.6739 16.2828L10.1867 19.6931C10.0752 20.4735 10.4757 21.2378 11.1808 21.5904C11.6965 21.8483 12.3035 21.8483 12.8192 21.5904C13.5243 21.2378 13.9248 20.4736 13.8133 19.6931L13.3261 16.2828C13.154 15.078 14.0889 14 15.306 14H16C17.1046 14 18 13.1046 18 12V11Z'
          }
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
      </svg>
    );
  }

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <rect
        x={'6'}
        y={'3'}
        width={'12'}
        height={'6'}
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M12 3V6'}
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M9 3V6'}
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M15 3V6'}
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <rect
        x={'6'}
        y={'9'}
        width={'12'}
        height={'2'}
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M18 11H6V12C6 13.1046 6.89543 14 8 14H8.69398C9.91108 14 10.846 15.078 10.6739 16.2828L10.1867 19.6931C10.0752 20.4735 10.4757 21.2378 11.1808 21.5904C11.6965 21.8483 12.3035 21.8483 12.8192 21.5904C13.5243 21.2378 13.9248 20.4736 13.8133 19.6931L13.3261 16.2828C13.154 15.078 14.0889 14 15.306 14H16C17.1046 14 18 13.1046 18 12V11Z'
        }
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default EditDesign;
