import * as Types from 'schema/types';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type KemiForThemeQueryVariables = Types.Exact<{
  linkName: Types.Scalars['String'];
}>;

export type KemiForThemeQueryResponse = {
  kemi?: {
    kemiTheme?: {
      cardShape: Types.ECardShape;
      bgColorType: Types.EColorType;
      bgSolidColor?: string | null;
      bgGradationColor1?: string | null;
      bgGradationColor2?: string | null;
      cardColor: string;
      borderColor: string;
      coverSize?: Types.ECoverSize | null;
      profileAlign?: Types.EProfileAlign | null;
      pointColor?: string | null;
    } | null;
  } | null;
};

export const KemiForThemeDocument = `
    query KemiForTheme($linkName: String!) {
  kemi(linkName: $linkName) {
    kemiTheme {
      cardShape
      bgColorType
      bgSolidColor
      bgGradationColor1
      bgGradationColor2
      cardColor
      borderColor
      coverSize
      profileAlign
      pointColor
    }
  }
}
    `;
export const useKemiForThemeQuery = <
  TData = KemiForThemeQueryResponse,
  TError = unknown
>(
  variables: KemiForThemeQueryVariables,
  options?: UseQueryOptions<KemiForThemeQueryResponse, TError, TData>
) =>
  useQuery<KemiForThemeQueryResponse, TError, TData>(
    ['KemiForTheme', variables],
    fetcher<KemiForThemeQueryResponse, KemiForThemeQueryVariables>(
      KemiForThemeDocument,
      variables
    ),
    options
  );

useKemiForThemeQuery.getKey = (variables: KemiForThemeQueryVariables) => [
  'KemiForTheme',
  variables,
];
export const useInfiniteKemiForThemeQuery = <
  TData = KemiForThemeQueryResponse,
  TError = unknown
>(
  variables: KemiForThemeQueryVariables,
  options?: UseInfiniteQueryOptions<KemiForThemeQueryResponse, TError, TData>
) => {
  return useInfiniteQuery<KemiForThemeQueryResponse, TError, TData>(
    ['KemiForTheme.infinite', variables],
    (metaData) =>
      fetcher<KemiForThemeQueryResponse, KemiForThemeQueryVariables>(
        KemiForThemeDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteKemiForThemeQuery.getKey = (
  variables: KemiForThemeQueryVariables
) => ['KemiForTheme.infinite', variables];
useKemiForThemeQuery.fetcher = (
  variables: KemiForThemeQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<KemiForThemeQueryResponse, KemiForThemeQueryVariables>(
    KemiForThemeDocument,
    variables,
    options
  );
