import * as Types from 'schema/types';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type ProductCardDetailQueryVariables = Types.Exact<{
  productCardId: Types.Scalars['String'];
}>;

export type ProductCardDetailQueryResponse = {
  card?:
    | {
        id: string;
        title: string;
        suggestedRetailPrice?: number | null;
        productId?: string | null;
        emoji?: string | null;
        imageUrl?: string | null;
        showImage: boolean;
        salesStartAlimtalkUserIds?: Array<string> | null;
        createdAt: string;
        user?: {
          id: string;
          userProfile?: { phoneNumber?: string | null } | null;
          kemi: {
            id: string;
            linkName: string;
            profileImageUrl?: string | null;
            title: string;
          };
        } | null;
        productDetail?: {
          id: string;
          providerId?: string | null;
          isSellerProduct?: boolean | null;
          title?: string | null;
          sku?: string | null;
          description?: string | null;
          imageUrls?: Array<string> | null;
          content?: string | null;
          suggestedRetailPrice?: number | null;
          supplyPrice?: number | null;
          kemiSalesPeriodState?: Types.EProductKemiSalesPeriodState | null;
          kemiStockState?: Types.EProductKemiStockState | null;
          productState?: Types.EProductState | null;
          maxSaleDaysPerSeller?: number | null;
          kemiSalesStartAt?: string | null;
          kemiSalesEndAt?: string | null;
          maxBuyPerOrder?: number | null;
          isRetailPriceChangeable?: boolean | null;
          discount?: {
            type: Types.EDiscountType;
            price?: number | null;
            rate?: number | null;
          } | null;
          shipping?: {
            shippingChargeType?: Types.EShippingChargeType | null;
            minPriceForFreeShipping?: number | null;
            shippingCharge?: number | null;
            extraChargeForIslandsAndMountains?: number | null;
            extraChargeForJeju?: number | null;
            shippingLocationType?: Types.EShippingLocationType | null;
            isChargedPerQuantity?: boolean | null;
            chargedQuantity?: number | null;
          } | null;
          options?: Array<{
            sku?: string | null;
            title?: string | null;
            image?: string | null;
            salesState?: Types.EOptionSalesState | null;
            supplyPrice?: number | null;
            suggestedRetailPrice?: number | null;
            requiredNumberOfExtraOption?: number | null;
            isExtraOptionExist?: boolean | null;
            extraOptionDescription?: string | null;
            discount?: {
              type: Types.EDiscountType;
              rate?: number | null;
              price?: number | null;
            } | null;
            extraOptions?: Array<{
              title?: string | null;
              sku?: string | null;
              stock?: {
                isInfiniteStock?: boolean | null;
                accumulatedInventory?: number | null;
                availableStock?: number | null;
              } | null;
            }> | null;
            stock?: {
              isInfiniteStock?: boolean | null;
              accumulatedInventory?: number | null;
              availableStock?: number | null;
            } | null;
          }> | null;
          additionalProductGroups?: Array<{
            name: string;
            products: Array<{
              imageUrls: Array<string>;
              maxBuyPerOrder?: number | null;
              name: string;
              price: number;
              salesState: Types.EOptionSalesState;
              sku: string;
              discount: {
                rate?: number | null;
                price?: number | null;
                type: Types.EDiscountType;
              };
              stock?: {
                accumulatedInventory?: number | null;
                availableStock?: number | null;
                isInfiniteStock?: boolean | null;
              } | null;
            }>;
          }> | null;
          stock?: {
            isInfiniteStock?: boolean | null;
            accumulatedInventory?: number | null;
            availableStock?: number | null;
          } | null;
        } | null;
      }
    | {}
    | null;
};

export const ProductCardDetailDocument = `
    query ProductCardDetail($productCardId: String!) {
  card(id: $productCardId) {
    ... on ProductCard {
      id
      user {
        id
        userProfile {
          phoneNumber
        }
        kemi {
          id
          linkName
          profileImageUrl
          title
        }
      }
      title
      suggestedRetailPrice
      productId
      emoji
      imageUrl
      showImage
      salesStartAlimtalkUserIds
      productDetail {
        id
        providerId
        isSellerProduct
        title
        sku
        description
        imageUrls
        content
        suggestedRetailPrice
        discount {
          type
          price
          rate
        }
        shipping {
          shippingChargeType
          minPriceForFreeShipping
          shippingCharge
          extraChargeForIslandsAndMountains
          extraChargeForJeju
          shippingLocationType
          isChargedPerQuantity
          chargedQuantity
        }
        supplyPrice
        kemiSalesPeriodState
        kemiStockState
        productState
        maxSaleDaysPerSeller
        kemiSalesStartAt
        kemiSalesEndAt
        maxBuyPerOrder
        isRetailPriceChangeable
        options {
          sku
          title
          image
          salesState
          supplyPrice
          suggestedRetailPrice
          discount {
            type
            rate
            price
          }
          requiredNumberOfExtraOption
          isExtraOptionExist
          extraOptionDescription
          extraOptions {
            title
            sku
            stock {
              isInfiniteStock
              accumulatedInventory
              availableStock
            }
          }
          stock {
            isInfiniteStock
            accumulatedInventory
            availableStock
          }
        }
        additionalProductGroups {
          name
          products {
            discount {
              rate
              price
              type
            }
            imageUrls
            maxBuyPerOrder
            name
            price
            salesState
            sku
            stock {
              accumulatedInventory
              availableStock
              isInfiniteStock
            }
          }
        }
        stock {
          isInfiniteStock
          accumulatedInventory
          availableStock
        }
      }
      createdAt
    }
  }
}
    `;
export const useProductCardDetailQuery = <
  TData = ProductCardDetailQueryResponse,
  TError = unknown
>(
  variables: ProductCardDetailQueryVariables,
  options?: UseQueryOptions<ProductCardDetailQueryResponse, TError, TData>
) =>
  useQuery<ProductCardDetailQueryResponse, TError, TData>(
    ['ProductCardDetail', variables],
    fetcher<ProductCardDetailQueryResponse, ProductCardDetailQueryVariables>(
      ProductCardDetailDocument,
      variables
    ),
    options
  );

useProductCardDetailQuery.getKey = (
  variables: ProductCardDetailQueryVariables
) => ['ProductCardDetail', variables];
export const useInfiniteProductCardDetailQuery = <
  TData = ProductCardDetailQueryResponse,
  TError = unknown
>(
  variables: ProductCardDetailQueryVariables,
  options?: UseInfiniteQueryOptions<
    ProductCardDetailQueryResponse,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<ProductCardDetailQueryResponse, TError, TData>(
    ['ProductCardDetail.infinite', variables],
    (metaData) =>
      fetcher<ProductCardDetailQueryResponse, ProductCardDetailQueryVariables>(
        ProductCardDetailDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteProductCardDetailQuery.getKey = (
  variables: ProductCardDetailQueryVariables
) => ['ProductCardDetail.infinite', variables];
useProductCardDetailQuery.fetcher = (
  variables: ProductCardDetailQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<ProductCardDetailQueryResponse, ProductCardDetailQueryVariables>(
    ProductCardDetailDocument,
    variables,
    options
  );
