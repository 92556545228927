import { useEffect } from 'react';

import useUserQuery from '@global/queries/useUserQuery';
import firebaseLogger from '@global/service/logger/event-logger/FirebaseLogger';

export const useFirebaseGlobalConfig = () => {
  const { data } = useUserQuery();
  useEffect(() => {
    firebaseLogger.setUser(data || null);
  }, [data]);
};
