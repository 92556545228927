import Badges from './Badges';

import { BadgeProps } from '@design0.2/Badge/Badge';
import { styled } from '@styles/stitches.config';

type CardContentProps = {
  title: string | JSX.Element;
  badges?: BadgeProps[];
  showBadge?: boolean;
  placeholder?: string;
};

function CardContent({
  showBadge,
  badges,
  title,
  placeholder,
}: CardContentProps) {
  return (
    <Content>
      {showBadge && badges && <Badges badges={badges} />}
      <CardTitle>
        {title}
        {!!placeholder && !title && (
          <span className={'placeholder'}>{placeholder}</span>
        )}
      </CardTitle>
    </Content>
  );
}

const Content = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  paddingX: 16,
  paddingY: 7, // 8px 들어와야 한다. 하지만 보더가 1px 있으므로 7px로 잡는다.
  gap: 2,
});

const CardTitle = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontType: 'bodyRegular',
  color: '$cardFontColor',
  textAlign: 'center',

  '.placeholder': {
    color: '$grey50',
  },
});

export default CardContent;
