import { SVGProps } from 'react';

import data from './data.generated';

type IconProps = {
  type: keyof typeof data;
} & SVGProps<SVGSVGElement>;

const Icon = (props: IconProps) => {
  const { type, ...svgProps } = props;

  const Component = data[type];

  return <Component {...svgProps} />;
};

export default Icon;
