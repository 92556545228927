import KemiError from './KemiError';

type NextApiErrorCode = 'NOT_IMPLEMENT';

class NextApiError extends KemiError {
  name: string;
  code: NextApiErrorCode;
  options: any;

  constructor(error: any) {
    super('NEXT_API_SERVER_ERROR');
    this.name = 'NextApiError';
    this.code = 'NOT_IMPLEMENT';
    this.options = error;
  }
}

export const nextApiErrorHandler = (error: NextApiError) => {
  return error;
};

export default NextApiError;
