import * as amplitude from '@amplitude/analytics-browser';
import { useEffect } from 'react';

import { facebookService } from '@global/service';

// 앱구동에 필요한 글로벌한 작업들
export const useConfigAppProperties = () => {
  useEffect(() => {
    // Facebook SDK init
    if (!window.FB) {
      facebookService.init();
    }

    amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string, {
      defaultTracking: true,
    });
  }, []);
};
