import * as React from 'react';
import { SVGProps } from 'react';
const SvgAddCardWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2 4h16v12H2z"
    />
    <path
      d="M20.5 8H22v12H4v-1.5M7 10h6M10 7v6"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgAddCardWhite;
