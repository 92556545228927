import { createContext } from 'react';

import { LayoutType } from '@shared/types/app';

type KemiAppContextValue = {
  layoutType: LayoutType;
};

const KemiAppContext = createContext<KemiAppContextValue>({
  layoutType: 'NONE',
});

export default KemiAppContext;
