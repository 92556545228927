import * as Types from 'schema/types';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type DailyInstagramStatsForSummaryQueryVariables = Types.Exact<{
  dailyInstagramInput: Types.DailyInstagramStatsInput;
}>;

export type DailyInstagramStatsForSummaryQueryResponse = {
  dailyInstagramStats?: {
    dailyInstagramStats?: Array<{
      date: string;
      followersCount: number;
      mediaCount: number;
      totalLikeCount: number;
      totalCommentsCount: number;
      totalSavedCount: number;
    } | null> | null;
  } | null;
};

export type InstagramMediasQueryVariables = Types.Exact<{
  instagramMediaInput: Types.InstagramMediaInput;
}>;

export type InstagramMediasQueryResponse = {
  instagramMedias?: {
    medias?: Array<{
      mediaId: string;
      mediaCreatedAt?: string | null;
      mediaProductType?: Types.EMediaProductType | null;
      mediaType?: Types.EMediaType | null;
      mediaUrl?: string | null;
      permalink?: string | null;
    } | null> | null;
  } | null;
};

export const DailyInstagramStatsForSummaryDocument = `
    query DailyInstagramStatsForSummary($dailyInstagramInput: DailyInstagramStatsInput!) {
  dailyInstagramStats(dailyInstagramInput: $dailyInstagramInput) {
    dailyInstagramStats {
      date
      followersCount
      mediaCount
      totalLikeCount
      totalCommentsCount
      totalSavedCount
    }
  }
}
    `;
export const useDailyInstagramStatsForSummaryQuery = <
  TData = DailyInstagramStatsForSummaryQueryResponse,
  TError = unknown
>(
  variables: DailyInstagramStatsForSummaryQueryVariables,
  options?: UseQueryOptions<
    DailyInstagramStatsForSummaryQueryResponse,
    TError,
    TData
  >
) =>
  useQuery<DailyInstagramStatsForSummaryQueryResponse, TError, TData>(
    ['DailyInstagramStatsForSummary', variables],
    fetcher<
      DailyInstagramStatsForSummaryQueryResponse,
      DailyInstagramStatsForSummaryQueryVariables
    >(DailyInstagramStatsForSummaryDocument, variables),
    options
  );

useDailyInstagramStatsForSummaryQuery.getKey = (
  variables: DailyInstagramStatsForSummaryQueryVariables
) => ['DailyInstagramStatsForSummary', variables];
export const useInfiniteDailyInstagramStatsForSummaryQuery = <
  TData = DailyInstagramStatsForSummaryQueryResponse,
  TError = unknown
>(
  variables: DailyInstagramStatsForSummaryQueryVariables,
  options?: UseInfiniteQueryOptions<
    DailyInstagramStatsForSummaryQueryResponse,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<
    DailyInstagramStatsForSummaryQueryResponse,
    TError,
    TData
  >(
    ['DailyInstagramStatsForSummary.infinite', variables],
    (metaData) =>
      fetcher<
        DailyInstagramStatsForSummaryQueryResponse,
        DailyInstagramStatsForSummaryQueryVariables
      >(DailyInstagramStatsForSummaryDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteDailyInstagramStatsForSummaryQuery.getKey = (
  variables: DailyInstagramStatsForSummaryQueryVariables
) => ['DailyInstagramStatsForSummary.infinite', variables];
useDailyInstagramStatsForSummaryQuery.fetcher = (
  variables: DailyInstagramStatsForSummaryQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    DailyInstagramStatsForSummaryQueryResponse,
    DailyInstagramStatsForSummaryQueryVariables
  >(DailyInstagramStatsForSummaryDocument, variables, options);
export const InstagramMediasDocument = `
    query InstagramMedias($instagramMediaInput: InstagramMediaInput!) {
  instagramMedias(instagramMediaInput: $instagramMediaInput) {
    medias {
      mediaId
      mediaCreatedAt
      mediaProductType
      mediaType
      mediaUrl
      permalink
    }
  }
}
    `;
export const useInstagramMediasQuery = <
  TData = InstagramMediasQueryResponse,
  TError = unknown
>(
  variables: InstagramMediasQueryVariables,
  options?: UseQueryOptions<InstagramMediasQueryResponse, TError, TData>
) =>
  useQuery<InstagramMediasQueryResponse, TError, TData>(
    ['InstagramMedias', variables],
    fetcher<InstagramMediasQueryResponse, InstagramMediasQueryVariables>(
      InstagramMediasDocument,
      variables
    ),
    options
  );

useInstagramMediasQuery.getKey = (variables: InstagramMediasQueryVariables) => [
  'InstagramMedias',
  variables,
];
export const useInfiniteInstagramMediasQuery = <
  TData = InstagramMediasQueryResponse,
  TError = unknown
>(
  variables: InstagramMediasQueryVariables,
  options?: UseInfiniteQueryOptions<InstagramMediasQueryResponse, TError, TData>
) => {
  return useInfiniteQuery<InstagramMediasQueryResponse, TError, TData>(
    ['InstagramMedias.infinite', variables],
    (metaData) =>
      fetcher<InstagramMediasQueryResponse, InstagramMediasQueryVariables>(
        InstagramMediasDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteInstagramMediasQuery.getKey = (
  variables: InstagramMediasQueryVariables
) => ['InstagramMedias.infinite', variables];
useInstagramMediasQuery.fetcher = (
  variables: InstagramMediasQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<InstagramMediasQueryResponse, InstagramMediasQueryVariables>(
    InstagramMediasDocument,
    variables,
    options
  );
