import * as React from 'react';
import { SVGProps } from 'react';
const SvgBinSmallBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.429 14 12 4H4l.571 10h6.858ZM2 4h12M6 2h4v2H6zM7 7v4M9 7v4"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgBinSmallBlack;
