import * as React from 'react';
import { SVGProps } from 'react';
const SvgBulbBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m9.5 20 5-.75M9 18l6-1M11.5 22h1M12 2C8.686 2 6 4.742 6 8.125c0 1.616.509 2.833 1.23 3.667C8.076 12.77 9 13.87 9 15.164V16l6-1c0-1.196.845-2.212 1.66-3.087.78-.834 1.34-2.092 1.34-3.788C18 4.742 15.314 2 12 2Z"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 8a4 4 0 0 0-4-4"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgBulbBlack;
