import Icon from '@design0.2/Icon/Icon';
import { styled } from '@styles/stitches.config';

function NoImage() {
  return (
    <Container>
      <Icon type={'image | black'} />
    </Container>
  );
}

const Container = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  transparentColor: 'translucentGrey',
});

export default NoImage;
