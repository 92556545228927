import * as React from 'react';
import { SVGProps } from 'react';
const SvgImageDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.8 3H4.2A1.2 1.2 0 0 0 3 4.2v15.6A1.2 1.2 0 0 0 4.2 21h15.6a1.2 1.2 0 0 0 1.2-1.2V4.2A1.2 1.2 0 0 0 19.8 3Z"
      stroke="#D8D8D8"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 11a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM6 14.823c3.285-1.862 7.64-.303 9 3.177"
      stroke="#D8D8D8"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 16c1.386-1.274 3.56-1.321 5-.108"
      stroke="#D8D8D8"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgImageDisabled;
