import classNames from 'classnames/bind';
import {
  createElement,
  ReactHTML,
  PropsWithChildren,
  forwardRef,
  AllHTMLAttributes,
} from 'react';

import { ColorName, colors } from '@styles/constants';
import { css, FontType } from '@styles/stitches.config';

type TextStyleProps = {
  font?: FontType;
  color?: ColorName;
};

export type TextProps = AllHTMLAttributes<Element> &
  PropsWithChildren<
    {
      type?: keyof ReactHTML;
      className?: string;
    } & TextStyleProps
  >;

const getTextClassName = ({ font, color }: TextStyleProps) => {
  return text({
    css: {
      ...(font && { fontType: font }),
      ...(color && { color: colors[color] }),
    },
  });
};

// eslint-disable-next-line react/display-name
const Text = forwardRef(
  (
    { type = 'span', font, color, className, children, ...rest }: TextProps,
    ref
  ) => {
    const textClassName = getTextClassName({ font, color }).className;

    return createElement(
      type,
      {
        ref,
        className: classNames(textClassName, className),
        ...rest,
      },
      children
    );
  }
);

const text = css({
  color: '$black',
  overflowWrap: 'anywhere',
  whiteSpace: 'pre-wrap',
  strong: {
    fontWeight: 700,
  },
});

export default Text;
