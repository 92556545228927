import {
  LoginWithFacebookDocument,
  LoginWithGoogleDocument,
  LoginWithKakaoDocument,
  LoginWithKemiDocument,
  LoginWithNaverDocument,
  RegisterWithFacebookDocument,
  RegisterWithGoogleDocument,
  RegisterWithKakaoDocument,
  RegisterWithNaverDocument,
  ReissueAccessTokenDocument,
} from '../graphql/auth.generated';
import { OAuthProviderType } from '../types';

import { randomCoverImage, randomProfileImage } from '@global/helper/image';
import ROUTE from '@routers';
import { getOrigin } from 'src/shared/helpers/app';

export const createDefaultKemiOption = () => {
  const kemiOption = {
    coverImageUrl: randomCoverImage(),
    profileImageUrl: randomProfileImage(),
  };

  return kemiOption;
};

export const getOAuthCallbackUri = (provider: OAuthProviderType) => {
  const origin = getOrigin();

  const path = ROUTE.oAuthCallback.replace('[provider]', provider);

  return origin + path;
};

export const isTokenCreationOperation = (operation: string) => {
  const TOKEN_CREATION_OPERATIONS = [
    LoginWithKakaoDocument,
    RegisterWithKakaoDocument,
    LoginWithNaverDocument,
    RegisterWithNaverDocument,
    LoginWithGoogleDocument,
    RegisterWithGoogleDocument,
    LoginWithFacebookDocument,
    RegisterWithFacebookDocument,
    LoginWithKemiDocument,
    ReissueAccessTokenDocument,
  ];

  return TOKEN_CREATION_OPERATIONS.includes(operation);
};
