import Image from '@global/components/atoms/Image';
import kemiFullpageLoader from '@static/media/kemi-fullpage-loader.gif';
import { styled } from '@styles/stitches.config';

const FullPageLoader = () => {
  return (
    <Block>
      <Image src={kemiFullpageLoader} alt={'loader'} width={176} height={32} />
    </Block>
  );
};

const Block = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
});

export default FullPageLoader;
