import { css } from '@styles/stitches.config';

const buttonCSS = css({
  boxShadow: '$buttonShadow8',
  fontType: 'bodyBold',
  width: '100%',
  height: 48,
  paddingRight: 16,
  paddingLeft: 16,
  border: 'none',
  borderRadius: 8,

  '&:hover': {
    cursor: 'pointer',
  },

  '&:focus': {
    outline: 'none',
  },

  '&:disabled': {
    cursor: 'default',
  },

  variants: {
    buttonType: {
      default: {
        backgroundColor: '$black',
        color: '$white',
      },
      defaultNoShadow: {
        backgroundColor: '$black',
        color: '$white',
        boxShadow: 'none',
      },
      defaultTransparent: {
        backgroundColor: '$transparentBlack',
        color: '$white',
      },
      disabled: {
        backgroundColor: '$grey50',
        color: '$white',

        '&:hover': {
          cursor: 'unset',
        },
      },
      disabledNoShadow: {
        backgroundColor: '$grey50',
        color: '$white',
        boxShadow: 'none',

        '&:hover': {
          cursor: 'unset',
        },
      },
      inverse: {
        backgroundColor: '$white',
        color: '$black',
      },
      inverseWeekNoShadow: {
        backgroundColor: '$white',
        color: '$grey100',
        boxShadow: 'none',
      },
      alert: {
        backgroundColor: '$white',
        color: '$red100',
      },
      alertNoShadow: {
        backgroundColor: '$white',
        color: '$red100',
        boxShadow: 'none',
      },
      alertWeekNoShadow: {
        backgroundColor: '$grey20',
        color: '$red100',
        boxShadow: 'none',
      },
      kakaoLogin: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FAE64D',
        color: '$black',

        '&:first-of-type': {
          marginRight: 11,
        },
      },
      naverLogin: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#5AC466',
        color: '$white',

        '&:first-of-type': {
          marginRight: 11,
        },
      },
      googleLogin: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '$white',
        color: '$black',
        border: '1px solid $black',

        '&:first-of-type': {
          marginRight: 11,
        },
      },
      line: {
        backgroundColor: '$white',
        color: '$black',
        border: '1px solid $black',

        '&:disabled': {
          backgroundColor: '$transparent',
          color: '$grey50',
          border: '1px solid $grey50',
          boxShadow: 'none',
        },
      },
      lineNoShadow: {
        backgroundColor: '$white',
        color: '$black',
        border: '1px solid $black',
        boxShadow: 'none',

        '&:disabled': {
          backgroundColor: '$transparent',
          color: '$grey50',
          border: '1px solid $grey50',
          boxShadow: 'none',
        },
      },
      lineWeek: {
        backgroundColor: '$white',
        color: '$black',
        border: '1px solid $grey50',

        '&:disabled': {
          backgroundColor: '$transparent',
          color: '$grey50',
          border: '1px solid $grey50',
          boxShadow: 'none',
        },
      },
      inverseNoShadow: {
        backgroundColor: '$white',
        color: '$black',
        boxShadow: 'none',
      },
      facebook: {
        backgroundColor: '$facebook',
        color: '$white',
        boxShadow: 'none',
      },
      sideMenu: {
        backgroundColor: '$transparentWhite',
        color: '$black',
      },
      instagram: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'linear-gradient(88.88deg, #6166e2 0%, #ec3f63 100%)',
        color: '$white',
      },
      small: {
        fontType: 'bodyBold',
        padding: 8,
        borderRadius: 8,
        backgroundColor: '$black',
        color: '$white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
      smallInverse: {
        fontType: 'bodyBold',
        boxShadow: '$buttonShadow8',
        '&:hover': {
          cursor: 'pointer',
        },
        paddingY: 8,
        color: '$black',
      },
      sizeSmall: {
        paddingX: 30,
      },
      dropdown: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '$white',
        color: '$black',
        fontType: 'bodyRegular',
        border: '1px solid $grey50',
      },
      purple100: {
        backgroundColor: '$purple100',
        color: '$white',
        boxShadow: '0 8px 16px -8px #c878ff',
      },
      smallOval: {
        backgroundColor: '$white',
        color: '$black',
        fontType: 'captionRegular',
        width: 'fit-content',
        padding: '8px 16px',
        border: '1px solid $grey50',
        borderRadius: 100,

        '&:disabled': {
          borderColor: '$grey20',
          color: '$grey50',
        },
      },
    },
  },
});

export default buttonCSS;
