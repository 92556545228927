import classNames from 'classnames/bind';
import React from 'react';

import buttonCSS from './buttonCSS';

import { ButtonProps } from '@global/components/atoms/Button/Button';

export type FormButtonProps = Exclude<
  React.HTMLProps<HTMLInputElement>,
  'type'
> & {
  buttonType?: ButtonProps['type'];
  type?: 'button' | 'submit' | 'reset';
};

const FormButton = ({
  buttonType = 'default',
  disabled,
  className,
  type,
  ...props
}: FormButtonProps) => {
  const buttonClassName = buttonCSS({ buttonType }).className;

  return (
    <input
      type={type || 'submit'}
      className={classNames(buttonClassName, className)}
      disabled={buttonType === 'disabled' || disabled}
      {...props}
    />
  );
};

export default FormButton;
