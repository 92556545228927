import * as React from 'react';
import { SVGProps } from 'react';
const SvgProfileDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m6.927 5.02-3.375 6a2 2 0 0 0 0 1.96l3.375 6A2 2 0 0 0 8.67 20h6.66a2 2 0 0 0 1.743-1.02l3.375-6a2 2 0 0 0 0-1.96l-3.375-6A2 2 0 0 0 15.33 4H8.67a2 2 0 0 0-1.743 1.02ZM10.5 9v2M13.5 9v2"
      stroke="#D8D8D8"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 13c.333.333 1.4 1 3 1s2.667-.667 3-1"
      stroke="#D8D8D8"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgProfileDisabled;
