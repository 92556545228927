import { InputHTMLAttributes } from 'react';

import Icon from '@design0.2/Icon/Icon';
import { Required } from '@design0.2/Inputs/shared/components/styled';
import { styled } from '@styles/stitches.config';

export type CheckBoxProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
};

function CheckBoxInput({
  checked,
  disabled,
  label,
  required,
  ...props
}: CheckBoxProps) {
  return (
    <Label>
      <IconWrapper>
        {checked && !disabled && <Icon type={'checkbox | checked'} />}
        {checked && disabled && <Icon type={'checkbox | checked | disabled'} />}
        {!checked && !disabled && <Icon type={'checkbox | unchecked'} />}
        {!checked && disabled && (
          <Icon type={'checkbox | unchecked | disabled'} />
        )}
      </IconWrapper>
      {label && (
        <LabelText>
          {label} {required && <Required>{'*'}</Required>}
        </LabelText>
      )}
      <Input
        type={'checkbox'}
        disabled={disabled}
        checked={checked}
        {...props}
      />
    </Label>
  );
}

const Input = styled('input', {
  display: 'none',
});

const Label = styled('label', {
  display: 'inline-flex',
  alignItems: 'center',
  gap: 4,

  '&:hover': {
    cursor: 'pointer',
  },
});

const IconWrapper = styled('span', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 24,
});

const LabelText = styled('span', {
  fontType: 'bodyRegular',
  wordBreak: 'break-all',
  color: '$black',
});

export default CheckBoxInput;
