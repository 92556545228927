import { useRouter } from 'next/router';
import { useEffect } from 'react';

import routeHistoryStore from '@shared/store/routeHistoryStore';

function useRouteChange() {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      routeHistoryStore.setState({
        prevUrl: window.location.pathname,
        currentUrl: url,
      });
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useRouteChange;
