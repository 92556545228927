import * as React from 'react';
import { SVGProps } from 'react';
const SvgClockSmallWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={8}
      cy={8}
      r={4}
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.682 3a6.03 6.03 0 0 0-1.88 2M11.318 3a6.03 6.03 0 0 1 1.88 2M8 6v2l1 1"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgClockSmallWhite;
