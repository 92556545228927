import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';
import React from 'react';

const KemiHead = () => {
  const router = useRouter();
  return (
    <DefaultSeo
      title={'Kemi, 수익화를 돕는 스마트프로필 케미'}
      description={
        'Kemi, 내 영향력을 수익화하는 SNS프로필 케미 무료로 만들어보세요'
      }
      openGraph={{
        title: 'Kemi, 수익화를 돕는 스마트프로필 케미',
        type: 'website',
        description:
          'Kemi, 내 영향력을 수익화하는 SNS프로필 케미 무료로 만들어보세요',
        url: `${process.env.NEXT_PUBLIC_HOST_NAME}${router.asPath}`,
        images: [
          {
            url: 'https://asset.kemist.io/og/landing.png',
            alt: '링크 하나로는 부족하셨죠? > 이미지 대체 텍스트 “여러 SNS를 연동해서 SNS 프로필 링크로 넣을 수 있는 케미”\n\n어려운 일은 케미가 알아서 해드려요 > 이미지 대체 텍스트 “발주, 정산, CS를 돕는 케미의 SNS마켓 서포트 기능”',
          },
        ],
      }}
    />
  );
};

export default KemiHead;
