import * as React from 'react';
import { SVGProps } from 'react';
const SvgTextMarginBottom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#text-margin_bottom_svg__a)">
      <rect
        x={0.25}
        y={0.25}
        width={23.5}
        height={23.5}
        rx={1.75}
        fill="#E9E6FF"
        stroke="#303030"
        strokeWidth={0.5}
        strokeLinejoin="round"
        strokeDasharray="1 1"
      />
      <path
        d="M8.796 2.65H6.763V7.9h-.73V2.65H4V2h4.796v.65ZM12.2 7.192c-.242.3-.509.51-.798.633-.29.117-.626.175-1.008.175a2.23 2.23 0 0 1-.86-.158 2.043 2.043 0 0 1-.652-.442c-.174-.183-.31-.4-.408-.65a2.283 2.283 0 0 1-.14-.8c0-.3.053-.575.157-.825A1.929 1.929 0 0 1 9.586 4.05c.254-.1.532-.15.834-.15.284 0 .544.047.782.142.237.089.44.222.608.4.174.172.307.386.4.641.092.25.138.536.138.859v.208H9.064c.012.167.052.328.122.483.075.15.17.284.287.4.116.111.252.2.408.267.156.067.327.1.513.1.295 0 .544-.05.747-.15.203-.1.385-.247.547-.442l.513.384ZM11.62 5.6c-.012-.333-.125-.6-.34-.8-.213-.2-.509-.3-.885-.3-.377 0-.68.1-.913.3-.231.2-.37.467-.417.8h2.555ZM14.448 5.8 12.988 4h.887l1.077 1.4L15.97 4h.825l-1.399 1.8 1.677 2.1h-.895l-1.286-1.683L13.623 7.9h-.843l1.668-2.1ZM19.965 4.55h-1.199V6.5c0 .128.003.247.009.358.006.106.029.2.07.284a.49.49 0 0 0 .182.191.735.735 0 0 0 .347.067 1.457 1.457 0 0 0 .6-.133l.026.591c-.116.05-.246.086-.391.109a2.533 2.533 0 0 1-.4.033c-.243 0-.437-.03-.582-.092a.786.786 0 0 1-.339-.258.975.975 0 0 1-.165-.408 4.022 4.022 0 0 1-.034-.55V4.55h-.887V4h.887V2.892h.677V4h1.2v.55Z"
        fill="#303030"
      />
    </g>
    <defs>
      <clipPath id="text-margin_bottom_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTextMarginBottom;
