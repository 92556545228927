import { globalCss } from './stitches.config';

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

export const resetCss = globalCss({
  'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, button, audio, video':
    {
      margin: 0,
      padding: 0,
      border: 0,
      fontSize: '100%',
      font: 'inherit',
      verticalAlign: 'baseline',
    },
  'article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section':
    {
      display: 'block',
    },
  body: {
    lineHeight: 1,
  },
  'ol, ul': {
    listStyle: 'none',
  },
  'blockquote, q': {
    quotes: 'none',
  },
  'blockquote:before, blockquote:after, q:before, q:after': {
    content: '',
    // typescript error 로 주석처리합니다.
    // content: 'none',
  },
  table: {
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  // 폼태그는 font 를 상속받지 않아서 추가했습니다.
  'input, button, textarea, select': {
    fontFamily: 'inherit',
  },
});

export const globalStyles = globalCss({
  '*': {
    boxSizing: 'border-box',
    '&::before, &::after': {
      boxSizing: 'border-box',
    },
  },
  body: {
    fontFamily: 'Pretendard,Nanum Gothic,sans-serif',
    color: '$black',
  },
  a: {
    outline: 'none',
    color: 'unset',
    textDecoration: 'none',
  },
  'a:hover, a:active': {
    textDecoration: 'none',
  },
  button: {
    backgroundColor: 'unset',
    color: '$black',
  },
  // 페이지별 svg에서 넣도록 수정필요.
  // svg: {
  //   verticalAlign: 'bottom',
  // },
  'input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
    {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
    {
      backgroundClip: 'content-box',
      backgroundColor: 'white',
      '-webkit-box-shadow': '0 0 0 1000px white inset',
    },
});
