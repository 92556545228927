// eslint-disable-next-line no-restricted-imports
import _Image, { ImageLoader, ImageProps } from 'next/image';

export type ImageType = Omit<ImageProps, 'unoptimized' | 'loader'>;

export type ImageSrc = ImageProps['src'];

/**
 * @deprecated
 * 네이티브 next/image를 사용해주세요
 */
const Image = (props: ImageType) => {
  const isStaticImage = typeof props.src !== 'string';

  if (isStaticImage) {
    return <_Image {...props} />;
  } else {
    return <_Image loader={imageLoader} unoptimized {...props} />;
  }
};

const imageLoader: ImageLoader = ({ src }) => {
  return src;
};

export default Image;
