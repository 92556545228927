import { SVGProps } from 'react';

import { colors } from '@styles/constants';

type EmailProps = {
  color?: string;
  size?: 'small' | 'big';
} & SVGProps<SVGSVGElement>;

const Email = ({ color = colors.black, size, style, ...rest }: EmailProps) => {
  const _style =
    size === 'small' ? { transform: 'scale(0.5)', ...style } : style;

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      style={_style}
      {...rest}
    >
      <rect
        x={'3'}
        y={'5'}
        width={'18'}
        height={'14'}
        rx={'3'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M3 8L10.3359 12.8906C11.3436 13.5624 12.6564 13.5624 13.6641 12.8906L21 8'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default Email;
