import * as React from 'react';
import { SVGProps } from 'react';
const SvgRatioLandscapeSBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#E9E6FF"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4 9h16v6H4z"
    />
  </svg>
);
export default SvgRatioLandscapeSBlack;
