import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SellerCenterDashboardSkeleton = ({ isSeller }: { isSeller: boolean }) => {
  const skeletonStyle = { borderRadius: 16 };

  return (
    <>
      <Skeleton style={skeletonStyle} height={106} />
      <Skeleton style={skeletonStyle} height={106} />
      <Skeleton style={skeletonStyle} height={106} />
      {isSeller && <Skeleton style={skeletonStyle} height={106} />}
    </>
  );
};

export default SellerCenterDashboardSkeleton;
