import * as React from 'react';
import { SVGProps } from 'react';
const SvgReceivedBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m3 10 9 6 9-6v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-9Z" />
      <path d="m9 9 2 2 4-4" />
      <path d="M18 12V3H6v9M3 10l3-2.333M21 10l-3-2.333" />
    </g>
  </svg>
);
export default SvgReceivedBlack;
