export const OrderReturn = ({ ...rest }) => {
  let color;

  switch (rest.type) {
    case 'linedWhite':
      color = 'white';
      break;
    case 'linedGrey':
      color = '#D8D8D8';
      break;
    default:
      color = '#303030';
  }

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        d={'M12 10V13.5'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <circle cx={'12'} cy={'16'} r={'1'} fill={color} />
      <rect
        x={'8'}
        y={'2'}
        width={'8'}
        height={'4'}
        rx={'1'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M8 3H6C4.89543 3 4 3.89543 4 5V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V5C20 3.89543 19.1046 3 18 3H16'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};
