import { useRef, useEffect } from 'react';

/**
 * @deprecated
 * react strict mode에서 useEffect가 두번실행되는걸 막고자 만든건데 쓰지 말아주세요.
 * 곧 지울 예정입니다. 만약 useEffect 두번 실행이되어 문제를 일으킨다면 애초에 잘못된 useEffect사용 일 수 있습니다.
 */
function useEffectOnce(effectCallback: () => void) {
  const isInvoked = useRef(false);

  useEffect(() => {
    if (!isInvoked.current) {
      isInvoked.current = true;
      effectCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useEffectOnce;
