import Image from 'next/image';

import { getImageWidth } from '../helper';
import { ECardThumbnailType } from '../types';

import { EImageSize } from '@schema/types';
import KemiImage from '@shared/components/KemiImage/KemiImage';
import musicCardIcon from '@static/media/music-card-icon.svg';
import videoCardIcon from '@static/media/video-card-icon.svg';
import HideEye from '@static/svg/HideEye';
import { styled } from '@styles/stitches.config';

type CardThumbnailProps = {
  imageSize: EImageSize;
  imageUrl: string;
  emoji?: string;
  isLoading?: boolean;
  hide?: boolean;
  label?: string;
  videoIcon?: boolean;
  musicIcon?: boolean;
  thumbnailType: ECardThumbnailType;
  onError?: () => void;
};

function CardThumbnail({
  imageSize = 'SMALL',
  imageUrl,
  emoji,
  isLoading,
  hide,
  label,
  videoIcon,
  musicIcon,
  thumbnailType,
  onError,
}: CardThumbnailProps) {
  const hasMediaIcon = !!videoIcon || !!musicIcon;
  const hasOverlay = hide || !!label;

  const imageWidth = getImageWidth(imageSize);

  return (
    <ImageWrapper type={imageSize}>
      {thumbnailType !== 'EMOJI' && (
        <KemiImage
          fill={!imageWidth}
          width={imageWidth || undefined}
          height={imageWidth || undefined}
          alt={'your-kemi-card-image'}
          src={imageUrl}
          ratio={1}
          isLoading={isLoading}
          onError={onError}
        />
      )}
      {hasMediaIcon && (
        <MediaIcon>
          {videoIcon && <Image src={videoCardIcon} alt={'videoCardIcon'} />}
          {musicIcon && <Image src={musicCardIcon} alt={'musicCardIcon'} />}
        </MediaIcon>
      )}
      {thumbnailType === 'EMOJI' && (
        <Emoji>
          <div>{emoji}</div>
        </Emoji>
      )}
      {hasOverlay && (
        <Overlay>
          {hide && (
            <div className={'hide'}>
              <HideEye />
            </div>
          )}
          {label && <div className={'label'}>{label}</div>}
        </Overlay>
      )}
    </ImageWrapper>
  );
}

const ImageWrapper = styled('div', {
  position: 'relative',
  width: 72,
  height: 72,
  transition: 'width 0.2s ease',
  overflow: 'hidden',
  borderRadius: '$imageBorderRadius',
  // absolute인 자식들에게 hidden이 안먹히는 사파리 버그 대응
  // https://bugs.webkit.org/show_bug.cgi?id=98538
  zIndex: 0,

  variants: {
    type: {
      SMALL: {
        width: 72,
        height: 72,
      },
      MEDIUM: {
        width: 112,
        height: 112,
      },
      LARGE: {
        width: '100%',
        height: 'initial',
      },
    },
  },
  defaultVariants: {
    type: 'SMALL',
  },
});

const Emoji = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  borderRadius: '$imageBorderRadius',
  overflow: 'hidden',

  transparentColor: 'translucentGrey',
  fontSize: 32,
  paddingBottom: '100%',

  div: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Overlay = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  borderRadius: '$imageBorderRadius',
  zIndex: 1,

  '.hide': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.64)',
    textAlign: 'center',
    'svg path': {
      stroke: '$white',
    },
  },
  '.label': {
    paddingX: 8,
    paddingY: 6,
    color: '$white',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.64)',
    fontType: 'labelBold',
    textAlign: 'center',
  },
});

const MediaIcon = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});

export default CardThumbnail;
