import { PropsWithChildren } from 'react';

import useChannelTalkBoot from '@pages/app/hooks/useChannelTalkBoot';

type Props = PropsWithChildren<{}>;

const ChannelTalk = ({ children }: Props) => {
  useChannelTalkBoot();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default ChannelTalk;
