import { PropsWithChildren, Suspense } from 'react';

import PageToastContainer from '@design0.2/Toast/PageToastContainer';
import { useTheme } from '@global/hooks/useTheme';
import useUserAgent from '@shared/hooks/useUserAgent';
import { styled } from '@styles/stitches.config';

const KemiLayout = ({
  linkName,
  children,
}: PropsWithChildren<{ linkName?: string }>) => {
  const { isDesktop } = useUserAgent();
  const { theme } = useTheme(linkName || '');

  return (
    <Container className={linkName ? theme : ''}>
      <Content isDesktop={isDesktop}>
        <ToastPosition>
          <PageToastContainer />
        </ToastPosition>
        <Suspense fallback={null}>{children}</Suspense>
      </Content>
    </Container>
  );
};

const Container = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  minHeight: '100vh',
  background: '$bgSolidColor',
});

const ToastPosition = styled('div', {
  position: 'sticky',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: '$kemiPageToast',
});

const Content = styled('div', {
  width: '100%',
  position: 'relative',
  variants: {
    isDesktop: {
      true: {
        width: '$appWidthWhenDesktop',
      },
      false: {
        width: '100%',
      },
    },
  },
});

export default KemiLayout;
