import { css } from '@storybook/theming';

import { styled } from '@styles/stitches.config';

export const Descriptions = styled('div', {
  marginTop: 8,
  fontType: 'captionRegular',
  color: '$grey100',
});

export const Caption = styled('p', {
  whiteSpace: 'pre-line',
});

export const ErrorMessage = styled('p', {
  color: '$red100',
});

export const InputStyle = css({
  fontType: 'subtitleRegular',
});

export const DefaultInput = styled('input', {
  width: '100%',
  fontType: 'subtitleRegular',
  color: '$black',

  '&:focus': {
    outline: 'none',
  },
  '&:disabled': {
    backgroundColor: '$grey20',
    color: '$black',
    // iOS disabled 스타일 이슈를 위해 추가해줍니다
    '-webkit-text-fill-color': '$colors$black',
    opacity: 1,
  },
  '&::placeholder': {
    color: '$grey100',
  },
});

export const DefaultInputWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  color: '$grey100',

  variants: {
    hasContent: {
      true: {
        flexDirection: 'column',
        gap: 0,
        alignItems: 'flex-start',
        justifyContent: 'center',

        '& > span': {
          fontType: 'captionRegular',
        },
      },
    },
  },

  '& > span': {
    whiteSpace: 'nowrap',
  },

  '&:focus-within': {
    flexDirection: 'column',
    gap: 0,
    alignItems: 'flex-start',
    justifyContent: 'center',

    '& > span': {
      fontType: 'captionRegular',
    },
  },
});

export const Required = styled('span', {
  color: '$red100',
});

export const DefaultInputLabel = styled('label', {
  position: 'relative',
  display: 'flex',
  alignItems: 'stretch',
  gap: 16,
  paddingX: 16,
  borderRadius: 8,
  height: 56,
  border1: '$grey50',
  width: '100%',

  background: '$white',

  '&:focus-within': {
    outline: 'none',
    border1: '$secondary100',
  },

  variants: {
    showError: {
      true: {
        border1: '$red100',
        '&:focus-within': {
          border1: '$red100',
        },
      },
    },
    disabled: {
      true: {
        background: '$grey20',
      },
    },
    hasActionButton: {
      true: {
        paddingRight: 12,
      },
    },
    small: {
      true: {
        height: 32,
        span: {
          fontType: 'captionRegular',
        },
      },
    },
  },
});

export const RemoveButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  zIndex: 2, // 위에 덮이는 input or select가 1, 그 위에 덮이는 clear button이 2
});
