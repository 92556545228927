import Link from 'next/link';
import { useRouter } from 'next/router';

import { Menu, MenuProps } from '@pages/app/components/Menu';
import { ArrowRight, RightContainer } from '@pages/app/components/shared';

export const InternalLinkMenu = ({
  targetUrls,
  postfix,
  ...props
}: MenuProps & { targetUrls: string[] }) => {
  const router = useRouter();
  const active = targetUrls?.some((url) => router.pathname === url);

  return (
    <Link href={targetUrls[0]} passHref>
      <Menu
        {...props}
        active={active}
        postfix={
          <RightContainer>
            {postfix}
            <ArrowRight inverse={active} />
          </RightContainer>
        }
      />
    </Link>
  );
};
