import * as React from 'react';
import { SVGProps } from 'react';
const SvgShopWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 12v8h6v-3a2 2 0 0 1 2-2v0a2 2 0 0 1 2 2v3h6v-8M10 4h4v6a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2V4ZM14 4h3l1 4v2a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2V4Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 4h3l2 4v2a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2V8l-1-4ZM7 4h3v6a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2V8l1-4Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 4h3L6 8v2a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2V8l2-4Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgShopWhite;
