import * as Types from 'schema/types';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type OrderMenusQueryVariables = Types.Exact<{
  orderMenusInput: Types.OrderMenusInput;
}>;

export type OrderMenusQueryResponse = {
  orderMenus?: { menus: Array<{ title: string; count: number }> } | null;
};

export type SellerCenterDashboardQueryVariables = Types.Exact<{
  orderMenusInput: Types.OrderMenusInput;
  dailySellerInput?: Types.InputMaybe<Types.DailySellerStatsInput>;
}>;

export type SellerCenterDashboardQueryResponse = {
  orderMenus?: { menus: Array<{ count: number; title: string }> } | null;
  dailySellerStats?: {
    sellerStats?: Array<{ date: string; sales: number } | null> | null;
  } | null;
};

export const OrderMenusDocument = `
    query OrderMenus($orderMenusInput: OrderMenusInput!) {
  orderMenus(orderMenusInput: $orderMenusInput) {
    menus {
      title
      count
    }
  }
}
    `;
export const useOrderMenusQuery = <
  TData = OrderMenusQueryResponse,
  TError = unknown
>(
  variables: OrderMenusQueryVariables,
  options?: UseQueryOptions<OrderMenusQueryResponse, TError, TData>
) =>
  useQuery<OrderMenusQueryResponse, TError, TData>(
    ['OrderMenus', variables],
    fetcher<OrderMenusQueryResponse, OrderMenusQueryVariables>(
      OrderMenusDocument,
      variables
    ),
    options
  );

useOrderMenusQuery.getKey = (variables: OrderMenusQueryVariables) => [
  'OrderMenus',
  variables,
];
export const useInfiniteOrderMenusQuery = <
  TData = OrderMenusQueryResponse,
  TError = unknown
>(
  variables: OrderMenusQueryVariables,
  options?: UseInfiniteQueryOptions<OrderMenusQueryResponse, TError, TData>
) => {
  return useInfiniteQuery<OrderMenusQueryResponse, TError, TData>(
    ['OrderMenus.infinite', variables],
    (metaData) =>
      fetcher<OrderMenusQueryResponse, OrderMenusQueryVariables>(
        OrderMenusDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteOrderMenusQuery.getKey = (variables: OrderMenusQueryVariables) => [
  'OrderMenus.infinite',
  variables,
];
useOrderMenusQuery.fetcher = (
  variables: OrderMenusQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<OrderMenusQueryResponse, OrderMenusQueryVariables>(
    OrderMenusDocument,
    variables,
    options
  );
export const SellerCenterDashboardDocument = `
    query SellerCenterDashboard($orderMenusInput: OrderMenusInput!, $dailySellerInput: DailySellerStatsInput) {
  orderMenus(orderMenusInput: $orderMenusInput) {
    menus {
      count
      title
    }
  }
  dailySellerStats(dailySellerInput: $dailySellerInput) {
    sellerStats {
      date
      sales
    }
  }
}
    `;
export const useSellerCenterDashboardQuery = <
  TData = SellerCenterDashboardQueryResponse,
  TError = unknown
>(
  variables: SellerCenterDashboardQueryVariables,
  options?: UseQueryOptions<SellerCenterDashboardQueryResponse, TError, TData>
) =>
  useQuery<SellerCenterDashboardQueryResponse, TError, TData>(
    ['SellerCenterDashboard', variables],
    fetcher<
      SellerCenterDashboardQueryResponse,
      SellerCenterDashboardQueryVariables
    >(SellerCenterDashboardDocument, variables),
    options
  );

useSellerCenterDashboardQuery.getKey = (
  variables: SellerCenterDashboardQueryVariables
) => ['SellerCenterDashboard', variables];
export const useInfiniteSellerCenterDashboardQuery = <
  TData = SellerCenterDashboardQueryResponse,
  TError = unknown
>(
  variables: SellerCenterDashboardQueryVariables,
  options?: UseInfiniteQueryOptions<
    SellerCenterDashboardQueryResponse,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<SellerCenterDashboardQueryResponse, TError, TData>(
    ['SellerCenterDashboard.infinite', variables],
    (metaData) =>
      fetcher<
        SellerCenterDashboardQueryResponse,
        SellerCenterDashboardQueryVariables
      >(SellerCenterDashboardDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteSellerCenterDashboardQuery.getKey = (
  variables: SellerCenterDashboardQueryVariables
) => ['SellerCenterDashboard.infinite', variables];
useSellerCenterDashboardQuery.fetcher = (
  variables: SellerCenterDashboardQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    SellerCenterDashboardQueryResponse,
    SellerCenterDashboardQueryVariables
  >(SellerCenterDashboardDocument, variables, options);
