import {
  useMeForFacebookAccessQuery,
  useUpdateSellerInfoForFacebookAccessMutation,
} from './graphql/FacebookAccess.generated';

import { useModalToast } from '@design0.2/Toast/hooks/useToast';
import { FACEBOOK_ERROR_CODE } from '@global/constants';
import { facebookService } from '@global/service';
import FacebookApiError from '@global/service/Error/FacebookApiError';
import globalErrorHandler from '@global/service/Error/globalErrorHandler';
import {
  FacebookAuthDataType,
  InstagramUserDataFacebookAPIType,
} from '@global/types';

function useFacebookAccess() {
  const toast = useModalToast();
  const { isLoading, data } = useMeForFacebookAccessQuery();
  const { mutateAsync: mutateSellerInfo } =
    useUpdateSellerInfoForFacebookAccessMutation();

  const requestFacebookAccess = async (
    callback: (authData: FacebookAuthDataType) => void
  ) => {
    try {
      const loginResponse = await facebookService
        .requestFacebookAccess('seller')
        .catch(() => {
          facebookService.facebookLogout();
          callback({ accessToken: null, instagramUserId: null });
        });

      const accessToken = loginResponse?.authResponse?.accessToken;

      // 반환되는 토큰이 없는 경우 튕겨내기
      if (!accessToken) {
        facebookService.facebookLogout();
        callback({ accessToken: null, instagramUserId: null });
        return;
      }

      let sellerInfo = {};

      const longTermToken = await facebookService.getLongTermAccessToken(
        accessToken
      );

      const instagramUserId = await facebookService.getInstagramUserId(
        longTermToken
      );

      // 토큰 만료 이슈로 로그아웃된 경우, 기존 userId와 다른 userId로 로그인하면 튕겨내기
      if (
        data?.me?.sellerInfo?.instagram?.instagramUserId &&
        data?.me?.sellerInfo?.instagram?.instagramUserId !== instagramUserId
      ) {
        facebookService.facebookLogout();
        toast.bad('기존 계정 정보와 상이합니다.');
        callback({
          accessToken: null,
          instagramUserId: data?.me?.sellerInfo?.instagram?.instagramUserId,
        });
        return;
      }

      // 연결된 인스타그램 계정이 없거나 허용하지 않은 경우 튕겨내기
      if (!instagramUserId) {
        facebookService.facebookLogout();
        callback({ accessToken: null, instagramUserId: null });
        return;
      }

      sellerInfo = {
        instagram: {
          accessToken: longTermToken,
          instagramUserId: instagramUserId,
        },
      };

      // 기존 등록한 sns 중에서 인스타그램 계정 정보가 없는 경우, 판매자 인증시 자동으로 넣어주기
      if (data && !data.me?.sellerInfo?.sns) {
        const { username } = (await facebookService.getInstagramUserData(
          instagramUserId,
          longTermToken
        )) as InstagramUserDataFacebookAPIType;

        if (username) {
          sellerInfo = {
            ...sellerInfo,
            sns: {
              type: 'INSTAGRAM',
              url: '@' + username,
            },
          };
        }
      }

      mutateSellerInfo({ sellerInfo });
      callback({ instagramUserId, accessToken: longTermToken });
    } catch (err: any) {
      globalErrorHandler(
        new FacebookApiError({
          ...err,
          message: FACEBOOK_ERROR_CODE.NO_FACEBOOK_ACCESS_TOKEN,
        }),
        { toast: '페이스북 로그인에 실패했습니다.' }
      );
    }
  };

  return {
    isFacebookAuthLoading: isLoading,
    facebookAuthData: data?.me?.sellerInfo?.instagram,
    requestFacebookAccess,
  };
}

export default useFacebookAccess;
