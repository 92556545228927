import { colors } from '@styles/constants';

const HideEye = ({ inverse, ...rest }: { inverse?: boolean }) => {
  const svgColor = inverse ? colors.white : colors.black;

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={
          'M12 16C9.79086 16 8 14.2091 8 12M9.21069 9.13298C9.93125 8.43184 10.9152 8 12 8C14.2091 8 16 9.79086 16 12C16 13.0877 15.5659 14.074 14.8614 14.795'
        }
        stroke={svgColor}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M7.23949 7.27131C8.6154 6.52827 10.2197 6 12 6C18 6 22 12 22 12C22 12 20.0127 14.9809 16.7385 16.7405M4.86977 8.89834C3.0457 10.4314 2 12 2 12C2 12 6 18 12 18C12.6317 18 13.2412 17.9335 13.8262 17.8145'
        }
        stroke={svgColor}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M3 3L21 21'}
        stroke={svgColor}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default HideEye;
