import KemiError from './KemiError';

import { EKemiErrorCode } from '@schema/types';

export type GraphqlError = {
  extensions: {
    code: EKemiErrorCode;
    exception: {
      stacktrace: string[];
    };
  };
  locations: { line: number; column: number }[];
  message: string;
  path: string[];
}[];

class KemiApiError extends KemiError {
  name: string;
  code: EKemiErrorCode;
  options: any;

  constructor(error: GraphqlError, options?: any) {
    super(error[0].message);
    this.name = 'KemiApiError';
    this.code = error[0].extensions.code;
    this.options = options;
  }
}

export default KemiApiError;
