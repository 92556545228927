import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { ReactNode } from 'react';

import InternalError from './InternalError';
import NetworkError from './NetworkError';

import NotFoundErrorPage from '@pages/404/index.page';
import Wrapper from '@pages/app/Wrapper';
import NotFoundError from '@shared/errors/NotFoundError';

type ErrorBoundaryProps = {
  children: ReactNode;
};

const ErrorBoundary = (props: ErrorBoundaryProps) => {
  const { children } = props;

  return (
    <SentryErrorBoundary
      fallback={({ error }) => {
        if (
          error instanceof TypeError &&
          error.message === 'Network request failed'
        ) {
          // graphql-request에서 사용하는 node-fetch의 네트워크 에러 인터페이스 및 메세지 입니다.
          return <NetworkError />;
        }

        if (error instanceof NotFoundError) {
          return <NotFoundErrorPage />;
        }

        return (
          <Wrapper>
            <InternalError />
          </Wrapper>
        );
      }}
    >
      {children}
    </SentryErrorBoundary>
  );
};

export default ErrorBoundary;
