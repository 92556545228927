import { SVGAttributes } from 'react';

type ArrowRightAngleProps = SVGAttributes<SVGSVGElement> & {
  inverse?: boolean;
  disabled?: boolean;
};

const ArrowRightAngle = ({
  inverse,
  disabled,
  ...rest
}: ArrowRightAngleProps) => {
  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={'M10 7L15 12L10 17'}
        stroke={inverse ? 'white' : disabled ? '#D8D8D8' : '#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default ArrowRightAngle;
