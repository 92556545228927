import KemiSignIn from './KemiSignIn';
import SignIn from './SignIn';
import SignUp from './SignUp';
import TermsSelection from './TermsSelection';

import SmallModal from '@design0.2/Modal/SmallModal';
import useSignInAndUpModal from '@shared/hooks/useSignInAndUpModal';

const SignInAndUpModal = () => {
  const signInAndUpModal = useSignInAndUpModal();

  const closeButtonVisible = signInAndUpModal.type !== 'termsDetail';

  return (
    <SmallModal
      onClose={signInAndUpModal.close}
      closeButton={closeButtonVisible}
    >
      {(() => {
        switch (signInAndUpModal.type) {
          case 'signUp':
            return <SignUp />;
          case 'termsDetail':
            return <TermsSelection />;
          case 'kemiSignIn':
            return <KemiSignIn />;
          case 'signIn':
          default:
            return <SignIn />;
        }
      })()}
    </SmallModal>
  );
};
export default SignInAndUpModal;
