import { styled } from '@styles/stitches.config';

export const TextCardContainer = styled('div', {
  width: '100%',
  paddingX: 16,

  variants: {
    hasError: {
      true: {
        border1: '$red100',
      },
    },
    marginType: {
      MARGIN_BOTTOM: {
        paddingTop: 16,
        paddingBottom: 40,
      },
      MARGIN_TOP: {
        paddingTop: 40,
        paddingBottom: 16,
      },
      MARGIN_BOTH: {
        paddingY: 40,
      },
      NONE: {
        paddingY: 0,
      },
    },
  },
});

export const TextCardContent = styled('div', {
  width: '100%',
  wordBreak: 'break-all',
  whiteSpace: 'pre-wrap',
  color: '$themeFontColor',

  variants: {
    isBold: {
      true: {
        fontType: 'bodyBold',
      },
      false: {
        fontType: 'bodyRegular',
      },
    },
    isBig: {
      true: {
        fontType: 'titleBold',
      },
      false: {
        fontType: 'titleRegular',
      },
    },
    textAlign: {
      LEFT: {
        textAlign: 'left',
      },
      CENTER: {
        textAlign: 'center',
      },
      RIGHT: {
        textAlign: 'right',
      },
    },
    ellipsis: {
      true: {
        ellipse: 3,
      },
    },
  },

  compoundVariants: [
    {
      isBig: true,
      isBold: true,
      css: {
        fontType: 'titleBold',
      },
    },
    {
      isBig: true,
      isBold: false,
      css: {
        fontType: 'titleRegular',
      },
    },
    {
      isBig: false,
      isBold: true,
      css: {
        fontType: 'bodyBold',
      },
    },
    {
      isBig: false,
      isBold: false,
      css: {
        fontType: 'bodyRegular',
      },
    },
    {
      ellipsis: true,
      isBig: true,
      css: {
        ellipse: 2,
      },
    },
  ],
});

export const CardContainer = styled('div', {
  display: 'flex',
  width: '100%',
  borderRadius: '$cardBorderRadius',
  background: '$cardColor',
  border1: '$borderColor',

  variants: {
    type: {
      SMALL: {
        minHeight: 80,
        alignItems: 'center',
      },
      MEDIUM: {
        minHeight: 120,
        alignItems: 'center',
      },
      LARGE: {
        minHeight: 'initial',
        flexDirection: 'column',
        gap: 1, // large에서는 content가 border를 가지고 있지 않으므로 gap 추가
      },
    },
    hasError: {
      true: {
        border1: '$red100',
      },
    },
    isDragging: {
      true: {
        boxShadow: '$boxShadow',
      },
    },
  },
  defaultVariants: {
    type: 'SMALL',
  },

  '&:hover': {
    cursor: 'pointer',
  },
});
