import * as React from 'react';
import { SVGProps } from 'react';
const SvgSellerBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g stroke="#303030" strokeWidth={1.5}>
      <path
        d="M3.577 13a2 2 0 0 1 0-2l3.346-5.794a2 2 0 0 1 1.732-1h6.69a2 2 0 0 1 1.732 1L20.423 11a2 2 0 0 1 0 2l-3.346 5.794a2 2 0 0 1-1.732 1h-6.69a2 2 0 0 1-1.732-1L3.577 13Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="m10.813 10.521.716-2.183a.5.5 0 0 1 .95.001l.712 2.182 2.304.001a.5.5 0 0 1 .293.905l-1.866 1.348.717 2.18a.5.5 0 0 1-.768.56l-1.87-1.349-1.871 1.35a.5.5 0 0 1-.768-.56l.717-2.18-1.867-1.349a.5.5 0 0 1 .292-.905l2.309-.001Z" />
    </g>
  </svg>
);
export default SvgSellerBlack;
