import * as React from 'react';
import { SVGProps } from 'react';
const SvgVideoWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.2 5H5.8C4.254 5 3 6.393 3 8.111v7.778C3 17.607 4.254 19 5.8 19h8.4c1.546 0 2.8-1.393 2.8-3.111V8.11C17 6.393 15.746 5 14.2 5ZM17 15.134l3.036 1.784a.584.584 0 0 0 .648-.035.746.746 0 0 0 .232-.285.894.894 0 0 0 .084-.381V7.783a.892.892 0 0 0-.084-.381.743.743 0 0 0-.232-.285.584.584 0 0 0-.648-.034L17 8.866"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgVideoWhite;
