import { CartItemInput } from '@schema/types';
import { safeJsonParse } from '@utils/dom';

const KEY = 'NON_MEMBER_CART_KEY';

export type LocalCartItem = CartItemInput & {
  id: string;
  updatedAt: Date;
};

export const getItems = () => {
  return (safeJsonParse(localStorage.getItem(KEY)) as LocalCartItem[]) || [];
};

export const createItems = (items: LocalCartItem[]) => {
  const oldCartItems = getItems();
  const newCartItems = oldCartItems.concat(items);
  localStorage.setItem(KEY, JSON.stringify(newCartItems));
};

export const updateItems = (items: LocalCartItem[]) => {
  const oldCartItems = getItems();
  const ids = items.map((item) => item.id);
  const updateItems = oldCartItems.map((oldItem) => {
    const updateItem = items.find((item) => item.id === oldItem.id);
    return ids.includes(oldItem.id) ? updateItem : oldItem;
  });
  localStorage.setItem(KEY, JSON.stringify(updateItems));
};

export const deleteItems = (ids: string[]) => {
  const cartItems = getItems();
  const newCartItems = cartItems.filter(
    (cartItem) => !ids.includes(cartItem.id)
  );
  const deletedItems = cartItems.filter((cartItem) =>
    ids.includes(cartItem.id)
  );
  localStorage.setItem(KEY, JSON.stringify(newCartItems));
  return deletedItems;
};
