import { SVGProps } from 'react';

function SettlementHistory(props: SVGProps<SVGSVGElement>) {
  const color = props.type === 'linedWhite' ? 'white' : '#303030';

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        d={'M5 3H19V17L15 21H5V3Z'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M15 17H19L15 21V17Z'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M9.33333 9L10.6667 15L12 9L13.3333 15L14.6667 9M8 12H9.33333M14.6667 12H16'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
}

export default SettlementHistory;
