import dayjs from 'dayjs';
import { decodeJwt } from 'jose';
import Cookies from 'js-cookie';

import { useReissueAccessTokenMutation } from '../graphql/auth.generated';

export const ACCESS_TOKEN_KEY = 'access-token';
const REFRESH_TOKEN_KEY = 'refresh-token';

const toCookie = (accessToken: string, refreshToken?: string) => {
  const after1Year = dayjs().add(1, 'year').toDate();

  Cookies.set(ACCESS_TOKEN_KEY, accessToken, {
    expires: after1Year,
  });

  if (refreshToken) {
    Cookies.set(REFRESH_TOKEN_KEY, refreshToken, {
      expires: after1Year,
    });
  }
};

const fromCookie = () => {
  const accessToken = Cookies.get(ACCESS_TOKEN_KEY);
  const refreshToken = Cookies.get(REFRESH_TOKEN_KEY);
  return { accessToken, refreshToken };
};

const removeCookie = () => {
  Cookies.remove(ACCESS_TOKEN_KEY);
  Cookies.remove(REFRESH_TOKEN_KEY);
};

/**
 * 케미 토큰을 저장한다.
 *
 * @param accessToken - 케미 access token
 * @param refreshToken - 케미 refresh token
 */
export const storeToken = (accessToken: string, refreshToken: string) => {
  toCookie(accessToken, refreshToken);
};

/**
 * 유효한 케미 access token을 반환한다.
 *
 * 만약 유효하지 않은 토큰이라면, 유효한 토큰을 새로 발급해 반환한다.
 *
 * @returns 케미 access token
 */
export const getAccessToken = async (): Promise<string | null> => {
  const { accessToken, refreshToken } = fromCookie();

  if (!accessToken) {
    return null;
  }

  if (isValid(accessToken)) {
    return accessToken;
  }

  try {
    const newAccessToken = await reissueAccessToken(
      accessToken,
      refreshToken || ''
    );
    toCookie(newAccessToken);
    return newAccessToken;
  } catch (e) {
    clearToken();
    location.href = '/';
    return null;
  }
};

const isValid = (accessToken: string): boolean => {
  const { exp } = decodeJwt(accessToken);

  if (typeof exp === 'undefined') {
    return false;
  }

  const tokenExpirationTimeToMs = exp * 1000;
  /**
   * 토큰의 만료시간 5분 전부터 갱신을 유도한다.
   * 토큰의 만료시간을 기준으로 갱신을 유도하면, 서버에 요청을 보내기 직전에는 유효할 수 있으나,
   * 요청이 서버에 도착했을 때 토큰이 만료될 여지가 있기 때문이다.
   */
  const validTokenTime = tokenExpirationTimeToMs - 1000 * 60 * 5;

  return Date.now() < validTokenTime;
};

const reissueAccessToken = async (
  accessToken: string,
  refreshToken: string
): Promise<string> => {
  const response = await useReissueAccessTokenMutation.fetcher({
    expiredAccessToken: accessToken,
    refreshToken,
  })();
  const newAccessToken = response.reissueAccessTokenByRefreshToken;
  return newAccessToken;
};

/**
 * 토큰 제거
 */
export const clearToken = () => {
  removeCookie();
};
