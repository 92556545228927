import { useEffect } from 'react';

import UserInteractionLogger, {
  FirebaseLogType,
} from '@global/service/logger/event-logger';
import { UserInteractionType } from '@global/types';
import routeHistoryStore from '@shared/store/routeHistoryStore';
import { getUserAgent } from '@utils/dom';

type UserInteractionParamType = {
  firebase?: FirebaseLogType;
};

export const handlePageIn = (
  tag: string,
  handler?: any,
  param?: (...args: any[]) => UserInteractionParamType
) => {
  return async (...args: any[]) => {
    const userAgent = getUserAgent();
    const interactionParam = await param?.(...args);
    UserInteractionLogger.log({
      tag,
      interactionType: UserInteractionType.PAGE_IN,
      userAgent: userAgent,
      request: args,
      ...interactionParam,
    });
    await handler?.(...args);
  };
};

export const logFirebase = (
  eventType: UserInteractionType,
  tag: string,
  param?: any
) => {
  const userAgent = getUserAgent();
  UserInteractionLogger.log({
    tag,
    interactionType: eventType,
    userAgent: userAgent,
    firebase: {
      logName: tag,
      logOption: {
        prepage: routeHistoryStore.getState().prevUrl,
        device: getUserAgent().device.type ?? 'desktop',
        ...param,
      },
    },
  });
};

export const useLogFirebase = (
  type: UserInteractionType,
  tag: string,
  param?: any,
  dependencies?: any[]
) => {
  useEffect(() => {
    const userAgent = getUserAgent();
    UserInteractionLogger.log({
      tag,
      interactionType: type,
      userAgent: userAgent,
      firebase: {
        logName: tag,
        logOption: {
          prepage: routeHistoryStore.getState().prevUrl,
          device: getUserAgent().device.type ?? 'desktop',
          ...param,
        },
      },
    });
  }, dependencies || []);
};
