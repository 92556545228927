import * as React from 'react';
import { SVGProps } from 'react';
const SvgArrayListBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#E9E6FF"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5 5h14v3H5zM5 10.5h14v3H5zM5 16h14v3H5z"
    />
  </svg>
);
export default SvgArrayListBlack;
