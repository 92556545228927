import ArrowRightAngle from '@static/svg/ArrowRightAngle';
import { styled } from '@styles/stitches.config';

export const AnchorContainer = styled('a', {
  display: 'block',
  padding: 4,
  cursor: 'pointer',
  height: 48,
});

export const Content = styled('div', {
  padding: 8,
  borderRadius: 8,
  fontType: 'bodyRegular',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  variants: {
    active: {
      true: {
        background: '$black',
        color: '$white',
      },
    },
  },
});

export const IconContainer = styled('div', {
  width: 24,
  height: 24,
  variants: {
    active: {
      true: {
        '& svg *': {
          stroke: '$white',
        },
      },
      false: {
        '& svg *': {
          stroke: '$black',
        },
      },
    },
  },
});

export const IconTitle = styled('div', {
  gridColumnWithGap: 8,
  alignItems: 'center',
});

export const RightContainer = styled('div', {
  gridColumnWithGap: 16,
  alignItems: 'center',
});

export const ArrowRight = styled(ArrowRightAngle, {
  variants: {
    arrow: {
      true: {
        transition: 'all 0.4s ease',
      },
    },
    open: {
      true: {
        transform: 'rotate(90deg)',
      },
    },
  },
});
