import * as amplitude from '@amplitude/analytics-browser';
import { useRouter } from 'next/router';
import { ReactElement, useEffect } from 'react';

import FullPageLoader from '@global/components/templates/FullPageLoader/FullPageLoader';
import useUserQuery from '@global/queries/useUserQuery';
import { useConfirmModalStore } from '@global/store/confirmModalStore';
import ROUTE, { SEO_ROUTES } from '@routers';

const Authorization = ({ children }: { children: ReactElement }) => {
  const router = useRouter();
  const { isLoading, data: user } = useUserQuery();

  const { open, close } = useConfirmModalStore();

  // router.replace는 CSR에서만 작동해서 useEffect로 CSR 보장
  useEffect(() => {
    if (isLoading) return;

    const isLogin = !!user;
    const isSeller = user?.sellerInfo.isSeller;

    if (user?.userId) amplitude.setUserId(user.userId);

    const isOnlySellerSalesAnalyticsRoute = () => {
      if (router.route.startsWith(ROUTE.sellerCenter.analytics.sales.summary)) {
        return false;
      }

      if (router.route.startsWith(ROUTE.sellerCenter.analytics.sales.index)) {
        return true;
      }

      return false;
    };

    switch (true) {
      case isOnlySellerSalesAnalyticsRoute():
        if (!isSeller) {
          router.replace(ROUTE.sellerCenter.analytics.sales.summary);
        }

        break;
      case router.route.startsWith(ROUTE.mykemi.suggest):
        break;
      case router.route.startsWith(ROUTE.mykemi.products.list):
        if (!isSeller) {
          router.replace(ROUTE.mykemi.registerSeller.index);
        }

        break;
      case router.route.startsWith(ROUTE.mykemi.index):
        if (!isLogin) {
          router.replace(ROUTE.index);
        }

        break;
      case router.route.startsWith(ROUTE.sellerCenter.index):
        if (!isLogin) {
          router.replace(ROUTE.index);
          break;
        }

        if (!isSeller) {
          open({
            title: '🚨 판매자 전용 기능입니다!',
            description:
              '1분만에 판매자 등록하고\nkemi에서 바로 상품판매를 시작해보세요!',
            confirmText: '판매자 등록하러 가기',
            cancelText: '나중에 할래요',
            actions: {
              confirm: () => {
                router.push({
                  pathname: ROUTE.mykemi.registerSeller.index,
                  query: { to: router.pathname },
                });
              },
              cancel: () => {
                router.push(ROUTE.mykemi.index);
              },
              backgroundClick: () => {
                router.push(ROUTE.mykemi.index);
              },
            },
          });
        }

        break;
    }

    return () => {
      close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, user, router]);

  if (SEO_ROUTES.includes(router.route)) {
    return children;
  }

  if (isLoading || !router.isReady) return <FullPageLoader />;

  return children;
};

export default Authorization;
