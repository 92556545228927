import * as React from 'react';
import { SVGProps } from 'react';
const SvgSettlementInformationBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m5.927 4.02-3.375 6a2 2 0 0 0 0 1.96l3.375 6A2 2 0 0 0 7.67 19H13v-1.5c0-2.5 2.245-4.5 4-4.5h.705a2 2 0 0 0 1.744-2.98l-3.376-6A2 2 0 0 0 14.33 3H7.67a2 2 0 0 0-1.743 1.02ZM9.5 8v2M12.5 8v2"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 12c.333.333 1.4 1 3 1s2.667-.667 3-1"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={17.5}
      cy={17.5}
      r={4.5}
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m15.833 15.5.834 4 .833-4 .833 4 .834-4M15 17.5h.833m3.334 0H20"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgSettlementInformationBlack;
