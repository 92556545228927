import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import useUserQuery from '@global/queries/useUserQuery';
import ROUTE from '@routers';
import {
  useNonDisplayedReviewRequestsQuery,
  useUpdateDisplayedReviewRequestMutation,
} from '@shared/graphql/RequestReviewModal.generated';
import useRequestReviewModalStore from '@shared/store/useRequestReviewModalStore';

const WHITE_LIST_PATHNAME = [
  ROUTE.kemi,
  ROUTE.cart.index,
  ROUTE.orders.index,
  ROUTE.orders.detail,
];

const SHOW_REVIEW_MODAL_DATE_KEY = 'SHOW_REVIEW_MODAL_DATE';
const useOpenRequestReviewModal = () => {
  const { data: user } = useUserQuery();
  const router = useRouter();
  const store = useRequestReviewModalStore();
  const { data, isFetched } = useNonDisplayedReviewRequestsQuery(undefined, {
    enabled: !!user,
  });
  const { mutate: updateRequestReviewDisplay } =
    useUpdateDisplayedReviewRequestMutation();

  useEffect(() => {
    const isIgnorePath = !WHITE_LIST_PATHNAME.includes(router.pathname);

    const today = dayjs().format('YYYYMMDD');
    const lastShowDate = localStorage.getItem(SHOW_REVIEW_MODAL_DATE_KEY);
    const showOnceADay = lastShowDate === today; // 하루에 한번만 노출

    const reviewRequestList =
      data?.nonDisplayedReviewRequests?.nonDisplayedReviewRequests || [];
    const isReadyData = isFetched && reviewRequestList.length > 0;

    if (isIgnorePath || showOnceADay || !isReadyData) return;

    // 모달 노출 여부 저장
    localStorage.setItem(SHOW_REVIEW_MODAL_DATE_KEY, today);
    updateRequestReviewDisplay({
      orderId: reviewRequestList[0].orderId,
      productCardId: reviewRequestList[0].productCardId,
    });
    store.actions.open(reviewRequestList);
  }, [data, isFetched, router.pathname]);

  return {
    opened: store.opened,
  };
};

export default useOpenRequestReviewModal;
