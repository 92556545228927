import Input, { InputProps } from '../../atoms/Input';

import LabelBox from '@global/components/molecules/LabelBox';
import { LabelBoxProps } from '@global/components/molecules/LabelBox/LabelBox';

type LabelInputProps = LabelBoxProps & InputProps;

const LabelInput = (props: LabelInputProps) => {
  const hasError =
    props.errorBorder ?? Boolean(props.errors && props.errors.length);

  return (
    <LabelBox {...props}>
      <Input errorBorder={hasError} {...props} />
    </LabelBox>
  );
};

export default LabelInput;
