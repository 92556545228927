import { useEffect, useState } from 'react';

import Button from '@global/components/atoms/Button';
import Text from '@global/components/atoms/Text';
import LabelInput from '@global/components/molecules/LabelInput';
import useSignInAndUpModal from '@shared/hooks/useSignInAndUpModal';
import ArrowLeft from '@static/svg/ArrowLeft';
import { styled } from '@styles/stitches.config';

const KemiSignIn = () => {
  const signInAndUpModal = useSignInAndUpModal();

  const { kemiId, kemiPassword, hasKemiLoginError } = signInAndUpModal;

  const [isValidForm, setIsValidForm] = useState(false);

  useEffect(() => {
    setIsValidForm(!!kemiId && !!kemiPassword);
  }, [kemiId, kemiPassword]);

  const handleLoginClick = async () => {
    try {
      await signInAndUpModal.proceedWithKemiId();
    } catch (e) {
      setIsValidForm(false);
    }
  };

  return (
    <LoginFormContainer>
      <ArrowLeftButton onClick={() => signInAndUpModal.changeMode('signIn')} />
      <Text type={'div'} font={'titleBold'}>
        {'케미 아이디로 로그인'}
      </Text>
      <div>
        <IdPasswordForm>
          <LabelInput
            value={kemiId}
            placeholder={'아이디'}
            onChange={signInAndUpModal.setKemiId}
          />
          <LabelInput
            type={'password'}
            value={kemiPassword}
            placeholder={'비밀번호'}
            onChange={signInAndUpModal.setkemiPassword}
          />
          {hasKemiLoginError && (
            <Text color={'red100'} font={'captionRegular'}>
              {'입력된 아이디 또는 비밀번호가 올바르지 않습니다.'}
            </Text>
          )}
        </IdPasswordForm>
        <LoginButton disabled={!isValidForm} onClick={handleLoginClick}>
          {'로그인'}
        </LoginButton>
        <LoginGuide>
          <Text font={'captionRegular'}>
            {
              '보다 더 간편하고 효율적인 사용자 경험을 위해 2022년 10월부터 케미는 sns 간편로그인만 지원합니다.\n\n신규로 계정을 생성하시고자 하시는 분들은 sns 간편 회원가입으로 부탁드립니다. 감사합니다.'
            }
          </Text>
        </LoginGuide>
        <SignUpButton
          type={'lineNoShadow'}
          onClick={() => signInAndUpModal.changeMode('signUp')}
        >
          {'간편 회원가입 바로가기'}
        </SignUpButton>
      </div>
    </LoginFormContainer>
  );
};

const ArrowLeftButton = styled(ArrowLeft, {
  cursor: 'pointer',
});

const LoginFormContainer = styled('div', {
  padding: 16,
  display: 'grid',
  gridAutoFlow: 'row',
  alignItems: 'start',
  justifyContent: 'flex-start',
  gap: 24,
  textAlign: 'center',
  gridTemplateColumns: '1fr',
});

const IdPasswordForm = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  alignItems: 'start',
  justifyContent: 'flex-start',
  gridTemplateColumns: '1fr',
  gap: 8,
  textAlign: 'left',
});

const LoginButton = styled('button', {
  marginTop: 16,
  width: '100%',
  height: 48,
  paddingY: 16,
  border: '1px solid $black',
  borderRadius: 8,
  backgroundColor: '$white',
  color: '$black',
  fontType: 'bodyBold',
  cursor: 'pointer',
  '&:disabled': {
    border: 'none',
    backgroundColor: '$grey50',
    color: '$white',
  },
});

const LoginGuide = styled('div', {
  marginTop: 40,
  background: '$purple10',
  borderRadius: 8,
  padding: '16px 12px',
  wordBreak: 'keep-all',
});

const SignUpButton = styled(Button, {
  marginTop: 16,
});

export default KemiSignIn;
