import * as React from 'react';
import { SVGProps } from 'react';
const SvgGoogleDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20a8 8 0 0 0 7.91-9.2H12.4V14h3.965a4.8 4.8 0 1 1-1-5.422l2.277-2.25A8 8 0 1 0 12 20Z"
      fill="#D8D8D8"
    />
  </svg>
);
export default SvgGoogleDisabled;
