import * as React from 'react';
import { SVGProps } from 'react';
const SvgFacebookFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={10} fill="#1877F2" />
    <mask
      id="facebook_filled_svg__a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={2}
      width={20}
      height={20}
    >
      <circle cx={12} cy={12} r={10} fill="#1877F2" />
    </mask>
    <g mask="url(#facebook_filled_svg__a)">
      <path
        d="M13.217 14.688V22h-2.782v-7.312H8v-2.693h2.435v-2.31c0-4.232 3.826-3.848 5.565-3.463v2.31h-.696c-1.043 0-2.087.384-2.087 1.923v1.54H16l-.696 2.693h-2.087Z"
        fill="#fff"
      />
    </g>
  </svg>
);
export default SvgFacebookFilled;
