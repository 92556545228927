import {
  fullModalToastStore,
  modalToastStore,
  pageToastStore,
  ToastMessage,
} from '@design0.2/Toast/stores/ToastStore';

export const usePageToast = () => {
  const { good, bad, info, setOpen } = pageToastStore();
  return {
    good: (message: ToastMessage) => {
      setOpen(false);
      setTimeout(() => {
        good(message);
      }, 0);
    },
    bad: (message: ToastMessage) => {
      setOpen(false);
      setTimeout(() => {
        bad(message);
      }, 0);
    },
    info: (message: ToastMessage) => {
      setOpen(false);
      setTimeout(() => {
        info(message);
      }, 0);
    },
    close: () => setOpen(false),
  };
};

export const useModalToast = () => {
  const { good, bad, info, setOpen } = modalToastStore();
  return {
    good: (message: ToastMessage) => {
      setOpen(false);
      setTimeout(() => {
        good(message);
      }, 0);
    },
    bad: (message: ToastMessage) => {
      setOpen(false);
      setTimeout(() => {
        bad(message);
      }, 0);
    },
    info: (message: ToastMessage) => {
      setOpen(false);
      setTimeout(() => {
        info(message);
      }, 0);
    },
  };
};

export const useFullModalToast = () => {
  const { good, bad, info, setOpen } = fullModalToastStore();
  return {
    good: (message: ToastMessage) => {
      setOpen(false);
      setTimeout(() => {
        good(message);
      }, 0);
    },
    bad: (message: ToastMessage) => {
      setOpen(false);
      setTimeout(() => {
        bad(message);
      }, 0);
    },
    info: (message: ToastMessage) => {
      setOpen(false);
      setTimeout(() => {
        info(message);
      }, 0);
    },
  };
};
