import Icon from '@design0.2/Icon/Icon';
import { styled } from '@styles/stitches.config';

const NotificationBanner = () => {
  return (
    <Banner>
      <Wrapper>
        <Icon type={'exclamation-mark-circle | white'} />
        <Description>
          {
            '원활한 서비스 이용을 위해 iOS 14 이상으로 업데이트하시거나, 다른 기기로 다시 접속해 주세요.'
          }
        </Description>
      </Wrapper>
    </Banner>
  );
};
const Banner = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  width: '100%',
  minHeight: 60,
  backgroundColor: '$red100',
  zIndex: '$notificationBanner',
  padding: '12px 16px',
});

const Wrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 'contentWidth',
});

const Description = styled('div', {
  flex: 1,
  fontType: 'bodyRegular',
  color: '$white',
  marginLeft: 10,
});

export default NotificationBanner;
