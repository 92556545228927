const QuestionMarkHex = ({ ...rest }) => {
  const color = rest.type === 'linedWhite' ? 'white' : '#303030';
  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={
          'M6.42849 4.02871L2.5396 11.0287C2.20402 11.6328 2.20402 12.3672 2.5396 12.9713L6.42849 19.9713C6.78123 20.6062 7.45047 21 8.17681 21L15.8232 21C16.5495 21 17.2188 20.6062 17.5715 19.9713L21.4604 12.9713C21.796 12.3672 21.796 11.6328 21.4604 11.0287L17.5715 4.02871C17.2188 3.39378 16.5495 3 15.8232 3L8.17681 3C7.45047 3 6.78123 3.39378 6.42849 4.02871Z'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M12.0058 7.52359C11.4559 7.61109 10.6995 8.03514 10.12 8.87516L9 7.9686C9.75582 6.87287 10.7252 6.20677 11.7698 6.04056C12.8205 5.8734 13.8377 6.23075 14.6256 6.96903C15.487 7.77633 15.6402 8.80511 15.3991 9.72795C15.1678 10.6133 14.5794 11.4155 13.9159 11.9726C13.3623 12.4373 13.0382 12.7872 12.8543 13.1208C12.686 13.4263 12.6505 13.9157 12.7011 14.4125H11.1672C11.0905 13.6587 11.2024 13.0071 11.5391 12.396C11.8604 11.813 12.3594 11.3185 12.9503 10.8225C13.4313 10.4187 13.8102 9.86898 13.9462 9.34837C14.0724 8.86527 13.9929 8.43416 13.5987 8.06477C13.1119 7.60856 12.5498 7.43705 12.0058 7.52359Z'
        }
        fill={color}
      />
      <circle cx={'12.0859'} cy={'16.9488'} r={'1.05118'} fill={color} />
    </svg>
  );
};

export default QuestionMarkHex;
