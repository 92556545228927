import * as React from 'react';
import { SVGProps } from 'react';
const SvgKakaoBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#kakao_black_svg__a)" fill="#303030">
      <path d="m10.194 9.847-.604 1.666h1.226l-.603-1.664-.02-.002Z" />
      <path d="M12 4c-4.97 0-9 3.082-9 6.884 0 2.475 1.707 4.644 4.27 5.857-.189.68-.682 2.464-.781 2.845-.122.474.18.468.377.34.154-.1 2.466-1.62 3.463-2.276.542.077 1.1.118 1.671.118 4.97 0 9-3.082 9-6.884C21 7.082 16.97 4 12 4Z" />
    </g>
    <defs>
      <clipPath id="kakao_black_svg__a">
        <path fill="#fff" transform="translate(3 4)" d="M0 0h18v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgKakaoBlack;
