import { SVGProps } from 'react';

function SettlementInformation(props: SVGProps<SVGSVGElement>) {
  const color = props.type === 'linedWhite' ? 'white' : '#303030';

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        d={
          'M5.92654 4.01948L2.55154 10.0195C2.20908 10.6283 2.20908 11.3717 2.55154 11.9805L5.92654 17.9805C6.28078 18.6103 6.94715 19 7.66969 19L13 19L13 17.5C13 15 15.2454 13 17 13L17.7054 13C18.428 13 19.0942 12.6103 19.4485 11.9805C19.7909 11.3717 19.7909 10.6283 19.4485 10.0195L16.0735 4.01948C15.7192 3.38973 15.0529 3 14.3303 3L7.66969 3C6.94715 3 6.28078 3.38973 5.92654 4.01948Z'
        }
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M9.5 8V10'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M12.5 8V10'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M8 12C8.33333 12.3333 9.4 13 11 13C12.6 13 13.6667 12.3333 14 12'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <circle
        cx={'17.5'}
        cy={'17.5'}
        r={'4.5'}
        stroke={color}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M15.8333 15.5L16.6667 19.5L17.5 15.5L18.3333 19.5L19.1667 15.5M15 17.5H15.8333M19.1667 17.5H20'
        }
        stroke={color}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
}

export default SettlementInformation;
