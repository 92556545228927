import * as React from 'react';
import { SVGProps } from 'react';
const SvgMoveBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m6 10-2 2 2 2M18 10l2 2-2 2M4 12h16M12 4v16M10 6l2-2 2 2M10 18l2 2 2-2"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgMoveBlack;
