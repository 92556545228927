import useInAppBrowserWarningModalStore from '@shared/store/useInAppBrowserWarningModalStore';

type InAppBrowserWarningModal = {
  opened: boolean;
  open: () => void;
  close: () => void;
};

const useInAppBrowserWarningModal = (): InAppBrowserWarningModal => {
  const store = useInAppBrowserWarningModalStore();

  return {
    opened: store.opened,
    open: store.actions.open,
    close: store.actions.close,
  };
};

export default useInAppBrowserWarningModal;
