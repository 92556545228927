import * as React from 'react';
import { SVGProps } from 'react';
const SvgEditDesignWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 3h12v6H6zM12 3v3M9 3v3M15 3v3M6 9h12v2H6zM18 11H6v1a2 2 0 0 0 2 2h.694a2 2 0 0 1 1.98 2.283l-.487 3.41a1.832 1.832 0 1 0 3.626 0l-.487-3.41A2 2 0 0 1 15.306 14H16a2 2 0 0 0 2-2v-1Z"
    />
  </svg>
);
export default SvgEditDesignWhite;
