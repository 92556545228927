import * as Toast from '@radix-ui/react-toast';

import ToastContainer from '@design0.2/Toast/components/ToastContainer';
import { pageToastStore } from '@design0.2/Toast/stores/ToastStore';
import { styled } from '@styles/stitches.config';

const PageToastContainer = () => {
  const toast = pageToastStore();
  return (
    <Toast.Provider>
      <ToastViewport />
      <ToastContainer
        toast={toast.toast}
        open={toast.open}
        setOpen={toast.setOpen}
      />
    </Toast.Provider>
  );
};

const ToastViewport = styled(Toast.Viewport, {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
});

export default PageToastContainer;
