import React from 'react';

import { calculateColorWithLightness, getHexcode } from '../helpers/color';

import { Color } from '@styles/constants';
import { styled, theme } from '@styles/stitches.config';

export type BadgeProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  backgroundColor?: Color;
  border?: boolean;
  color?: Color;
};

const COLOR_RED = theme.colors.red100.value;

/**
 * @name Badge
 * @description
 * Design0.2 Badge입니다.
 * https://www.figma.com/file/P9vKaawDjktY6CAGUVkfNS/Kemi-Components?node-id=3275%3A4216&t=IixX1QgSyuHkUTZB-0
 *
 */
const Badge = (props: BadgeProps) => {
  const { backgroundColor, border = false, color, children } = props;
  const bgColor = backgroundColor ? getHexcode(backgroundColor) : COLOR_RED;
  const textColor = color
    ? getHexcode(color, theme.colors.black.value)
    : calculateColorWithLightness(bgColor);

  return (
    <BaseBadge
      css={{
        color: textColor,
        background: bgColor,
      }}
      border={border}
    >
      <span>{children}</span>
    </BaseBadge>
  );
};

const BaseBadge = styled('div', {
  display: 'inline-flex',
  color: '$white',
  paddingX: 7,
  paddingY: 3,
  borderRadius: 28,
  alignItems: 'center',
  justifyContent: 'center',
  fontType: 'labelBold',
  border1: 'transparent',
  variants: {
    border: {
      true: {
        border1: '$black',
      },
    },
  },

  span: {
    ellipse: 1,
  },
});

export default Badge;
