import * as React from 'react';
import { SVGProps } from 'react';
const SvgSocialMediaBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 18.815h.75a.75.75 0 0 0-.222-.533L4 18.816ZM4 21h-.75a.75.75 0 0 0 1.085.67L4 21Zm2.156-1.078.139-.737a.75.75 0 0 0-.474.066l.335.671ZM10.25 16.5c0 1.428-1.358 2.75-3.25 2.75v1.5c2.526 0 4.75-1.812 4.75-4.25h-1.5ZM7 13.75c1.892 0 3.25 1.322 3.25 2.75h1.5c0-2.438-2.224-4.25-4.75-4.25v1.5ZM3.75 16.5c0-1.428 1.358-2.75 3.25-2.75v-1.5c-2.526 0-4.75 1.812-4.75 4.25h1.5Zm.778 1.782c-.496-.491-.778-1.116-.778-1.782h-1.5c0 1.11.474 2.106 1.222 2.848l1.056-1.066ZM4.75 21v-2.185h-1.5V21h1.5Zm1.07-1.749L3.666 20.33l.67 1.342 2.156-1.078-.67-1.342ZM7 19.25c-.243 0-.479-.023-.705-.065l-.277 1.474c.317.06.646.091.982.091v-1.5ZM16.25 5.5a.75.75 0 0 0 1.5 0h-1.5Zm.75 5-.335.67a.75.75 0 0 0 .67 0L17 10.5Zm4.75-5A2.75 2.75 0 0 0 19 2.75v1.5c.69 0 1.25.56 1.25 1.25h1.5ZM19 2.75a2.75 2.75 0 0 0-2.75 2.75h1.5c0-.69.56-1.25 1.25-1.25v-1.5ZM17.75 5.5A2.75 2.75 0 0 0 15 2.75v1.5c.69 0 1.25.56 1.25 1.25h1.5ZM15 2.75a2.75 2.75 0 0 0-2.75 2.75h1.5c0-.69.56-1.25 1.25-1.25v-1.5ZM12.25 5.5c0 1.835 1.211 3.275 2.256 4.19a11.595 11.595 0 0 0 2.152 1.478l.004.002h.002L17 10.5l.336-.67-.006-.003a2.167 2.167 0 0 1-.139-.075 10.092 10.092 0 0 1-1.698-1.191C14.54 7.725 13.75 6.665 13.75 5.5h-1.5Zm4.75 5 .336.67h.002l.003-.002a.794.794 0 0 1 .052-.027c.034-.018.082-.043.142-.077a11.595 11.595 0 0 0 1.959-1.375c1.045-.914 2.256-2.354 2.256-4.189h-1.5c0 1.165-.789 2.225-1.744 3.06a10.092 10.092 0 0 1-1.836 1.267l-.006.002.336.671Z"
      fill="#303030"
    />
    <circle
      cx={7}
      cy={7}
      r={1.5}
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={3}
      y={3}
      width={8}
      height={8}
      rx={2}
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={13}
      y={13}
      width={8}
      height={8}
      rx={2}
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m15.7 15 3 2-3 2v-4Z"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgSocialMediaBlack;
