import ErrorConsoleLogger from '@global/service/logger/error-logger/ErrorConsoleLogger';
import ErrorSentryLogger from '@global/service/logger/error-logger/ErrorSentryLogger';
import { IEventLogger } from '@global/service/logger/interfaces';
import { Maybe } from '@global/types';

export type ErrorLogParam = {
  data: any;
  elasticSearch?: Maybe<any>;
};

export default class ErrorLogger implements IEventLogger<ErrorLogParam> {
  private loggerList: IEventLogger<ErrorLogParam>[] = [
    // 다른 로깅방식으로 로깅하고 싶다면 여기에 추가하면 됩니다
    new ErrorConsoleLogger(),
    new ErrorSentryLogger(),
  ];

  log(param: ErrorLogParam): void {
    this.loggerList.forEach((_logger) => _logger.log(param));
  }
}
