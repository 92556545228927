import { parse, stringify } from 'qs';

import { isObjectEmpty } from '@utils/collection';

export const generateQueryUrl = (path: string, query: Partial<{}>) => {
  const paths = path.split('?');
  const host = paths[0];
  const queryFromPath = parse(paths[1]);
  const queryParams = {
    ...query,
    ...queryFromPath,
  };

  if (isObjectEmpty(queryParams)) {
    return host;
  }

  return `${host}?${stringify(queryParams)}`;
};

export const parseQueryUrl = (url: string) => {
  const [path, serializedQuery] = url.split('?');
  const query = parse(serializedQuery) as { [key: string]: string };
  return {
    path,
    query,
  };
};

export const checkAndAddHttps = (url: string) => {
  if (url?.includes('https://') || url?.includes('http://')) {
    return url;
  }

  return 'https://' + url;
};

type ImageResizeFormat =
  | 'jpeg'
  | 'png'
  | 'gif'
  | 'webp'
  | 'svg'
  | 'tiff'
  | 'jfif';

type ImageResizeQuery = {
  w?: number; // 너비(px)
  h?: number; // 높이(px)
  q?: number; // 품질(1 ~ 100)
  f?: ImageResizeFormat;
};

export const appendResizeQuery = (
  imageUrl: string,
  query: ImageResizeQuery
) => {
  // 기존 버킷에 저장된 이미지는 resize query를 붙이지 않고 리턴
  if (!imageUrl || !isKemiImage(imageUrl)) {
    return imageUrl;
  }

  const url = new URL(imageUrl);

  Object.entries(query).forEach(([key, value]) => {
    if (typeof value === 'number') {
      url.searchParams.append(key, value.toString());
      return;
    }

    if (value) {
      url.searchParams.append(key, value);
    }
  });

  return url.toString();
};

export const removeResizeQuery = (fileUrl: string) => {
  const url = new URL(fileUrl);
  return `${url.origin}${url.pathname}`;
};

export const isKemiImage = (imageUrl: string) => {
  const kemiImageBaseUrls = [
    'https://asset.kemi.io', // 케미에서 제공하는 static한 이미지 url 도메인
    'https://asset.kemist.io', // 케미에서 제공하는 static한 이미지 url 도메인
    process.env.NEXT_PUBLIC_KEMI_IMAGE_BASE_URL as string,
  ];

  return kemiImageBaseUrls.some((url) => imageUrl.startsWith(url));
};

export const removeProtocol = (url: string) => {
  return url.startsWith('http://')
    ? url.slice(7)
    : url.startsWith('https://')
    ? url.slice(8)
    : url;
};

export const toDownloadImageUrl = (imageUrl: string) => {
  // 기존 버킷에 저장된 이미지는 다운로드 할 수 없다.
  if (
    !imageUrl ||
    !imageUrl.startsWith(process.env.NEXT_PUBLIC_KEMI_IMAGE_BASE_URL as string)
  ) {
    return imageUrl;
  }

  const url = new URL(imageUrl);
  url.searchParams.append('download', 'true');

  return url.toString();
};
