import * as Toast from '@radix-ui/react-toast';

import { BadToastItem, GoodToastItem, InfoToastItem } from './ToastItem';

import { ToastItem } from '@design0.2/Toast/stores/ToastStore';
import { keyframes, styled } from '@styles/stitches.config';

type ToastContainerProps = {
  toast?: ToastItem;
  open: boolean;
  setOpen: (open: boolean) => void;
};
const ToastContainer = ({ open, setOpen, toast }: ToastContainerProps) => {
  if (!toast) return null;

  return (
    <ToastRoot open={open} onOpenChange={setOpen} duration={4000}>
      <ToastClose>
        <Toast.Description>
          {toast.type === 'good' && (
            <GoodToastItem>{toast.message}</GoodToastItem>
          )}
          {toast?.type === 'bad' && (
            <BadToastItem>{toast.message}</BadToastItem>
          )}
          {toast.type === 'info' && (
            <InfoToastItem>{toast.message}</InfoToastItem>
          )}
        </Toast.Description>
      </ToastClose>
    </ToastRoot>
  );
};

const slideIn = keyframes({
  from: {
    opacity: 0,
    transform: 'translateY(-50%)',
  },
  to: {
    opacity: 1,
    transform: 'translateY(0%)',
  },
});

const slideOut = keyframes({
  from: {
    opacity: 1,
    transform: 'translateY(0%)',
  },
  to: {
    opacity: 0,
    transform: 'translateY(-50%)',
  },
});

const ToastRoot = styled(Toast.Root, {
  '&[data-state="open"]': {
    animation: `${slideIn} 500ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideOut} 500ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

const ToastClose = styled(Toast.Close, {
  width: '100%',
});

export default ToastContainer;
