import * as React from 'react';
import { SVGProps } from 'react';
const SvgNotificationRingingBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 20a2 2 0 0 0 2-2h-4a2 2 0 0 0 2 2Z"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 10a6 6 0 0 1 6-6v0a6 6 0 0 1 6 6v3.486a5 5 0 0 0 .84 2.774l.123.185A1 1 0 0 1 18.131 18H5.87a1 1 0 0 1-.833-1.555l.124-.185A5 5 0 0 0 6 13.486V10ZM4 8v5M2 9v3M20 8v5M22 9v3"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgNotificationRingingBlack;
