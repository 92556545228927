import * as React from 'react';
import { SVGProps } from 'react';
const SvgPhoneBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.88 8.654a1.684 1.684 0 0 0 0-2.379L8.097 4.493a1.682 1.682 0 0 0-2.379 0l-.978.979a2.519 2.519 0 0 0-.32 3.174A39.111 39.111 0 0 0 15.355 19.58a2.523 2.523 0 0 0 3.175-.317l.978-.98a1.682 1.682 0 0 0 0-2.378l-1.784-1.784a1.68 1.68 0 0 0-2.379 0l-.595.595A39.909 39.909 0 0 1 9.286 9.25l.595-.597Z"
      stroke="#303030"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPhoneBlack;
