import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { UAParser } from 'ua-parser-js';

import useInAppBrowserWarningModal from './useInAppBrowserWarningModal';

import { useModalToast } from '@design0.2/Toast/hooks/useToast';
import ROUTE from '@routers';
import { useCreateOrderInSignInAndUpModalMutation } from '@shared/graphql/CreateOrderInSignInAndUpModal.generated';
import SignInAndSignUpModalStoreActions, {
  SignInAndUpModalStoreActions,
  SignInAndUpModalStoreState,
} from '@shared/store/useSignInAndUpModalStore';
import { isWebView } from '@utils/dom';
import {
  initLoginAndRegisterFlow,
  loginWithKemiId,
} from 'src/auth/modules/auth';
import { OAuthProviderType } from 'src/auth/types';

const REQUIRED_TERMS = ['service', 'privacy'];

type SignInAndUpModal = {
  opened: SignInAndUpModalStoreState['opened'];
  purpose: SignInAndUpModalStoreState['purpose'];
  type: SignInAndUpModalStoreState['type'];
  selectedTerms: SignInAndUpModalStoreState['selectedTerms'];
  isTermsSelectionDirty: SignInAndUpModalStoreState['isTermsSelectionDirty'];
  allTermsSelected: boolean;
  allRequiredTermsSelected: boolean;
  shouldSelectRequiredTermsFlag: SignInAndUpModalStoreState['shouldSelectRequiredTermsFlag'];
  kemiId: SignInAndUpModalStoreState['kemiId'];
  kemiPassword: SignInAndUpModalStoreState['kemiPassword'];
  hasKemiLoginError: SignInAndUpModalStoreState['hasKemiLoginError'];
  open: SignInAndUpModalStoreActions['open'];
  close: SignInAndUpModalStoreActions['close'];
  changeMode: SignInAndUpModalStoreActions['changeMode'];
  updateTerms: SignInAndUpModalStoreActions['updateTerms'];
  selectAllTerms: SignInAndUpModalStoreActions['selectAllTerms'];
  deselectAllTerms: SignInAndUpModalStoreActions['deselectAllTerms'];
  setKemiId: SignInAndUpModalStoreActions['setKemiId'];
  setkemiPassword: SignInAndUpModalStoreActions['setkemiPassword'];
  proceedWithOAuth: (oAuthProvider: OAuthProviderType) => Promise<void>;
  proceedWithKemiId: () => Promise<void>;
  buyWithNonMember: () => Promise<void>;
};

const useSignInAndUpModal = (): SignInAndUpModal => {
  const {
    opened,
    purpose,
    type,
    selectedTerms,
    isTermsSelectionDirty,
    shouldSelectRequiredTermsFlag,
    kemiId,
    kemiPassword,
    hasKemiLoginError,
    linkName,
    redirectTo,
    orderLineItemsToOrder,
    actions,
  } = SignInAndSignUpModalStoreActions();
  const { mutateAsync: mutateCreateOrder } =
    useCreateOrderInSignInAndUpModalMutation();
  const router = useRouter();
  const modalToast = useModalToast();
  const inAppBrowserWarningModal = useInAppBrowserWarningModal();

  const allTermsSelected = useMemo(() => {
    return Object.values(selectedTerms).every((selected) => !!selected);
  }, [selectedTerms]);

  const allRequiredTermsSelected = useMemo(() => {
    return (
      Object.entries(selectedTerms)
        .filter(([type]) => REQUIRED_TERMS.includes(type))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .every(([_, selected]) => !!selected)
    );
  }, [selectedTerms]);

  const createOrder = async () => {
    if (!orderLineItemsToOrder) {
      return;
    }

    const { createOrder: createOrderResponse } = await mutateCreateOrder({
      order: {
        orderLineItems: orderLineItemsToOrder,
      },
    });

    if (!createOrderResponse) {
      return;
    }

    const { id: orderId } = createOrderResponse;

    return orderId;
  };

  const proceedWithOAuth = async (oAuthProvider: OAuthProviderType) => {
    if (type === 'signUp' && !allRequiredTermsSelected) {
      actions.setShouldSelectRequiredTermsFlag(true);
      modalToast.bad('먼저 회원가입 약관에 동의해주세요. 🤝');
      return;
    }

    const isInAppBrowser = isWebView();
    const ua = new UAParser().getUA().toLocaleLowerCase();
    const isAndroid = ua.includes('android');
    const isKakao = ua.includes('kakaotalk');

    if (oAuthProvider === 'google' && isInAppBrowser) {
      inAppBrowserWarningModal.open();
      return;
    }

    if (
      oAuthProvider === 'facebook' &&
      isInAppBrowser &&
      isAndroid &&
      isKakao
    ) {
      inAppBrowserWarningModal.open();
      return;
    }

    switch (purpose) {
      case 'normal':
        initLoginAndRegisterFlow({
          provider: oAuthProvider,
          redirectTo: redirectTo ?? '',
          linkName: linkName ?? undefined,
          marketingAgreement: selectedTerms.marketing,
        });
        break;
      case 'checkout':
        initLoginAndRegisterFlow({
          provider: oAuthProvider,
          redirectTo: location.pathname,
          linkName: linkName ?? undefined,
          marketingAgreement: selectedTerms.marketing,
        });
        break;
      case 'order':
        const orderId = await createOrder();

        if (!orderId) {
          return;
        }

        const checkoutUrl = ROUTE.orders.checkout.index.replace(
          '[orderId]',
          orderId
        );

        initLoginAndRegisterFlow({
          provider: oAuthProvider,
          redirectTo: checkoutUrl,
          linkName: linkName ?? undefined,
          marketingAgreement: selectedTerms.marketing,
        });
        break;
    }
  };

  const proceedWithKemiId = async () => {
    try {
      await loginWithKemiId(kemiId, kemiPassword);
    } catch (e) {
      actions.setHasKemiLoginError(true);
      return;
    }

    switch (purpose) {
      case 'normal':
        location.href = redirectTo ?? '/';
        break;
      case 'checkout':
        location.href = location.pathname;
        break;
      case 'order':
        const orderId = await createOrder();

        if (!orderId) {
          return;
        }

        router.push({
          pathname: ROUTE.orders.checkout.index,
          query: { orderId },
        });

        actions.close();
        break;
    }
  };

  const buyWithNonMember = async () => {
    const orderId = await createOrder();

    if (!orderId) {
      return;
    }

    router.push({
      pathname: ROUTE.orders.checkout.index,
      query: { orderId },
    });

    actions.close();
  };

  return {
    opened,
    purpose,
    type,
    selectedTerms,
    isTermsSelectionDirty,
    allTermsSelected,
    allRequiredTermsSelected,
    shouldSelectRequiredTermsFlag,
    kemiId,
    kemiPassword,
    hasKemiLoginError,
    open: actions.open,
    close: actions.close,
    changeMode: actions.changeMode,
    updateTerms: actions.updateTerms,
    selectAllTerms: actions.selectAllTerms,
    deselectAllTerms: actions.deselectAllTerms,
    setKemiId: actions.setKemiId,
    setkemiPassword: actions.setkemiPassword,
    proceedWithOAuth,
    proceedWithKemiId,
    buyWithNonMember,
  };
};

export default useSignInAndUpModal;
