const Edit = ({ ...rest }) => {
  if (rest.type === 'linedWhite') {
    return (
      <svg
        width={'24'}
        height={'24'}
        viewBox={'0 0 24 24'}
        fill={'none'}
        xmlns={'http://www.w3.org/2000/svg'}
        {...rest}
      >
        <path
          d={
            'M16.5858 3.41421L5 15L3.31623 20.0513C3.18593 20.4422 3.5578 20.8141 3.94868 20.6838L9 19L20.5858 7.41421C21.3668 6.63316 21.3668 5.36684 20.5858 4.58579L19.4142 3.41421C18.6332 2.63316 17.3668 2.63316 16.5858 3.41421Z'
          }
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M15 5L19 9'}
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M5 15L9 19'}
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M3.5 19.5L3 21L4.5 20.5L3.5 19.5Z'}
          stroke={'white'}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
      </svg>
    );
  }

  return (
    <svg
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...rest}
    >
      <path
        d={
          'M16.5858 3.41421L5 15L3.31623 20.0513C3.18593 20.4422 3.5578 20.8141 3.94868 20.6838L9 19L20.5858 7.41421C21.3668 6.63316 21.3668 5.36684 20.5858 4.58579L19.4142 3.41421C18.6332 2.63316 17.3668 2.63316 16.5858 3.41421Z'
        }
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M15 5L19 9'}
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M5 15L9 19'}
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M3.5 19.5L3 21L4.5 20.5L3.5 19.5Z'}
        stroke={'#303030'}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};

export default Edit;
