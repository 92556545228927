import { PropsWithChildren, ReactNode } from 'react';

import Box from '../../atoms/Box';
import Text from '../../atoms/Text';

import { styled } from '@styles/stitches.config';

export type LabelBoxProps = PropsWithChildren<{
  label?: string;
  labelDescription?: string;
  labelOption?: string | ReactNode;
  labelTextBelow?: string;
  errors?: string[] | string;
  required?: boolean;
  className?: string;
}>;

const LabelBox = ({
  label,
  labelDescription,
  labelOption,
  labelTextBelow,
  errors,
  className,
  required,
  children,
}: LabelBoxProps) => {
  const hasError = Boolean(errors && errors.length);

  return (
    <Container className={className}>
      {label && (
        <LabelContainer>
          <GridRow>
            <LabelText type={'h3'} font={'bodyRegular'} required={required}>
              {label}
            </LabelText>
            <Description>{labelDescription}</Description>
          </GridRow>
          {labelOption && labelOption}
        </LabelContainer>
      )}
      {children}
      {hasError && <Error errors={errors as string[]} />}
      {labelTextBelow && (
        <Text font={'captionRegular'} color={'grey100'}>
          {labelTextBelow}
        </Text>
      )}
    </Container>
  );
};

type ErrorProps = {
  errors: string[] | string;
};

const Error = ({ errors }: ErrorProps) => {
  return (
    <Errors type={'ul'}>
      {typeof errors === 'string' ? (
        <Text color={'red100'} font={'captionRegular'}>
          {errors}
        </Text>
      ) : (
        errors.map((error, i) => {
          return (
            <Text key={i} color={'red100'} font={'captionRegular'}>
              {error}
            </Text>
          );
        })
      )}
    </Errors>
  );
};

const Container = styled(Box, {
  display: 'grid',
  gap: 8,
});

const LabelContainer = styled(Box, {
  fontType: 'bodyRegular',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const LabelText = styled(Text, {
  variants: {
    required: {
      true: {
        position: 'relative',
        textAlign: 'end',

        '&::after': {
          fontType: 'bodyBold',
          content: '*',
          position: 'absolute',
          top: -4,
          right: -10,
          color: '$red100',
        },
      },
    },
  },
});

const Description = styled(Text, {
  fontType: 'bodyRegular',
  display: 'grid',
  gap: 4,
});

const GridRow = styled(Box, {
  display: 'grid',
  gridAutoFlow: 'column',
  gap: 4,
});

const Errors = styled(Box, {
  fontType: 'bodyRegular',
  display: 'grid',
  gap: 4,
});

export default LabelBox;
